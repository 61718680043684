import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalApagar = ({
  modalApagar,
  toggle,
  setLoading,
  envio,
  converterData,
}) => {
  const [loadingModal, setLoadingModal] = useState(false);

  const apagar = async (envio) => {
    setLoadingModal(true);

    const response = await apiClient.delete(
      `/registro-envios/excluir/${envio.id}`
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  return (
    <Modal
      isOpen={modalApagar}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Excluir Envio
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <h4 className="fw-bold">Confirmação de Exclusão de Envio</h4>
            <small className="text-muted">
              <b>{envio?.projeto?.nome}</b> Em{" "}
              {converterData(envio?.created_at || "")}
            </small>
            <p className="mt-5" style={{ fontSize: "1rem" }}>
              Tem certeza de que deseja <b className="text-danger">excluir</b>{" "}
              este envio? Essa ação é irreversível e todas as informações
              relacionadas serão permanentemente removidas do sistema.
            </p>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-danger"
              onClick={() => apagar(envio)}
              disabled={loadingModal}
            >
              Apagar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
