import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api_helper";

const Navdata = () => {
  const [userDados, setUserDados] = useState(null);
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isClientes, setIsClientes] = useState(false);
  const [isUsuarios, setIsUsuarios] = useState(false);
  const [isProjetos, setIsProjetos] = useState(false);
  const [isEnvios, setIsEnvios] = useState(false);
  const [isLanding, setIsLanding] = useState(false);
  const [isFerramentas, setIsFerramentas] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
    if (iscurrentState !== "Clientes") {
      setIsClientes(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
    if (iscurrentState !== "Projetos") {
      setIsProjetos(false);
    }
    if (iscurrentState !== "Envios") {
      setIsEnvios(false);
    }
    if (iscurrentState !== "Ferramentas") {
      setIsFerramentas(false);
    }
    const checkingLogin = async () => {
      setUserDados(getLoggedinUser());
    };
    checkingLogin();
  }, [
    history,
    iscurrentState,
    isDashboard,
    isClientes,
    isUsuarios,
    isProjetos,
    isEnvios,
    isLanding,
    isFerramentas,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
  ];

  const clientesMenuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "projetos",
      label: "Projetos",
      icon: "ri-folder-2-line",
      link: "/#",
      stateVariables: isProjetos,
      click: function (e) {
        e.preventDefault();
        setIsProjetos(!isProjetos);
        setIscurrentState("Projetos");
        updateIconSidebar(e);
      },
      subItems: [
        // {
        //   id: "projetos-envio",
        //   label: "Projetos",
        //   link: "/projetos",
        //   parentId: "projetos",
        // },
        {
          id: "alocacao",
          label: "Páginas de Alocação",
          link: "/alocacao",
          parentId: "projetos",
        },
      ],
    },
  ];

  const userMenuItems = [
    {
      id: "clientes",
      label: "Clientes",
      icon: "ri-user-2-line",
      link: "/clientes",
      stateVariables: isClientes,
      click: function (e) {
        e.preventDefault();
        setIsClientes(!isClientes);
        setIscurrentState("Clientes");
        updateIconSidebar(e);
      },
    },
    {
      id: "projetos",
      label: "Projetos",
      icon: "ri-folder-2-line",
      link: "/projetos",
      stateVariables: isProjetos,
      click: function (e) {
        e.preventDefault();
        setIsProjetos(!isProjetos);
        setIscurrentState("Projetos");
        updateIconSidebar(e);
      },
    },
    {
      id: "envios",
      label: "Envios",
      icon: "ri-send-plane-2-line",
      link: "/envios",
      stateVariables: isEnvios,
      click: function (e) {
        e.preventDefault();
        setIsEnvios(!isEnvios);
        setIscurrentState("Envios");
        updateIconSidebar(e);
      },
    },
  ];

  const adminMenuItems = [
    {
      id: "clientes",
      label: "Clientes",
      icon: "ri-user-2-line",
      link: "/clientes",
      stateVariables: isClientes,
      click: function (e) {
        e.preventDefault();
        setIsClientes(!isClientes);
        setIscurrentState("Clientes");
        updateIconSidebar(e);
      },
    },
    {
      id: "usuarios",
      label: "Usuários",
      icon: "ri-user-3-line",
      link: "/usuarios",
      stateVariables: isUsuarios,
      click: function (e) {
        e.preventDefault();
        setIsUsuarios(!isUsuarios);
        setIscurrentState("Usuarios");
        updateIconSidebar(e);
      },
    },
    {
      id: "projetos",
      label: "Projetos",
      icon: "ri-folder-2-line",
      link: "/#",
      stateVariables: isProjetos,
      click: function (e) {
        e.preventDefault();
        setIsProjetos(!isProjetos);
        setIscurrentState("Projetos");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "projetos-envio",
          label: "Projetos",
          link: "/projetos",
          parentId: "projetos",
        },
        {
          id: "envios",
          label: "Envios",
          link: "/envios",
          parentId: "projetos",
        },
        {
          id: "alocacao",
          label: "Páginas de Alocação",
          link: "/alocacao",
          parentId: "projetos",
        },
      ],
    },
    {
      id: "ferramentas",
      label: "Ferramentas",
      icon: "ri-tools-line",
      link: "/#",
      stateVariables: isFerramentas,
      click: function (e) {
        e.preventDefault();
        setIsFerramentas(!isFerramentas);
        setIscurrentState("Ferramentas");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "logs",
          label: "Logs",
          link: "/logs",
          parentId: "ferramentas",
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      {userDados?.role === "cliente"
        ? [...menuItems, ...clientesMenuItems]
        : userDados?.role === "user"
        ? [...menuItems, ...userMenuItems]
        : userDados?.role === "admin"
        ? [...menuItems, ...adminMenuItems]
        : []}
    </React.Fragment>
  );
};
export default Navdata;
