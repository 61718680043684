import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchOption from "./SearchOption";
import ModalEnviar from "./ModalEnviar";
import ModalApagar from "./ModalApagar";
import Paginacao from "../../Components/Common/Paginacao";

import { useActiveWallet } from "thirdweb/react";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const Envios = () => {
  const wallet = useActiveWallet();
  const [userDados, setUserDados] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [modalEnviar, setModalEnviar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);

  const [envios, setEnvios] = useState([]);
  const [envio, setEnvio] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [base, setBase] = useState("meus");
  const [status, setStatus] = useState("todos");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const arrowNavToggle = (tab) => {
    if (base !== tab) {
      setBase(tab);
      setCurrentPage(1);
      setLoading(true);
    }
  };

  const toggleModalEnviar = useCallback(
    (envi) => {
      setEnvio(envi);
      setModalEnviar(!modalEnviar);
    },
    [modalEnviar]
  );

  const toggleModalApagar = useCallback(
    (envi) => {
      setEnvio(envi);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const converterData = (data) => {
    if (!data) return "";
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const fetchEnvios = useCallback(async (params = {}) => {
    const response = await apiClient.post("/registro-envios/buscar", {
      ...params,
    });

    if (response.sucesso) {
      setEnvios(response.dados.data);
      setTotalItems(response.dados.total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const getEnvios = async () => {
      setUserDados(getLoggedinUser());
      await fetchEnvios({
        base,
        termo: termoBusca,
        status,
        itens_pagina: itemsPerPage,
        pagina: currentPage,
      });
      setLoading(false);
      setLoadTable(false);
    };
    getEnvios();
  }, [
    fetchEnvios,
    loading,
    itemsPerPage,
    currentPage,
    termoBusca,
    base,
    status,
  ]);

  document.title = "Envios | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Envios" pageTitle="Projetos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2 mt-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                    setLoading(true);
                  }}
                  value={itemsPerPage}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Col>
              <Col xl={8}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setCurrentPage}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCurrentPage(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={status}
                >
                  <option value="todos">Todos</option>
                  <option value="ativo">Ativos</option>
                  <option value="inativo">Inativos</option>
                  <option value="bloqueado">Bloqueados</option>
                </select>
              </Col>
            </Row>
          </Card>

          {userDados.role === "admin" && (
            <Nav
              pills
              className="nav nav-pills arrow-navtabs nav-info bg-light mb-3 mt-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "meus",
                  })}
                  onClick={() => {
                    arrowNavToggle("meus");
                  }}
                >
                  Meus Envios
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "todos",
                  })}
                  onClick={() => {
                    arrowNavToggle("todos");
                  }}
                >
                  Todos
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <Row>
            <Col lg={12}>
              <Card id="envioList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Lista de Envios</h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {!loading && !loadTable ? (
                      <table className="table table-hover table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                id="checkBoxAll"
                                className="form-check-input"
                                onClick={() => {}}
                              />
                            </th>
                            <th scope="col">Projeto</th>
                            <th scope="col">Token</th>
                            <th scope="col">Usuário</th>
                            <th scope="col">Status</th>
                            <th scope="col">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(envios || []).map((envio, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="customerCheckBox form-check-input"
                                  value={envio.id}
                                  onChange={() => {}}
                                />
                              </th>
                              <td>
                                {envio.projeto?.nome}
                                <br />
                                <small className="text-muted">
                                  {envio.hash === ""
                                    ? `Preparado em ${converterData(
                                        envio.created_at
                                      )}`
                                    : `Enviado em ${converterData(
                                        envio.updated_at
                                      )}`}
                                </small>
                              </td>
                              <td>{envio?.projeto?.token.sigla}</td>
                              <td>{envio.user.name}</td>
                              <td>
                                {envio.hash === "" ? (
                                  <span className="badge bg-success-subtle text-waiting">
                                    Em Espera
                                  </span>
                                ) : (
                                  <span className="badge bg-success-subtle text-success">
                                    Enviado
                                  </span>
                                )}
                              </td>
                              <td>
                                {envio.hash === "" ? (
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li
                                      className="list-inline-item edit"
                                      title="Editar"
                                    >
                                      <Link
                                        to="#"
                                        className="text-success d-inline-block edit-item-btn"
                                        onClick={() => {
                                          toggleModalEnviar(envio);
                                        }}
                                      >
                                        <i className="ri-send-plane-fill fs-16"></i>
                                      </Link>
                                    </li>
                                    <li
                                      className="list-inline-item"
                                      title="Remover"
                                      onClick={() => {
                                        toggleModalApagar(envio);
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        className="text-danger d-inline-block remove-item-btn"
                                        onClick={() => {}}
                                      >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                ) : (
                                  <>
                                    {"Hash: "}
                                    <Link
                                      to={
                                        envio?.projeto?.token?.rede
                                          ?.block_explorer +
                                        "tx/" +
                                        envio.hash
                                      }
                                      target="_blank"
                                      className="text-success d-inline-block edit-item-btn"
                                    >
                                      {formatAddress(envio.hash)}
                                    </Link>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <Row className="justify-content-center mt-3 mb-3">
                        <Spinner color="warning" />
                      </Row>
                    )}
                  </div>
                  {totalItems > itemsPerPage && (
                    <Paginacao
                      total={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      perPageData={itemsPerPage}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <ModalEnviar
            modalEnviar={modalEnviar}
            toggle={toggleModalEnviar}
            envio={envio}
            setLoading={setLoading}
            converterData={converterData}
            wallet={wallet}
          />
          <ModalApagar
            modalApagar={modalApagar}
            toggle={toggleModalApagar}
            envio={envio}
            setLoading={setLoading}
            converterData={converterData}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Envios;
