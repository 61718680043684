import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchOption from "./SearchOption";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";
import Paginacao from "../../Components/Common/Paginacao";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const Usuarios = () => {
  const [userDados, setUserDados] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [modalNovo, setModalNovo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);

  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [status, setStatus] = useState("todos");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalEditar = useCallback(
    (cli) => {
      setUsuario(cli);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalApagar = useCallback(
    (cli) => {
      setUsuario(cli);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const fetchUsuarios = useCallback(async (params = {}) => {
    const response = await apiClient.post("/users/buscar", {
      ...params,
    });

    if (response.sucesso) {
      setUsuarios(response.dados.data);
      setTotalItems(response.dados.total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const getUsuarios = async () => {
      setUserDados(getLoggedinUser());
      await fetchUsuarios({
        termo: termoBusca,
        status,
        itens_pagina: itemsPerPage,
        pagina: currentPage,
      });
      setLoading(false);
      setLoadTable(false);
    };
    getUsuarios();
  }, [fetchUsuarios, loading, itemsPerPage, currentPage, termoBusca, status]);

  document.title = "Usuários | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Usuários" pageTitle="Painel" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2 mt-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                    setLoading(true);
                  }}
                  value={itemsPerPage}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Col>
              <Col xl={8}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setCurrentPage}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCurrentPage(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={status}
                >
                  <option value="todos">Todos</option>
                  <option value="ativo">Ativos</option>
                  <option value="inativo">Inativos</option>
                  <option value="bloqueado">Bloqueados</option>
                </select>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col lg={12}>
              <Card id="userList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Lista de Usuários</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => toggleModalNovo()}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Novo
                          Usuário
                        </button>{" "}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {!loading && !loadTable ? (
                      <table className="table table-hover table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                id="checkBoxAll"
                                className="form-check-input"
                                onClick={() => {}}
                              />
                            </th>
                            <th scope="col">Nome</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Status</th>
                            <th scope="col">Clientes</th>
                            <th scope="col">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(usuarios || []).map((user, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="customerCheckBox form-check-input"
                                  value={user.id}
                                  onChange={() => {}}
                                />
                              </th>
                              <td>
                                {user.name}
                                <br />
                                <small className="text-muted">
                                  {converterData(user.created_at)}
                                </small>
                              </td>
                              <td>{user.email}</td>
                              <td>
                                {(() => {
                                  switch (user?.status) {
                                    case "bloqueado":
                                      return (
                                        <span className="badge text-uppercase bg-info-subtle text-info">
                                          Bloqueado
                                        </span>
                                      );
                                    case "inativo":
                                      return (
                                        <span className="badge text-uppercase bg-danger-subtle text-danger">
                                          Inativo
                                        </span>
                                      );
                                    case "ativo":
                                      return (
                                        <span className="badge text-uppercase bg-success-subtle text-success">
                                          Ativo
                                        </span>
                                      );
                                    default:
                                      return (
                                        <span className="badge bg-dark-subtle text-white">
                                          -
                                        </span>
                                      );
                                  }
                                })()}
                              </td>
                              <td className="text-success">
                                {user?.clientes_count || 0}
                              </td>
                              <td>
                                {userDados.id !== user.id && (
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li
                                      className="list-inline-item edit"
                                      title="Editar"
                                    >
                                      <Link
                                        to="#"
                                        className="text-info d-inline-block edit-item-btn"
                                        onClick={() => {
                                          toggleModalEditar(user);
                                        }}
                                      >
                                        <i className="ri-pencil-fill fs-16"></i>
                                      </Link>
                                    </li>
                                    <li
                                      className="list-inline-item"
                                      title="Remover"
                                      onClick={() => {
                                        toggleModalApagar(user);
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        className="text-danger d-inline-block remove-item-btn"
                                        onClick={() => {}}
                                      >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <Row className="justify-content-center mt-3 mb-3">
                        <Spinner color="warning" />
                      </Row>
                    )}
                  </div>
                  {totalItems > itemsPerPage && (
                    <Paginacao
                      total={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      perPageData={itemsPerPage}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>

          <ModalNovo
            modalNovo={modalNovo}
            toggle={toggleModalNovo}
            setLoading={setLoading}
          />
          <ModalEditar
            modalEditar={modalEditar}
            toggle={toggleModalEditar}
            usuario={usuario}
            setLoading={setLoading}
          />
          <ModalApagar
            modalApagar={modalApagar}
            toggle={toggleModalApagar}
            usuario={usuario}
            setLoading={setLoading}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Usuarios;
