import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { createThirdwebClient } from "thirdweb";
import {
  useDisconnect,
  useActiveWallet,
  ConnectButton,
  darkTheme,
} from "thirdweb/react";
import { createWallet, walletConnect } from "thirdweb/wallets";
import { toast } from "react-toastify";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const client = createThirdwebClient({
  clientId: process.env.REACT_APP_PUBLIC_THIRDWEB_CLIENT_ID,
});

const apiClient = new APIClient();
const wallets = [createWallet("io.metamask"), walletConnect()];

const BotaoConectar = (props) => {
  const carteira = useActiveWallet();

  const [loading, setLoading] = useState(false);
  const [userDados, setUserDados] = useState(null);
  const { disconnect } = useDisconnect();

  const requestSignature = async (messageToSign) => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const from = accounts[0];

        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [messageToSign, from],
        });

        return signature;
      } catch (error) {
        console.error("Erro ao assinar a mensagem:", error);
        toast.error("Erro ao assinar a mensagem");
        return null;
      }
    } else {
      toast.error("MetaMask não está conectada");
      console.error("MetaMask não está conectada");
      return null;
    }
  };

  const walletLogin = async () => {
    setLoading(true);
    const endereco = carteira?.getAccount()?.address;

    const response = await apiClient.get(
      `/wallet-login/get-message/${endereco}`
    );
    if (response.sucesso) {
      const mensagem = response.mensagem;
      const signature = await requestSignature(mensagem);
      if (signature) {
        const loginResponse = await apiClient.post("/wallet-login/login", {
          address: endereco,
          signature,
        });
        if (loginResponse.sucesso) {
          localStorage.setItem("authUser", JSON.stringify(loginResponse.dados));
          localStorage.setItem("lastLogin", Date.now());
          setTimeout(() => {
            window.location.href = "/principal";
          }, 200);
        } else {
          console.log(loginResponse.mensagem);
          toast.error(loginResponse.mensagem);
        }
      }
    } else {
      console.log(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const formatAddress = () => {
    const address = carteira?.getAccount()?.address;
    return address
      ? `${address.slice(0, 6)}...${address.slice(-4)}`
      : "Não Conectada";
  };

  useEffect(() => {
    const checkingLogin = async () => {
      setUserDados(getLoggedinUser());
    };
    checkingLogin();
  }, []);
  return (
    <React.Fragment>
      {carteira ? (
        <>
          <Button
            className="btn btn-soft-success"
            onClick={walletLogin}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <i className="ri-wallet-3-line align-middle me-1 mb-1"></i>
            )}{" "}
            Logar com Wallet
          </Button>
          <p className="mt-2">
            <small className="text-muted">
              Carteira Conectada: {formatAddress()}
            </small>
          </p>
        </>
      ) : (
        <ConnectButton
          client={client}
          wallets={wallets}
          theme={darkTheme({
            colors: {
              modalBg: "#131418",
              dropdownBg: "#131418",
              primaryButtonBg: "#f7666e",
              primaryButtonText: "#ededef",
            },
          })}
          connectButton={{
            label: "Conectar Carteira",
          }}
          connectModal={{
            size: "compact",
            title: "Escolha sua Carteira",
            showThirdwebBranding: false,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default BotaoConectar;
