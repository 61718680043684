import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AOS from "aos";
import { ethers } from "ethers";

import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { useSwitchActiveWalletChain, useActiveWallet } from "thirdweb/react";
import { defineChain } from "thirdweb/chains";
import BotaoConectar from "../Authentication/BotaoConectar";
import { erc20 } from "../../assets/abi/Erc20";

import "aos/dist/aos.css";

const apiClient = new APIClient();

const ModalNovo = ({ modalNovo, toggle, setLoading }) => {
  const carteira = useActiveWallet();
  const switchChain = useSwitchActiveWalletChain();

  const [loadingModal, setLoadingModal] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [nome, setNome] = useState("");
  const [token, setToken] = useState({});
  const [tokens, setTokens] = useState([]);

  const [contrato, setContrato] = useState("");
  const [contratos, setContratos] = useState([]);

  const [rede, setRede] = useState({});
  const [redes, setRedes] = useState([]);
  const [tokenContrato, setTokenContrato] = useState("");
  const [tokenSigla, setTokenSigla] = useState("");
  const [tokenDecimais, setTokenDecimais] = useState("");
  const [tokenNome, setTokenNome] = useState("");

  const toggleModal = () => {
    setNome("");
    setToken({});
    setTokenContrato("");
    setTokenSigla("");
    setTokenDecimais(6);
    setTokenNome("");
    setRede({});
    setContrato("");
    setContratos([]);
    toggle();
  };

  const criar = async () => {
    setLoadingModal(true);

    const data = token.value
      ? {
          nome,
          token_id: token.value,
          contrato_id: contrato,
        }
      : {
          nome,
          contrato: ethers.utils.getAddress(tokenContrato.toLowerCase()),
          token_sigla: tokenSigla,
          decimais: parseInt(tokenDecimais),
          token_nome: tokenNome,
          rede_id: rede.value,
          contrato_id: contrato,
        };

    const response = await apiClient.post("/projetos", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      toggleModal();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  const fecthTokens = useCallback(async () => {
    const response = await apiClient.get("/tokens");
    if (response.sucesso) {
      const optionsTokens = response.dados.map((token) => ({
        label: `${token?.nome} (${token?.rede?.nome})`,
        value: `${token?.id}`,
        rede_id: `${token?.rede_id}`,
      }));
      setTokens([
        {
          label: "Tokens",
          options: optionsTokens,
        },
      ]);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchRedes = useCallback(async () => {
    const response = await apiClient.get("/redes");
    if (response.sucesso) {
      const optionsRedes = response.dados.map((rede) => ({
        label: `${rede?.nome} (${rede?.token_rede})`,
        value: `${rede?.id}`,
        sigla: `${rede?.token_rede}`,
        nome: `${rede?.nome}`,
        network_id: `${rede?.network_id}`,
      }));
      setRedes([
        {
          label: "Redes",
          options: optionsRedes,
        },
      ]);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchContratos = useCallback(async (rede) => {
    if (rede === 0) {
      return;
    }
    const response = await apiClient.get(`/contratos/rede/${rede}`);
    if (response.sucesso) {
      setContratos(response.dados);
      if (response.dados.length > 0) {
        setContrato(response.dados[0].id);
      }
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchDecimals = useCallback(async (contractAddress, rede) => {
    if (contractAddress === "") {
      return;
    }
    if (rede === 0) {
      return;
    }
    setWaiting(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, erc20, provider);
      const retorno = await contract.decimals();
      const symbol = await contract.symbol();
      await fetchContratos(rede);
      setTokenDecimais(retorno);
      setTokenSigla(symbol);
    } catch (error) {
      console.error(error);
      toast.error(
        "Houve um erro ao obter as casas decimais. O contrato e a rede estão corretos?"
      );
    } finally {
      setWaiting(false);
    }
  }, []);

  useEffect(() => {
    const executar = async () => {
      await fecthTokens();
      await fetchRedes();
      setLoadingModal(false);
    };
    const executarContratos = async (id_rede) => {
      await fetchContratos(id_rede);
    };
    if (modalNovo && loadingModal) {
      executar();
    }
    if (rede && rede.network_id) {
      switchChain(
        defineChain({
          id: rede.network_id,
          nativeCurrency: {
            name: rede.nome,
            symbol: rede.sigla,
            decimals: 18,
          },
        })
      );
    }
    AOS.init({
      easing: "ease-out-back",
      duration: 3000,
      anchorPlacement: "top-bottom",
    });
    if (tokenContrato && rede && rede.network_id) {
      fetchDecimals(tokenContrato, rede.network_id);
    }
    if (rede && rede.value) {
      executarContratos(rede.value);
    }
  }, [modalNovo, rede, tokenContrato]);

  return (
    <Modal
      isOpen={modalNovo}
      toggle={() => {
        toggleModal();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggleModal();
        }}
      >
        Novo Projeto
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={80}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="token" className="form-label">
                  Token <span className="text-danger">*</span>
                </Label>
                <Select
                  value={token}
                  onChange={(sortBy) => {
                    setToken(sortBy);
                    setRede({
                      value: `${sortBy?.rede_id}`,
                    });
                  }}
                  options={tokens}
                  id="token"
                  placeholder={
                    loadingModal ? "Carregando..." : "Selecione o Token"
                  }
                  disabled={loadingModal}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#1f2429",
                      border: "1px solid #2a4661",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </div>
            </Col>
          </Row>
          {contratos.length > 0 && (
            <Row className="mt-3">
              <Col md="12">
                <div>
                  <Label htmlFor="contratos" className="form-label">
                    Contrato de Envio <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="contratos"
                    className="form-control"
                    type="select"
                    value={contrato}
                    onChange={(e) => setContrato(e.target.value)}
                  >
                    {contratos.map((contrato) => {
                      if(contrato.ativo) return(
                      <option key={contrato.id} value={contrato.id}>
                        {contrato.nome}
                      </option>
                    )})}
                  </Input>
                </div>
              </Col>
            </Row>
          )}
          {!token.value && (
            <>
              <Row className="mt-3 text-center">
                <div className="signin-other-title">
                  <h5 className="fs-13 mb-4 title text-muted">
                    ou Cadastre o Token
                  </h5>
                </div>
              </Row>
              {!carteira ? (
                <Row className="justify-content-center mt-3">
                  <BotaoConectar />
                </Row>
              ) : (
                <div data-aos="fade-up">
                  <Row className="mt-3">
                    <Col md="6">
                      <div>
                        <Label htmlFor="redes" className="form-label">
                          Rede <span className="text-danger">*</span>
                        </Label>
                        <Select
                          value={rede}
                          onChange={(sortBy) => {
                            setRede(sortBy);
                          }}
                          options={redes}
                          id="redes"
                          placeholder={
                            loadingModal ? "Carregando..." : "Selecione a Rede"
                          }
                          disabled={loadingModal}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#1f2429",
                              border: "1px solid #2a4661",
                              color: "#aaa",
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: "#aaa",
                            }),
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <Label htmlFor="contrato" className="form-label">
                        Contrato <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="contrato"
                        className="form-control"
                        type="text"
                        placeholder="0x..."
                        value={tokenContrato}
                        onChange={(e) => setTokenContrato(e.target.value)}
                        maxLength={42}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md="12">
                      <div>
                        <Label htmlFor="token_nome" className="form-label">
                          Nome do Token <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="token_nome"
                          className="form-control"
                          type="text"
                          placeholder="Nome"
                          value={tokenNome}
                          onChange={(e) => setTokenNome(e.target.value)}
                          maxLength={80}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="6">
                      <div>
                        <Label htmlFor="sigla" className="form-label">
                          Sigla <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="sigla"
                          className="form-control"
                          type="text"
                          placeholder={waiting ? "Aguardando..." : "Sigla"}
                          value={tokenSigla}
                          onChange={(e) => setTokenSigla(e.target.value)}
                          maxLength={15}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <Label htmlFor="decimais" className="form-label">
                          Decimais <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="decimais"
                          className="form-control"
                          type="text"
                          placeholder={waiting ? "Aguardando..." : "Decimais"}
                          value={tokenDecimais}
                          onChange={(e) =>
                            setTokenDecimais(e.target.value.replace(/\D/g, ""))
                          }
                          maxLength={2}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </>
          )}

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggleModal()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-success"
              onClick={() => criar()}
              disabled={loadingModal}
            >
              Criar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
