import React, { useState, useCallback, useEffect } from "react";
import { Button, Col, Container, Input, Row, Spinner } from "reactstrap";
import logoLight from "../../assets/images/logo-light.png";
import { useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();
import {
  converterData,
  formatEmail,
} from "../../Components/FunctionsRepository";
import CryptoJS from "crypto-js";

const AlocacaoPublica = () => {
  const { projeto_id, codigo } = useParams();

  const codigoGrupo1 = CryptoJS.MD5(`ALOCACAO:GRUPO1:${projeto_id}`);
  const codigoGrupo2 = CryptoJS.MD5(`ALOCACAO:GRUPO2:${projeto_id}`);
  const codigoGrupo3 = CryptoJS.MD5(`ALOCACAO:GRUPO3:${projeto_id}`);

  const [projeto, setProjeto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [finalizado, setFinalizado] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [titulo, setTitulo] = useState(null);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [carteira, setCarteira] = useState("");

  const getGrupo = () => {
    return codigo === codigoGrupo1.toString()
      ? 1
      : codigo === codigoGrupo2.toString()
      ? 2
      : codigo === codigoGrupo3.toString()
      ? 3
      : 0;
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const response = await apiClient.postPublico("/alocacoes/form-entry", {
      nome_completo: nome,
      email,
      carteira,
      alocacao_id: projeto_id,
      grupo: getGrupo(),
    });
    if (response.sucesso) {
      toast.success(response.mensagem);
      setNome("");
      setEmail("");
      setCarteira("");
      setFinalizado(true);
    } else {
      toast.error(response.mensagem);
    }
    setLoadingSubmit(false);
  };

  const fetchProjeto = useCallback(async (id) => {
    setLoading(true);
    if (!titulo) {
      const response = await apiClient.get(`/alocacoes/${id}`);
      if (response.sucesso) {
        setProjeto(response.dados);
        setTitulo(response.dados.titulo);
        document.title = `${response.dados.titulo} | Claim My Tokens`;
      } else {
        console.error(response.mensagem);
        toast.error(response.mensagem);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async (projeto_id, titulo) => {
      await fetchProjeto(projeto_id, titulo);
    };
    fetchData(projeto_id, titulo);
  }, [projeto_id, codigo]);

  return (
    <React.Fragment>
      <section className="section" id="contact">
        {!projeto?.convite ? (
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-5">
                  <span className="logo-sm">
                    <img src={logoLight} alt="" height="45" />
                  </span>
                  <p className="text-muted mb-4 mt-5 ff-secondary">
                    Este projeto é privado e requer um convite para acessar.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        ) : getGrupo() === 0 ? (
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-5">
                  <span className="logo-sm">
                    <img src={logoLight} alt="" height="45" />
                  </span>
                  <p className="text-muted mb-4 mt-5 ff-secondary">
                    O link que você acessou não é válido. Por favor, verifique
                    com o administrador do projeto.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        ) : finalizado ? (
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-5">
                  <span className="logo-sm">
                    <img src={logoLight} alt="" height="45" />
                  </span>
                  <p className="text-muted mb-4 mt-5 ff-secondary">
                    Obrigado por preencher o formulário. Você receberá um e-mail
                    de confirmação em breve.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        ) : loading ? (
          <Container>
            <Row className="justify-content-center">
              <Spinner color="primary" />
            </Row>
          </Container>
        ) : (
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-5">
                  <span className="logo-sm">
                    <img src={logoLight} alt="" height="45" />
                  </span>
                  <p className="text-muted mb-4 mt-5 ff-secondary">
                    Preencha os campos abaixo do fomulário para ser elegível
                    para alocação no projeto.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="gy-4">
              <Col lg={4}>
                <div>
                  <div className="mt-4">
                    <h5 className="fs-13 text-muted text-uppercase">
                      {projeto?.titulo}
                    </h5>
                    <div className="ff-secondary fw-semibold">
                      {projeto?.subtitulo}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h5 className="fs-13 text-muted text-uppercase">
                      Data de Início Prevista
                    </h5>
                    <div className="ff-secondary fw-semibold">
                      {projeto?.envios.length > 0
                        ? converterData(projeto?.envios[0].data_envio)
                        : "Projeto sem data de início"}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h5 className="fs-13 text-muted text-uppercase">
                      Redes Sociais / Sites
                    </h5>
                    <div className="ff-secondary fw-semibold">
                      {!projeto?.site &&
                        !projeto?.instagram_url &&
                        !projeto?.twitter_url &&
                        !projeto?.discord_url &&
                        "Projeto não tem redes sociais"}
                      {projeto?.site && projeto?.site !== "" && (
                        <a
                          href={projeto?.site}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon icon="globe" className="icon-md" />
                        </a>
                      )}
                      {projeto?.twitter_url && projeto?.twitter_url !== "" && (
                        <a
                          href={projeto?.twitter_url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon icon="twitter" className="icon-md" />
                        </a>
                      )}
                      {projeto?.discord_url && projeto?.discord_url !== "" && (
                        <a
                          href={projeto?.discord_url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon
                            icon="message-circle"
                            className="icon-md"
                          />
                        </a>
                      )}
                      {projeto?.instagram_url &&
                        projeto?.instagram_url !== "" && (
                          <a
                            href={projeto?.instagram_url}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary"
                          >
                            <FeatherIcon icon="instagram" className="icon-md" />
                          </a>
                        )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h5 className="fs-13 text-muted text-uppercase">
                      Dados de Distribuição
                    </h5>
                    <div className="ff-secondary fw-semibold">
                      <strong className="text-warning">Periodicidade:</strong>{" "}
                      {projeto?.periodo_distribuicao.charAt(0).toUpperCase() +
                        projeto?.periodo_distribuicao.slice(1)}
                      <br />
                      <strong className="text-warning">Períodos:</strong>{" "}
                      {projeto?.periodos}
                      <br />
                      <strong className="text-warning">
                        Dia de distribuição:
                      </strong>{" "}
                      {projeto?.distribuicao_dia}
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={8}>
                <div>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-4">
                        <label htmlFor="name" className="form-label fs-13">
                          Nome Completo <span className="text-danger">*</span>
                        </label>
                        <Input
                          id="name"
                          type="text"
                          className="form-control bg-light border-light"
                          placeholder="Seu nome"
                          maxLength={255}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-4">
                        <label htmlFor="email" className="form-label fs-13">
                          E-mail <span className="text-danger">*</span>
                        </label>
                        <Input
                          id="email"
                          type="email"
                          className="form-control bg-light border-light"
                          placeholder="Seu e-mail"
                          maxLength={150}
                          onChange={(e) =>
                            setEmail(formatEmail(e.target.value))
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-4">
                        <label htmlFor="carteira" className="form-label fs-13">
                          Carteira <span className="text-danger">*</span>
                        </label>
                        <Input
                          type="carteira"
                          className="form-control bg-light border-light"
                          id="carteira"
                          placeholder="0x..."
                          maxLength={42}
                          onChange={(e) => setCarteira(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="text-end">
                      <Button
                        color="primary"
                        id="submit"
                        className="submitBnt btn btn-primary"
                        onClick={() => handleSubmit()}
                        disabled={!nome || !email || !carteira || loadingSubmit}
                      >
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default AlocacaoPublica;
