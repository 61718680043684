import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Spinner, Input, Label, Button } from "reactstrap";
import { getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import Section from "./Section";
import Widgets from "./Widgets";
import UltimosClientes from "./UltimosClientes";
import UltimosEnvios from "./UltimosEnvios";
import { APIClient } from "../../helpers/api_helper";
import error from "../../assets/images/error.svg";
import { useSwitchActiveWalletChain, useActiveWallet } from "thirdweb/react";
import { defineChain } from "thirdweb/chains";

import { GraficoProjeto } from "./GraficoCliente";

const apiClient = new APIClient();

const DashboardEcommerce = () => {
  document.title = "Dashboard | Claim My Tokens";

  const wallet = useActiveWallet();

  const [userDados, setUserDados] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [tableContents, setTableContents] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState(null);
  const [carteiras, setCarteiras] = useState([]);
  const [carteira, setCarteira] = useState(null);
  const [carteiraEnvio, setCarteiraEnvio] = useState(null);
  const [envios, setEnvios] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Enviados",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const getLast12Months = (numerico = false) => {
    const months = [];
    const date = new Date();

    if (!numerico) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        month: "short",
        year: "numeric",
      });

      for (let i = 0; i < 12; i++) {
        const newDate = new Date();
        newDate.setMonth(newDate.getMonth() - i);
        months.push(formatter.format(newDate).replace(". de ", "."));
      }
    } else {
      for (let i = 0; i < 12; i++) {
        const newDate = new Date();
        newDate.setMonth(newDate.getMonth() - i);
        months.push(newDate.getMonth());
      }
    }

    return months.reverse();
  };

  const getCarteiraById = (id) => {
    let carteiraAddress = "";
    carteiras.forEach((carteira) => {
      if (carteira.id === id) {
        carteiraAddress = carteira.carteira;
      }
    });
    return carteiraAddress;
  };

  const fetchEnvios = useCallback(async (projeto) => {
    const response = await apiClient.get(`/claim/${projeto?.id}`);

    if (response.sucesso) {
      const enviosTotal = response.dados;
      const last12Months = getLast12Months();

      // Cria uma cópia do objeto series[0] para evitar mutação direta do estado
      let newSerie = {
        name: "Enviados",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      };

      // Itera os envios e acumula os valores de amount no array data, conforme o mês
      enviosTotal.forEach((envio) => {
        const mesEnvio = new Date(envio.created_at.replace(" ", "T"))
          .toLocaleString("pt-BR", { month: "short", year: "numeric" })
          .replace(". de ", ".");
        const indexMes = last12Months.findIndex((mes) => mes === mesEnvio);

        if (indexMes !== -1) {
          newSerie.data[indexMes] += parseFloat(
            envio.amount / 10 ** projeto.token.decimais
          );
        }
      });

      // Atualiza o estado series com o objeto newSerie atualizado
      setSeries([newSerie]);
      setEnvios(enviosTotal);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  const switchChain = useSwitchActiveWalletChain();
  const trocaRede = async (proj) => {
    try {
      await switchChain(
        defineChain({
          id: proj?.token?.rede?.network_id,
          nativeCurrency: {
            name: proj?.token?.rede?.nome,
            symbol: proj?.token?.rede?.sigla,
            decimals: 18,
          },
        })
      );
    } catch (e) {
      console.error(e);
      if (e.code && e.code !== -32002) toast.error("Erro ao trocar de rede");
      return false;
    }
    return true;
  };

  const toggleProjeto = async (proj) => {
    setProjeto(proj);
    await trocaRede(proj);
    await fetchEnvios(proj);
  };

  const getLast15 = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/dashboard/last-15");
    if (response.sucesso) {
      setTableContents(response.dados);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const fetchProjetos = useCallback(async (params = {}) => {
    setLoading(true);
    const response = await apiClient.post("/projetos/buscar", {});

    if (response.sucesso) {
      setProjetos(response.dados);
      if (response.dados.length > 0) {
        toggleProjeto(response.dados[0]);
      }
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const changeCarteiraProjeto = async () => {
    setLoadingEdit(true);
    const response = await apiClient.put(`/carteiras/${carteira}/projeto`, {
      projeto_id: projeto.id,
    });
    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoadingEdit(false);
  };

  const getCarteiras = useCallback(async (projeto) => {
    const response = await apiClient.get(`/carteiras`);
    if (response.sucesso) {
      let carteirasArray = [];
      if (!response.dados.carteirasAdmin) {
        carteirasArray = response.dados;
        setCarteiras(carteirasArray);
        for (let i = 0; i < carteirasArray.length; i++) {
          let quebrarlacos = false;
          for (let j = 0; j < carteirasArray[i].projeto_link.length; j++) {
            if (carteirasArray[i].projeto_link[j].projeto_id === projeto.id) {
              setCarteira(carteirasArray[i].projeto_link[j].carteira_id);
              setCarteiraEnvio(carteirasArray[i].carteira);
              quebrarlacos = true;
              break;
            }
          }
          if (quebrarlacos) break;
        }
      }
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const checkingLogin = async () => {
      const datalogado = getLoggedinUser();
      setUserDados(datalogado);
      if (datalogado.role !== "cliente") await getLast15();
      else await fetchProjetos();
    };
    const loadWallets = async (projeto) => {
      await getCarteiras(projeto);
    };
    if (!projeto && wallet) checkingLogin();
    else if (projeto) loadWallets(projeto);
    else {
      checkingLogin();
      setLoading(false);
    }
  }, [projeto, wallet]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section />
                {loading ? (
                  <Row className="justify-content-center align-items-center h-100">
                    <Spinner />
                  </Row>
                ) : userDados?.role === "admin" ||
                  userDados?.role === "user" ? (
                  <>
                    <Row>
                      {userDados?.role === "admin" ? <Widgets /> : null}
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <UltimosClientes
                          userDados={userDados}
                          clientes={tableContents?.clientes || []}
                        />
                      </Col>
                      <Col xl={6}>
                        <UltimosEnvios envios={tableContents?.envios || []} />
                      </Col>
                    </Row>
                  </>
                ) : userDados?.role === "cliente" ? (
                  <Row>
                    <Col md="12">
                      <h4 className="header-title mb-3">Projetos</h4>
                      <Input
                        type="select"
                        value={projeto?.id}
                        onChange={(e) => {
                          const proj = projetos.find(
                            (p) => p.id === parseInt(e.target.value)
                          );
                          toggleProjeto(proj);
                        }}
                      >
                        {projetos.map((proj) => (
                          <option key={proj.id} value={proj.id}>
                            {proj.nome} - {proj.token.nome} ({proj.token.sigla})
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col md="10 mt-3">
                      <div className="form-group">
                        <Label className="form-label">
                          Carteira cadastrada
                        </Label>
                        <Input
                          type="select"
                          value={carteira || ""}
                          onChange={(e) => {
                            setCarteira(e.target.value);
                          }}
                        >
                          {carteiras.map((carteira) => (
                            <option key={carteira.id} value={carteira.id}>
                              {carteira.carteira}
                            </option>
                          ))}
                        </Input>
                        {wallet?.getAccount()?.address !==
                          getCarteiraById(carteira) && (
                          <small className="text-danger">
                            A carteira logada não é a mesma cadastrada para o
                            projeto
                          </small>
                        )}
                      </div>
                    </Col>
                    <Col md="2 mt-5 justify-content-end text-right">
                      <Button
                        color="info"
                        onClick={() => changeCarteiraProjeto()}
                        size="sm"
                        disabled={loadingEdit}
                      >
                        Mudar
                      </Button>
                    </Col>

                    <Col md="12 mt-4">
                      {projeto ? (
                        <GraficoProjeto
                          dataColors='["--vz-warning"]'
                          projeto={projeto}
                          trocaRede={trocaRede}
                          envios={envios}
                          series={series}
                          fetchEnvios={fetchEnvios}
                          getLast12Months={getLast12Months}
                          loading={loading}
                          setLoading={setLoading}
                          carteiraEnvio={carteiraEnvio}
                        />
                      ) : (
                        <div className="text-center pt-4">
                          <div className="">
                            <img
                              src={error}
                              alt=""
                              height={300}
                              className="error-basic-img move-animation"
                            />
                          </div>
                          <div className="mt-n4">
                            <h3 className="text-uppercase">
                              Parece que você não tem projetos cadastrados 😭
                            </h3>
                            <p className="text-muted mb-4">
                              Não se preocupe, caso você já esteja na fila de
                              cadastro para algum projeto, ele aparecerá aqui
                              assim que for aprovado.
                            </p>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
