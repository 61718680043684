import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalEditar = ({ modalEditar, toggle, setLoading, usuario }) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [tipo, setTipo] = useState("user");
  const [statusUser, setStatusUser] = useState("ativo");

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const atualizar = async (userId) => {
    setLoadingModal(true);

    const data = {
      name: nome,
      sobrenome,
      email,
      password,
      password_confirmation: passwordConfirm,
      tipo,
      status: statusUser,
    };

    const response = await apiClient.put(`/users/${userId}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      setNome("");
      setSobrenome("");
      setEmail("");
      setPassword("");
      setPasswordConfirm("");
      setTipo("user");
      setStatusUser("ativo");

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  useEffect(() => {
    if (usuario) {
      setNome(usuario?.name);
      setSobrenome(usuario?.sobrenome);
      setEmail(usuario?.email);
      setTipo(usuario?.tipo);
      setStatusUser(usuario?.status);
    }
  }, [usuario]);

  return (
    <Modal
      isOpen={modalEditar}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Usuário {usuario?.name}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail <span className="text-danger">*</span>
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e)}
                  maxLength={100}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg="6">
              <div className="form-group">
                <Label className="form-label">Senha <span className="text-danger">*</span></Label>
                <Input
                  id="password"
                  placeholder="Digite apenas se deseja alterar a senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={30}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Label className="form-label">Confirmação de Senha <span className="text-danger">*</span></Label>
                <Input
                  id="passwordConfirm"
                  placeholder="Digite a senha novamente"
                  type="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  maxLength={30}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg="6">
              <div className="form-group">
                <Label className="form-label">Tipo</Label>
                <Input
                  type="select"
                  className="form-select"
                  id="tipo"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="user">Usuário</option>
                  <option value="admin">Administrador</option>
                </Input>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Label className="form-label">Status</Label>
                <Input
                  type="select"
                  className="form-select"
                  id="status-user"
                  value={statusUser}
                  onChange={(e) => setStatusUser(e.target.value)}
                >
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                  <option value="bloqueado">Bloqueado</option>
                </Input>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-info"
              onClick={() => atualizar(usuario?.id)}
              disabled={loadingModal}
            >
              Editar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
