import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalStatus = ({ modal, toggle, setLoading, projeto }) => {
  const [loadingModal, setLoadingModal] = useState(false);

  const mudarStatus = async (projeto) => {
    setLoadingModal(true);

    const response = await apiClient.put(`/alocacoes/${projeto.id}/status`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {projeto?.status === "inativo" ? "Ativar" : "Desativar"}
        Projeto <b>{projeto?.titulo || ""}</b>
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <h4 className="fw-bold">
              Confirmação de{" "}
              {projeto?.status === "inativo" ? "Ativação" : "Desativação"} de
              Projeto
            </h4>
            <p className="mt-5" style={{ fontSize: "1rem" }}>
              Tem certeza de que deseja
              {projeto?.status === "inativo" ? (
                <b className="text-success"> Ativar </b>
              ) : (
                <b className="text-danger"> Desativar </b>
              )}
              este projeto?
            </p>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className={`btn btn-link link-${
                projeto?.status === "inativo" ? "success" : "danger"
              } fw-medium`}
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className={`btn btn-${
                projeto?.status === "inativo" ? "success" : "danger"
              }`}
              onClick={() => mudarStatus(projeto)}
              disabled={loadingModal}
            >
              {projeto?.status === "inativo" ? "Ativar" : "Desativar"}
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalStatus;
