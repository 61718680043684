import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import FeatherIcon from "feather-icons-react";
import { formatAddress } from "../../Components/FunctionsRepository";

const UltimosClientes = ({ clientes, userDados }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState([]);

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < Math.ceil(clientes.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(currentItems);
  }, [currentPage, clientes]);
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Últimos Clientes Cadastrados
            </h4>
            <div className="flex-shrink-0">
              <UncontrolledDropdown className="card-header-dropdown">
                <DropdownToggle
                  tag="a"
                  className="text-reset dropdown-btn"
                  role="button"
                >
                  <span className="text-muted">
                    Ver<i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-end" end>
                  <DropdownItem>
                    <Link to="/clientes">Ver Todos os Clientes</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-centered table-hover align-middle table-nowrap mb-0">
                <tbody>
                  {currentItems.map((item, key) => (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <FeatherIcon
                              icon="user"
                              className={`avatar-sm p-2 text-success`}
                            />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1 fw-medium">
                              <Link
                                to="/apps-ecommerce-seller-details"
                                className="text-reset"
                              >
                                {item.nome}
                              </Link>
                            </h5>
                            <small
                              className="text-muted"
                              style={{ 
                                fontSize: "10px",
                                cursor: "pointer"
                               }}
                              title={item.user?.carteiras[0]?.carteira}
                            >
                              {formatAddress(item.user?.carteiras[0]?.carteira)}
                            </small>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted">{item.email}</span>
                      </td>
                      <td>
                        <b>
                          {converterData(
                            userDados.role === "admin"
                              ? item.created_at
                              : item.created_at
                          )}
                        </b>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
              <div className="col-sm">
                <div className="text-muted">
                  Mostrando <span className="fw-semibold">5</span>
                  {" de "}
                  <span className="fw-semibold">15</span> clientes
                </div>
              </div>
              <div className="col-sm-auto mt-3 mt-sm-0">
                <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <Link to="#" className="page-link" onClick={prevPage}>
                      ←
                    </Link>
                  </li>
                  {Array.from(
                    { length: Math.ceil(clientes.length / itemsPerPage) },
                    (_, i) => (
                      <li
                        key={i + 1}
                        className={`page-item ${
                          currentPage === i + 1 ? "active" : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(clientes.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <Link to="#" className="page-link" onClick={nextPage}>
                      →
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default UltimosClientes;
