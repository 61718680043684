import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Label,
  CardHeader,
  Spinner,
  Button,
  CardBody,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const Importacao = () => {
  const [loading, setLoading] = useState(false);
  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState(null);
  const [sheetLink, setSheetLink] = useState("");
  const [sheetName, setSheetName] = useState("");
  const [rowOffset, setRowOffset] = useState("");
  const [nameColumn, setNameColumn] = useState("");
  const [lastNameColumn, setLastNameColumn] = useState("");
  const [phoneColumn, setPhoneColumn] = useState("");
  const [walletColumn, setWalletColumn] = useState("");
  const [emailColumn, setEmailColumn] = useState("");
  const [amountColumn, setAmountColumn] = useState("");
  const [data, setData] = useState([]);
  const [clientesCriados, setClientesCriados] = useState([]);
  const [clientesAtualizados, setClientesAtualizados] = useState([]);
  const [clientesApagados, setClientesApagados] = useState([]);
  const [clientesErro, setClientesErro] = useState([]);

  function extractIdFromLink(link) {
    const regex = /\/d\/([^/?\s]+)/;
    const match = link.match(regex);

    if (match) {
      return match[1];
    } else {
      return link;
    }
  }

  const handleSubmit = async () => {
    if (!projeto) toast.error("Selecione um projeto antes de continuar");

    setLoading(true);
    const postData = {
      projeto_id: projeto.id,
      sheet_link: extractIdFromLink(sheetLink),
      sheet_name: sheetName,
      offset: rowOffset === "" ? 0 : rowOffset,
      col_nome: nameColumn,
      col_sobrenome: phoneColumn === "" ? 0 : lastNameColumn,
      col_phone: phoneColumn === "" ? 0 : phoneColumn,
      col_wallet: walletColumn,
      col_amount: amountColumn,
      col_email: emailColumn,
    };
    const response = await apiClient.post("google-sheets-imports", postData);
    if (response.sucesso) {
      const importData = response.dados.import_data;
      setData(response.dados.sheet_data);
      setSheetLink(importData.sheet_link);
      setSheetName(importData.sheet_name);
      setRowOffset(importData.offset);
      setNameColumn(importData.col_nome);
      setLastNameColumn(importData.col_sobrenome);
      setPhoneColumn(importData.col_phone);
      setWalletColumn(importData.col_wallet);
      setEmailColumn(importData.col_email);
      setAmountColumn(importData.col_amount);

      toast.success(response.mensagem);
    } else {
      toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const sync = async () => {
    setLoading(true);
    const response = await apiClient.post(
      `google-sheets-imports/${projeto.id}/sync`
    );
    if (response.sucesso) {
      setClientesCriados(response.dados.clientes_criados);
      setClientesAtualizados(response.dados.clientes_atualizados);
      setClientesApagados(response.dados.clientes_apagados);
      setClientesErro(response.dados.clientes_erro);
      toast.success(response.mensagem);
    } else {
      toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const resetarPlanilha = async () => {
    setLoading(true);
    const response = await apiClient.delete(
      `google-sheets-imports/${projeto.id}`
    );
    if (response.sucesso) {
      setSheetLink("");
      setSheetName("");
      setRowOffset("");
      setNameColumn("");
      setLastNameColumn("");
      setPhoneColumn("");
      setWalletColumn("");
      setEmailColumn("");
      setAmountColumn("");
      setData([]);
      setClientesCriados([]);
      setClientesAtualizados([]);
      setClientesApagados([]);
      setClientesErro([]);
      toast.success(response.mensagem);
    } else {
      toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const fetchProjetos = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/projetos", {});

    if (response.sucesso) {
      setProjetos(response.dados);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const fetchProjeto = useCallback(async (projeto) => {
    setLoading(true);
    setProjeto(projeto);
    const response = await apiClient.get(
      `/google-sheets-imports/${projeto.id}/projeto`
    );
    if (response.sucesso) {
      const planilhaDados = response.dados.sheet_data;
      const importacaoDados = response.dados.import_data;
      setSheetLink(importacaoDados.sheet_link);
      setSheetName(importacaoDados.sheet_name);
      setRowOffset(importacaoDados.offset);
      setNameColumn(importacaoDados.col_nome);
      setLastNameColumn(importacaoDados.col_sobrenome);
      setPhoneColumn(importacaoDados.col_phone);
      setWalletColumn(importacaoDados.col_wallet);
      setEmailColumn(importacaoDados.col_email);
      setAmountColumn(importacaoDados.col_amount);
      setData(planilhaDados);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const rodar = async () => {
      await fetchProjetos();
    };
    rodar();
  }, [projeto]);

  document.title = "Importar Clientes | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Importar Clientes (Planilha Google)"
            pageTitle="Painel"
          />
          <Card>
            <CardHeader>
              <small className="text-muted">
                Os campos marcados com <span className="text-danger">*</span>{" "}
                são obrigatórios
              </small>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Label>
                    Projeto <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    value={projeto?.id}
                    onChange={(e) => {
                      const proj = projetos.find(
                        (p) => p.id === parseInt(e.target.value)
                      );
                      fetchProjeto(proj);
                    }}
                  >
                    {!projeto && <option value="">Selecione um Projeto</option>}
                    {projetos.map((proj) => (
                      <option key={proj.id} value={proj.id}>
                        {proj.nome} - {proj.token.nome} ({proj.token.sigla})
                      </option>
                    ))}
                  </Input>
                </Col>
                {projeto && (
                  <>
                    <Col md="4 mt-3">
                      <Label>
                        Link ou ID da Spreadsheet{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Link da Spreadsheet"
                        value={sheetLink}
                        onChange={(e) => setSheetLink(e.target.value)}
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Nome da Sheet <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Nome da Sheet"
                        value={sheetName}
                        onChange={(e) => setSheetName(e.target.value)}
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Linhas a pular (offset)
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Quantidade de linhas a pular"
                        value={rowOffset}
                        onChange={(e) =>
                          setRowOffset(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Coluna para Nome <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Número da coluna"
                        value={nameColumn}
                        onChange={(e) =>
                          setNameColumn(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Coluna para Sobrenome
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Número da coluna"
                        value={lastNameColumn}
                        onChange={(e) =>
                          setLastNameColumn(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>Coluna para Telefone</Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Número da coluna"
                        value={phoneColumn}
                        onChange={(e) =>
                          setPhoneColumn(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Coluna para Carteira{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Número da coluna"
                        value={walletColumn}
                        onChange={(e) =>
                          setWalletColumn(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Coluna para Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Número da coluna"
                        value={emailColumn}
                        onChange={(e) =>
                          setEmailColumn(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="4 mt-3">
                      <Label>
                        Coluna para Quantidade de Tokens{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Número da coluna"
                        value={amountColumn}
                        onChange={(e) =>
                          setAmountColumn(
                            parseInt(e.target.value.replace(/\D/g, ""))
                          )
                        }
                      />
                    </Col>
                    <Col md="12 mt-3 text-right d-flex justify-content-end">
                      {data.length > 0 && (
                        <>
                          <Button
                            className="me-2"
                            color="info"
                            onClick={() => {
                              sync();
                            }}
                            disabled={loading}
                          >
                            Sincronizar
                          </Button>
                          <Button
                            className="me-2"
                            color="danger"
                            onClick={() => {
                              resetarPlanilha();
                            }}
                            disabled={loading}
                          >
                            Limpar Planilha
                          </Button>
                        </>
                      )}
                      {clientesCriados.length == 0 &&
                        clientesAtualizados.length == 0 &&
                        clientesApagados.length == 0 &&
                        clientesErro.length == 0 && (
                          <Button
                            color="secondary"
                            onClick={() => {
                              handleSubmit();
                            }}
                            disabled={loading}
                          >
                            Ler Planilha
                          </Button>
                        )}
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>

          {clientesCriados.length == 0 &&
          clientesAtualizados.length == 0 &&
          clientesApagados.length == 0 &&
          clientesErro.length == 0 ? (
            <Card>
              <CardHeader>
                <h4 className="card-title">Clientes Encontrados na Planilha</h4>
              </CardHeader>
              <CardBody>
                <table className="table table-hover table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Sobrenome</th>
                      <th scope="col">Telefone</th>
                      <th scope="col">Carteira</th>
                      <th scope="col">Email</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        <td>{row.name}</td>
                        <td>{row.lastName}</td>
                        <td>{row.phone}</td>
                        <td>{row.wallet}</td>
                        <td>{row.email}</td>
                        <td>{row.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : (
            <>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Clientes Adicionados</h4>
                </CardHeader>
                <CardBody>
                  <table className="table table-hover table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Sobrenome</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Carteira</th>
                        <th scope="col">Email</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientesCriados.map((row, index) => (
                        <tr key={index} className="text-success">
                          <td>{row.name}</td>
                          <td>{row.lastName}</td>
                          <td>{row.phone}</td>
                          <td>{row.wallet}</td>
                          <td>{row.email}</td>
                          <td>{row.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h4 className="card-title">
                    Clientes Atualizados ou mantidos
                  </h4>
                </CardHeader>

                <CardBody>
                  <table className="table table-hover table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Sobrenome</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Carteira</th>
                        <th scope="col">Email</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientesAtualizados.map((row, index) => (
                        <tr key={index} className="text-info">
                          <td>{row.name}</td>
                          <td>{row.lastName}</td>
                          <td>{row.phone}</td>
                          <td>{row.wallet}</td>
                          <td>{row.email}</td>
                          <td>{row.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h4 className="card-title">Clientes Apagados ou Alterados</h4>
                </CardHeader>

                <CardBody>
                  <table className="table table-hover table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Sobrenome</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Carteira</th>
                        <th scope="col">Email</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientesApagados.map((row, index) => (
                        <tr key={index} className="text-warning">
                          <td>{row.name}</td>
                          <td>{row.lastName}</td>
                          <td>{row.phone}</td>
                          <td>{row.wallet}</td>
                          <td>{row.email}</td>
                          <td>{row.amount/(10** (projeto?.token?.decimais || 0))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h4 className="card-title">Clientes com Erro</h4>
                </CardHeader>

                <CardBody>
                  <table className="table table-hover table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Sobrenome</th>
                        <th scope="col">Carteira</th>
                        <th scope="col">Email</th>
                        <th scope="col">Erro</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientesErro.map((row, index) => (
                        <tr key={index} className="text-danger">
                          <td>{row.name}</td>
                          <td>{row.lastName}</td>
                          <td>{row.wallet}</td>
                          <td>{row.email}</td>
                          <td>{row.error}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Importacao;
