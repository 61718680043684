import React, { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Widgets = () => {
  const [loading, setLoading] = useState(true);
  const [ecomWidgets, setEcomWidgets] = useState([
    {
      id: 1,
      cardColor: "primary",
      label: "Total Clientes",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      counter: "0",
      link: "página de Clientes",
      bgcolor: "secondary",
      icon: "bx bx-user-circle",
      separator: ".",
    },
    {
      id: 2,
      cardColor: "info",
      label: "Total Usuários",
      badge: "ri-arrow-right-down-line",
      badgeClass: "danger",
      counter: "0",
      link: "página de Usuários",
      bgcolor: "info",
      icon: "bx bx-user",
      decimals: 0,
      separator: ".",
    },
    {
      id: 3,
      cardColor: "success",
      label: "Projetos Cadastrados",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      counter: "0",
      link: "página de Projetos",
      bgcolor: "success",
      icon: "bx bx-folder",
      separator: ".",
    },
    {
      id: 4,
      cardColor: "info",
      label: "Envios realizados",
      badgeClass: "muted",
      counter: "0",
      link: "página de Envios",
      bgcolor: "warning",
      icon: "bx bx-send",
      separator: ".",
    },
  ]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/dashboard/quantidades");
    if (response.sucesso) {
      setEcomWidgets([
        {
          id: 1,
          cardColor: "primary",
          label: "Total Clientes",
          badge: "ri-arrow-right-up-line",
          badgeClass: "success",
          counter: response.dados.clientes,
          link: "página de Clientes",
          bgcolor: "secondary",
          icon: "bx bx-user-circle",
          separator: ".",
        },
        {
          id: 2,
          cardColor: "info",
          label: "Total Usuários",
          badge: "ri-arrow-right-down-line",
          badgeClass: "danger",
          counter: response.dados.usuarios,
          link: "página de Usuários",
          bgcolor: "info",
          icon: "bx bx-user",
          decimals: 0,
          separator: ".",
        },
        {
          id: 3,
          cardColor: "success",
          label: "Projetos Cadastrados",
          badge: "ri-arrow-right-up-line",
          badgeClass: "success",
          counter: response.dados.projetos,
          link: "página de Projetos",
          bgcolor: "success",
          icon: "bx bx-folder",
          separator: ".",
        },
        {
          id: 4,
          cardColor: "info",
          label: "Envios realizados",
          badgeClass: "muted",
          counter: response.dados.envios,
          link: "página de Envios",
          bgcolor: "warning",
          icon: "bx bx-send",
          separator: ".",
        },
      ]);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [fetchData]);

  return (
    <React.Fragment>
      {ecomWidgets.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target={item.counter}>
                      {loading ? (
                        <Spinner size="sm" color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={3}
                        />
                      )}
                    </span>
                  </h4>
                  <Link to="#" className="text-decoration-underline">
                    {item.link}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded fs-3 bg-" + item.bgcolor + "-subtle"
                    }
                  >
                    <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
