import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader, Spinner } from "reactstrap";

import { useParams } from "react-router-dom";
import Paginacao from "../../Components/Common/Paginacao";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchOption from "./SearchOption";

import { converterData } from "../../Components/FunctionsRepository";

import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const Logs = () => {
  const { user_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const fetchLog = useCallback(async (params = {}) => {
    const response = await apiClient.post("/logs/buscar", {
      ...params,
    });

    if (response.sucesso) {
      setLogs(response.dados.data);
      setTotalItems(response.dados.total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchLog({
        termo: termoBusca,
        itens_pagina: itemsPerPage,
        pagina: currentPage,
        user_id,
      });
      setLoading(false);
      setLoadTable(false);
    };
    fetch();
  }, [user_id, loading, itemsPerPage, currentPage, termoBusca]);

  document.title = "Logs | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Logs de Uso" pageTitle="Ferramentas" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2 mt-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                    setLoading(true);
                  }}
                  value={itemsPerPage}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Col>
              <Col xl={10}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setCurrentPage}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Logs de Usuários</h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {!loading && !loadTable ? (
                      <table className="table table-hover table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Mensagem</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(logs || []).map((log, index) => {
                            return (
                              <tr key={index}>
                                <td>{converterData(log.created_at)}</td>
                                <td>
                                  {log.user?.name}
                                  <br />
                                  <small className="text-muted">
                                    ID: #{log.id}
                                  </small>
                                </td>
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: log.mensagem,
                                  }}
                                ></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <Row className="justify-content-center mt-3 mb-3">
                        <Spinner color="warning" />
                      </Row>
                    )}
                  </div>
                  {totalItems > itemsPerPage && (
                    <Paginacao
                      total={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      perPageData={itemsPerPage}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Logs;
