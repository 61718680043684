import React from "react";
import { Col, Row } from "reactstrap";
 import BotaoConectar from "../Authentication/BotaoConectar";

const Section = (props) => {
  const greetingMessage = () => {
    var today = new Date();
    var curHr = today.getHours();
    if (curHr < 12) {
      return "Bom dia";
    } else if (curHr < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  };

  const [userData, setUserData] = React.useState(null);

  React.useEffect(() => {
    const checkingLogin = async () => {
      const storedData = localStorage.getItem("authUser");
      setUserData(JSON.parse(storedData));
    };
    checkingLogin();
  }, []);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                {greetingMessage()}, {userData?.name}!
              </h4>
              <p className="text-muted mb-0">
                Hoje é um bom dia para fazer claim dos seus tokens.
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <BotaoConectar />
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
