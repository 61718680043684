import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalEditar = ({ modalEditar, toggle, setLoading, cliente }) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState("nacional");

  const toggleTipoTelefone = useCallback((tipoTelefone) => {
    setTelefone("");
    setTipo(tipoTelefone);
  }, []);

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const atualizar = async (cliente) => {
    setLoadingModal(true);

    const data = {
      nome,
      sobrenome,
      email,
      telefone,
      tipo_telefone: tipo,
    };

    const response = await apiClient.put(
      `/cliente/atualizar/${cliente.id}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      setNome("");
      setSobrenome("");
      setEmail("");
      setTelefone("");
      toggleTipoTelefone("nacional");

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  useEffect(() => {
    if (cliente) {
      setNome(cliente.nome || "");
      setSobrenome(cliente.sobrenome || "");
      setEmail(cliente.email || "");
      toggleTipoTelefone(cliente.tipo_telefone || "nacional");
      setTelefone(cliente.telefone || "");
    }
  }, [cliente]);
  return (
    <Modal
      isOpen={modalEditar}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Cliente
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={80}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="sobrenome" className="form-label">
                  Sobrenome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="sobrenome"
                  className="form-control"
                  type="text"
                  placeholder="Sobrenome"
                  value={sobrenome}
                  onChange={(e) => setSobrenome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e)}
                  maxLength={100}
                />
              </div>
            </Col>
            {tipo === "nacional" ? (
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone Nacional |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("usa")}
                    >
                      USA
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("internacional")}
                    >
                      Internacional
                    </a>
                  </Label>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="(11) 99999-9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            ) : tipo === "internacional" ? (
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone Internacional |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("nacional")}
                    >
                      Nacional
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("usa")}
                    >
                      USA
                    </a>
                  </Label>
                  <InputMask
                    mask="+999 999 999 9999"
                    placeholder="+999 999 999 9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            ) : (
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone USA |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("nacional")}
                    >
                      Nacional
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("internacional")}
                    >
                      Internacional
                    </a>
                  </Label>
                  <InputMask
                    mask="+1 (999) 999-9999"
                    placeholder="+1 (999) 999-9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            )}
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-info"
              onClick={() => atualizar(cliente)}
              disabled={loadingModal}
            >
              Editar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
