import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

const ModalProjetos = ({ modalProjetos, toggle, cliente, projetos }) => {
  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    const getAll = async () => {
      setLoadingModal(false);
    };
    if (cliente) getAll();
  }, [loadingModal, cliente]);

  return (
    <Modal
      isOpen={modalProjetos}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Ver Projetos
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div>
          <Row className="mt-4">
            <h4 className="fw-bold">Projetos Ativos</h4>
            <small className="text-muted">
              <b>Nome:</b>{" "}
              {cliente?.nome ? `${cliente.nome} ${cliente.sobrenome}` : ""}
            </small>
          </Row>
          <Row className="mt-3">
            <Col lg="12" className="mt-3">
              <h3>Projetos cadastrados</h3>
              {loadingModal ? (
                <Row className="justify-content-center text-center mt-3">
                  <Spinner />
                </Row>
              ) : (projetos || []).length === 0 ? (
                <small className="text-muted">Nenhum projeto cadastrado</small>
              ) : (
                <>
                  <Row className="d-flex justify-content-between mt-3 w-100">
                    <Col sm={4}><h5 className="text-warning">Nome</h5></Col>
                    <Col sm={4}><h5 className="text-warning">Token</h5></Col>
                    <Col sm={4}><h5 className="text-warning">Rede</h5></Col>
                  </Row>
                  {(projetos || []).map((projeto) => (
                    <Row
                      className="d-flex justify-content-between mt-3 w-100"
                      key={projeto.projeto.id}
                    >
                      <Col sm={4}>
                        <b>{projeto.projeto.nome}</b>
                      </Col>
                      <Col sm={4}>{projeto.projeto?.token?.sigla}</Col>
                      <Col sm={4}>{projeto.projeto?.token?.rede?.nome}</Col>
                    </Row>
                  ))}
                </>
              )}
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-warning fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalProjetos;
