import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  Collapse,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";

const Regras = () => {
  const [borderCol1, setborderCol1] = useState(true);
  const [borderCol2, setborderCol2] = useState(false);
  const [borderCol3, setborderCol3] = useState(false);
  const [borderCol4, setborderCol4] = useState(false);
  const [borderCol5, setborderCol5] = useState(false);
  const [borderCol6, setborderCol6] = useState(false);
  const [borderCol7, setborderCol7] = useState(false);
  const [borderCol8, setborderCol8] = useState(false);
  const [borderCol9, setborderCol9] = useState(false);
  const [borderCol10, setborderCol10] = useState(false);
  const [borderCol11, setborderCol11] = useState(false);
  const [borderCol12, setborderCol12] = useState(false);

  const t_borderCol1 = () => {
    setborderCol1(!borderCol1);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol2 = () => {
    setborderCol2(!borderCol2);
    setborderCol1(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol3 = () => {
    setborderCol3(!borderCol3);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol4 = () => {
    setborderCol4(!borderCol4);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol5 = () => {
    setborderCol5(!borderCol5);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol6 = () => {
    setborderCol6(!borderCol6);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol7 = () => {
    setborderCol7(!borderCol7);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol8 = () => {
    setborderCol8(!borderCol8);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol9 = () => {
    setborderCol9(!borderCol9);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol10 = () => {
    setborderCol10(!borderCol10);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol11 = () => {
    setborderCol11(!borderCol11);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol12(false);
  };

  const t_borderCol12 = () => {
    setborderCol12(!borderCol12);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          Faq - Perguntas Frequentes
        </h4>
      </CardHeader>
      <CardBody>
        <div className="live-preview">
          <Accordion
            className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success"
            id="accordionBordered"
            toggle={()=>{}}
            open=""
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample1">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol1,
                  })}
                  type="button"
                  onClick={t_borderCol1}
                  style={{ cursor: "pointer" }}
                >
                  Informacões
                </button>
              </h2>

              <Collapse
                isOpen={borderCol1}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse1"
              >
                <div className="accordion-body text">
                  <p>
                    Aqui adicionaremos o FAQ com informações gerais sobre o projeto ClaimMyTokens.
                  </p>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div>
      </CardBody>
    </Card>
  );
};

export default Regras;
