import React, { useState } from "react";
import {
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import FeatherIcon from "feather-icons-react";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";

const apiClient = new APIClient();

const ClienteOutItem = ({ projeto, cliente, setLoadTable }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const addClienteProjeto = async (cliente, projeto) => {
    setLoading(true);

    const data = {
      cliente_id: cliente?.cliente?.id,
      projeto_id: projeto?.id,
      amount:
        amount == ""
          ? 0
          : parseInt(
              parseFloat(amount.replace(",", "")) * 10 ** projeto?.token?.decimais
            ),
    };

    const response = await apiClient.post("/projetos/add-cliente", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setLoadTable(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0">
        <FeatherIcon className="avatar-xs rounded-circle" icon="user" />
      </div>
      <div className="flex-grow-1 ms-2">{`${cliente?.cliente?.nome} ${cliente?.cliente?.sobrenome}`}</div>
      <div className="ml-auto me-2">
        <NumericFormat
          className="form-control"
          placeholder="0.00"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          thousandSeparator=","
          decimalSeparator="."
          decimalScale={projeto?.token?.decimais}
          fixedDecimalScale={true}
          allowNegative={false}
        />
      </div>
      <div>
        {loading ? (
          <Spinner size={"sm"} />
        ) : (
          <Link
            title="Adicionar cliente ao projeto"
            onClick={() => addClienteProjeto(cliente, projeto)}
          >
            <FeatherIcon
              className="text-success"
              icon="plus-circle"
              width="20"
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default ClienteOutItem;
