import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Spinner,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import SearchModal from "./SearchModal";
import ClienteInItem from "./ClienteInItem";

const ClientesIn = ({ projeto, getClientes }) => {
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [termo, setTermo] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [total, setTotal] = useState(0);

  const prevPage = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
      setLoading(true);
    }
  };

  const nextPage = () => {
    if (paginaAtual < Math.ceil(total / 10)) {
      setPaginaAtual(paginaAtual + 1);
      setLoading(true);
    }
  };

  useEffect(() => {
    const executar = async () => {
      const resposta = await getClientes(
        "true",
        termo,
        paginaAtual,
        projeto.id
      );
      console.log (resposta);
      setClientes(resposta.data);
      setTotal(resposta.total);
      setLoading(false);
      setLoadTable(false);
    };
    if (projeto && loading) {
      executar();
    }
  }, [projeto, loading]);
  return (
    <Card>
      <CardHeader>
        <SearchModal
          setTermoBusca={setTermo}
          termoBusca={termo}
          setLoading={setLoading}
          setLoadTable={setLoadTable}
          setPaginaAtual={setPaginaAtual}
        />
      </CardHeader>
      <CardBody>
        <div className="live-preview">
          <ListGroup>
            {loadTable ? (
              <Row className="justify-content-center">
                <Spinner />
              </Row>
            ) : (
              clientes.map((cliente, index) => {
                return (
                  <ListGroupItem key={index}>
                    <ClienteInItem
                      cliente={cliente}
                      projeto={projeto}
                      setLoadTable={setLoading}
                    />
                  </ListGroupItem>
                );
              })
            )}
          </ListGroup>
        </div>
        <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li
                className={`page-item ${paginaAtual === 1 ? "disabled" : ""}`}
              >
                <Link to="#" className="page-link" onClick={prevPage}>
                  ←
                </Link>
              </li>
              <li
                className={`page-item ${
                  paginaAtual === Math.ceil(total / 10) ? "disabled" : ""
                }`}
              >
                <Link to="#" className="page-link" onClick={nextPage}>
                  →
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ClientesIn;
