import { useCallback } from 'react';
import { ethers } from 'ethers';
// Função para verificar o último horário de login
export const checkLastLogin = async () => {
  const lastLoginTimestamp = localStorage.getItem("lastLogin");
  if (lastLoginTimestamp) {
    const lastLoginTime = new Date(parseInt(lastLoginTimestamp));
    const currentTime = new Date();
    const timeDiffInHours = (currentTime - lastLoginTime) / (1000 * 60 * 60);

    if (timeDiffInHours >= 1) {
      localStorage.removeItem("lastLogin");
      localStorage.removeItem("authUser");
      window.location.reload();
    }
  }
};

export const formatAddress = (address) => {
  return address
    ? `${address.slice(0, 6)}...${address.slice(-4)}`
    : "";
};


export const converterData = (dataISO, minutoSegundo = true) => {
  if (dataISO) {
    const ano = dataISO.substring(0, 4);
    const mes = dataISO.substring(5, 7);
    const dia = dataISO.substring(8, 10);
    const hora = dataISO.substring(11, 13);
    const minuto = dataISO.substring(14, 16);

    return minutoSegundo
      ? `${dia}/${mes}/${ano} ${hora}:${minuto}`
      : `${dia}/${mes}/${ano}`;
  }
  return "";
};

export const formatEmail = (value) => {
  value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

  const atSymbolCount = value.split("@").length - 1;
  if (atSymbolCount > 1) {
    const index = value.lastIndexOf("@");
    value = value.slice(0, index) + value.slice(index + 1);
  }

  return value;
};