import React, { useEffect } from "react";
import { Row, Input } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const Paginacao = ({
  total,
  currentPage,
  setCurrentPage,
  perPageData,
  center,
}) => {
  const handlePageChange = (e) => {
    setCurrentPage(parseInt(e.target.value));
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / perPageData); i++) {
    pageNumbers.push(i);
  }

  const handleprevPage = () => {
    let prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };

  const handlenextPage = () => {
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    if (pageNumbers.length && pageNumbers.length < currentPage) {
      setCurrentPage(pageNumbers.length);
    }
  }, [pageNumbers.length, currentPage, setCurrentPage]);

  return (
    <React.Fragment>
      <Row
        className={`g-0 justify-content-${center ? "center" : "end"} mb-4 mt-3`}
      >
        <div className="col-sm-auto">
          <div className="d-flex align-items-center">
            {currentPage > 1 && (
              <Link
                to="#!"
                className="page-item pagination-prev"
                onClick={handleprevPage}
              >
                <FeatherIcon icon="chevron-left" className="icon-xs" />
              </Link>
            )}

            <Input
              type="select"
              className="form-select mx-2"
              value={currentPage}
              onChange={handlePageChange}
            >
              {pageNumbers.map((page) => (
                <option key={page} value={page}>
                  {page}
                </option>
              ))}
            </Input>

            {currentPage < pageNumbers.length && (
              <Link
                to="#!"
                className="page-item pagination-next "
                onClick={handlenextPage}
              >
                <FeatherIcon icon="chevron-right" className="icon-xs" />
              </Link>
            )}
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default Paginacao;
