import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";
import Informacoes from "../pages/Informacoes";
import Clientes from "../pages/Clientes";
import Importacao from "../pages/Clientes/Importacao";
import Usuarios from "../pages/Usuarios";
import Projetos from "../pages/Projetos";
import ClaimProjeto from "../pages/Projetos/ClaimProjeto";
import Envios from "../pages/Envios";
import Alocacao from "../pages/Alocacao";
import PaginaAlocacao from "../pages/Alocacao/PaginaAlocacao";
import VerAlocacao from "../pages/Alocacao/VerAlocacao";
import NovaAlocacao from "../pages/Alocacao/NovaAlocacao";
import AlocacaoPublica from "../pages/AlocacaoPublica";
import EditarAlocacao from "../pages/Alocacao/EditarAlocacao";
import Logs from "../pages/Logs";

import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Register from "../pages/Authentication/Register";
import JobLanding from "../pages/Job_Landing/Job";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import GerenciaCarteiras from "../pages/Authentication/GerenciaCarteiras";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/logs", component: <Logs /> },
  { path: "/logs/:user_id", component: <Logs /> },
  { path: "/informacoes", component: <Informacoes /> },
  { path: "/clientes", component: <Clientes /> },
  { path: "/importacao", component: <Importacao /> },
  { path: "/usuarios", component: <Usuarios /> },
  { path: "/projetos", component: <Projetos /> },
  { path: "/claim-projeto/:projeto_id", component: <ClaimProjeto /> },
  { path: "/envios", component: <Envios /> },
  { path: "/alocacao", component: <Alocacao /> },
  { path: "/nova-alocacao", component: <NovaAlocacao /> },
  {
    path: "/editar-alocacao/:projeto_id",
    component: <EditarAlocacao />,
  },
  {
    path: "/alocacao/:projeto_id",
    component: <VerAlocacao />,
  },
  {
    path: "/alocacao-projeto/:projeto_id",
    component: <PaginaAlocacao />,
  },
  { path: "/index", component: <Dashboard /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/gerencia-carteiras", component: <GerenciaCarteiras /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  {
    path: "/alocacao-publica/:projeto_id/:codigo",
    component: <AlocacaoPublica />,
  },
  {
    path: "/alocacao-publica/:projeto_id/:codigo/:convite",
    component: <AlocacaoPublica />,
  },

  //AuthenticationInner pages
  { path: "/auth-404", component: <Basic404 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

  { path: "/job-landing", component: <JobLanding /> },

];

export { authProtectedRoutes, publicRoutes };