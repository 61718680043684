import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ModalVerConvites = ({ modal, setLoading, toggle, alocacao }) => {
  const [loadingVisual, setLoadingVisual] = useState(false);
  const [loadTable, setLoadTable] = useState(true);
  const [adicionadosPaginaAtual, setAdicionadosPaginaAtual] = useState(1);
  const [convites, setConvites] = useState([]);

  const adicionadoPrevPage = () => {
    if (adicionadosPaginaAtual > 1) {
      setAdicionadosPaginaAtual(adicionadosPaginaAtual - 1);
      setLoadTable(true);
    }
  };

  const adicionadoNextPage = () => {
    if (adicionadosPaginaAtual < Math.ceil(convites.length / 10)) {
      setAdicionadosPaginaAtual(adicionadosPaginaAtual + 1);
      setLoadTable(true);
    }
  };

  const toggleModal = () => {
    setConvites([]);
    setLoading(true);
    setLoadTable(true);
    setAdicionadosPaginaAtual(1);

    toggle();
  };

  const cancelarConvite = async (convite_id) => {
    setLoadingVisual(true);
    const response = await apiClient.delete(`alocacoes/convite/${convite_id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setLoadTable(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      checkLastLogin();
    }
  };

  const getConvites = useCallback(async (alocacao) => {
    const response = await apiClient.get(`alocacoes/${alocacao.id}/convites`);
    if (response.sucesso) {
      setConvites(response.dados);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const execute = async () => {
      await getConvites(alocacao);
      setLoadTable(false);
      setLoadingVisual(false);
    };

    if (modal && alocacao && loadTable) {
      execute();
    }
  }, [modal, alocacao, loadTable]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggleModal();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggleModal();
        }}
      >
        Convites Não Usados
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Card>
          <CardBody>
            <div className="live-preview mt-2">
              {loadTable || loadingVisual ? (
                <Row className="justify-content-center">
                  <Spinner color="warning" />
                </Row>
              ) : (
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Nome</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Grupo</th>
                      <th className="border-0">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {convites?.map((convite, index) => {
                      return (
                        <tr key={index}>
                          <td>{`${convite?.cliente?.nome} ${convite?.cliente?.sobrenome}`}</td>
                          <td>{convite?.cliente?.email}</td>
                          <td>{convite?.grupo}</td>
                          <td>
                            <Link
                              title="Cancelar Convite"
                              onClick={() => cancelarConvite(convite?.id)}
                            >
                              <FeatherIcon
                                className="text-danger"
                                icon="x-circle"
                                width="20"
                              />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
              <div className="col-sm-auto mt-3 mt-sm-0">
                <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                  <li
                    className={`page-item ${
                      adicionadosPaginaAtual === 1 ? "disabled" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={adicionadoPrevPage}
                    >
                      ←
                    </Link>
                  </li>
                  <li
                    className={`page-item ${
                      adicionadosPaginaAtual === Math.ceil(convites.length / 10)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={adicionadoNextPage}
                    >
                      →
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-muted fw-medium"
            onClick={() => toggleModal()}
          >
            <i className="ri-close-line me-1 align-middle"></i> Fechar
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalVerConvites;
