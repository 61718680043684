import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Label,
  Input,
  Button,
  Progress,
} from "reactstrap";

import {
  converterData,
  checkLastLogin,
} from "../../Components/FunctionsRepository";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { NumericFormat } from "react-number-format";
import { ethers } from "ethers";
import {
  useSwitchActiveWalletChain,
  useActiveWallet,
  useActiveWalletChain,
} from "thirdweb/react";
import { defineChain } from "thirdweb/chains";
import BotaoConectar from "../Authentication/BotaoConectar";
import { erc20 } from "../../assets/abi/Erc20";
import { paymentContract } from "../../assets/abi/PaymentContract";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const PaginaAlocacao = () => {
  const { projeto_id } = useParams();

  const carteira = useActiveWallet();
  const chain = useActiveWalletChain();
  const switchChain = useSwitchActiveWalletChain();

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [userDados, setUserDados] = useState(null);
  const [userProjetoDados, setUserProjetoDados] = useState(null);
  const [contratoPagamento, setContratoPagamento] = useState(null);
  const [projeto, setProjeto] = useState(null);
  const [corStatus, setCorStatus] = useState("dark");
  const [amount, setAmount] = useState(0);
  const [depositos, setDepositos] = useState([]);
  const [totalDepositado, setTotalDepositado] = useState(0);
  const [porcentagemTotal, setPorcentagemTotal] = useState(0);

  const activateChain = async (rede) => {
    switchChain(
      defineChain({
        id: rede.network_id,
        nativeCurrency: {
          name: rede.nome,
          symbol: rede.sigla,
          decimals: 18,
        },
      })
    );
  };

  const alocar = async (userProjeto, projeto, contratoAddress, amount) => {
    setLoading(true);
    if (chain?.id !== projeto.moeda.rede.network_id) {
      if (!(await activateChain(projeto.moeda.rede))) {
        toast.error("Erro ao ativar rede da moeda");
        return;
      }
    }

    const carteiraOn = carteira?.getAccount()?.address;
    if (!carteiraOn) {
      toast.error("Carteira não conectada");
      setLoading(false);
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenAddress = projeto.moeda.endereco;

    //approve amount for token (multiplied by projeto.moeda.decimais)
    let receipt;
    let txHash;
    let estimateGas;
    try {
      const contract = new ethers.Contract(tokenAddress, erc20, signer);
      console.log("Contrato: ", projeto.moeda.decimais);

      try {
        estimateGas = await contract.estimateGas.approve(
          contratoAddress,
          ethers.BigNumber.from(
            parseFloat(amount) * (10 ^ projeto.moeda.decimais)
          )
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoading(false);
        return;
      }

      const transactionResponse = await contract.approve(
        contratoAddress,
        ethers.BigNumber.from(
          parseFloat(amount) * 10 ** projeto.moeda.decimais
        ),
        {
          gasLimit: estimateGas,
        }
      );
      // get the transaction hash before waiting
      txHash = await transactionResponse.hash;
      receipt = await transactionResponse.wait();
      console.log("Hash Aprovação: ", txHash);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao aprovar tokens");
      setLoading(false);
      return;
    }

    if (!contratoAddress) {
      toast.error("Erro ao buscar contrato");
      setLoading(false);
      return;
    }

    try {
      const contract = new ethers.Contract(
        contratoAddress,
        paymentContract,
        signer
      );

      try {
        estimateGas = await contract.estimateGas.send(
          ethers.BigNumber.from(
            parseFloat(amount) * 10 ** projeto.moeda.decimais
          ),
          parseInt(
            userProjeto.grupo === 1
              ? projeto.porcentagem_1
              : userProjeto.grupo === 2
              ? projeto.porcentagem_2
              : projeto.porcentagem_3
          ),
          tokenAddress,
          projeto.carteira_recebimento
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoading(false);
        return;
      }

      const dataDeposito = {
        alocacao_id: projeto.id,
        amount: parseFloat(amount),
      };
      let deposito;
      const responseDeposito = await apiClient.post(
        `/alocacoes/deposito`,
        dataDeposito
      );
      if (!responseDeposito.sucesso) {
        console.error(responseDeposito);
        toast.error(responseDeposito.mensagem);
        setLoading(false);
        return;
      } else {
        deposito = responseDeposito.dados;
      }

      const transactionResponse = await contract.send(
        ethers.BigNumber.from(
          parseFloat(amount) * 10 ** projeto.moeda.decimais
        ),
        parseInt(
          userProjeto.grupo === 1
            ? projeto.porcentagem_1
            : userProjeto.grupo === 2
            ? projeto.porcentagem_2
            : projeto.porcentagem_3
        ),
        tokenAddress,
        projeto.carteira_recebimento,
        {
          gasLimit: estimateGas,
        }
      );
      // get the transaction hash before waiting
      txHash = await transactionResponse.hash;
      const data = {
        hash: txHash,
      };
      receipt = await transactionResponse.wait();

      const response = await apiClient.put(
        `/alocacoes/deposito/${deposito.id}`,
        data
      );
      if (!response.sucesso) {
        console.error(response);
        toast.error(response.mensagem);
        setLoading(false);
        return;
      } else {
        toast.success("Transação enviada com sucesso! Redirecionando...");
        setTimeout(() => {
          window.location.href = `/alocacao`;
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar transação");
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const fetchAllDepositos = async (userDados) => {
    const response = await apiClient.get(
      `/alocacoes/${userDados.id}/depositos/${projeto_id}`
    );
    if (response.sucesso) {
      setDepositos(response.dados);
      let total = 0;
      response.dados.forEach((deposito) => {
        if (deposito?.hash && deposito?.hash !== "") total += deposito.amount;
      });
      setTotalDepositado(total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  const fetchUserProjetoDados = async (userDados) => {
    const response = await apiClient.get(
      `/alocacoes/${projeto_id}/cliente/${userDados.id}`
    );
    if (response.sucesso) {
      setUserProjetoDados(response.dados);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  const fetchContratoAddress = async (rede_id) => {
    const response = await apiClient.get(`/contratos/rede/${rede_id}`);
    let contrato;
    if (response.sucesso) {
      contrato = response.dados.find(
        (contrato) => contrato.nome === "PaymentContract"
      );
    }

    setContratoPagamento(contrato?.contrato);
  };

  const fetchProjeto = useCallback(async (id, userDados) => {
    setLoadingPage(true);
    const response = await apiClient.get(`/alocacoes/${id}`);
    if (response.sucesso) {
      setProjeto(response.dados);
      setCorStatus(
        response.dados.status === "ativo"
          ? "success"
          : response.dados.status === "inativo"
          ? "danger"
          : response.dados.status === "iniciado"
          ? "info"
          : response.dados.status === "finalizado"
          ? "dark"
          : "secondary"
      );
      await fetchUserProjetoDados(userDados);
      await fetchContratoAddress(response.dados?.moeda?.rede?.id);
      const porcTotal = Math.round(
        (parseFloat(response.dados?.alocacao_atual || 0) /
          parseFloat(response.dados?.alocacao_total || 1)) *
          100
      );
      setPorcentagemTotal(porcTotal < 1 ? 1 : porcTotal);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    const fetchData = async (projeto_id) => {
      const dados = await getLoggedinUser();
      setUserDados(dados);
      await fetchAllDepositos(dados);

      if (!projeto?.titulo) {
        await fetchProjeto(projeto_id, dados);
      }

      setLoading(false);
    };
    fetchData(projeto_id);
  }, [carteira, projeto_id]);

  document.title = `${projeto?.titulo} | Claim My Tokens`;
  return (
    <React.Fragment>
      <div className="page-content">
        {loadingPage ? (
          <Container>
            <Row className="justify-content-center">
              <Spinner color="primary" />
            </Row>
          </Container>
        ) : (
          <Container fluid>
            <BreadCrumb title="Alocação" pageTitle="Projetos" />
            <Row>
              <Col lg="8">
                <Row className="mt-3">
                  <div className="col-sm">
                    <div>
                      <h1 className="text-warning">{projeto?.titulo}</h1>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex justify-content-between">
                      {projeto?.twitter_url && projeto?.twitter_url !== "" && (
                        <a
                          href={projeto?.twitter_url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon icon="twitter" className="icon-md" />
                        </a>
                      )}
                      {projeto?.discord_url && projeto?.discord_url !== "" && (
                        <a
                          href={projeto?.discord_url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon
                            icon="message-circle"
                            className="icon-md"
                          />
                        </a>
                      )}
                      {projeto?.instagram_url &&
                        projeto?.instagram_url !== "" && (
                          <a
                            href={projeto?.instagram_url}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary"
                          >
                            <FeatherIcon icon="instagram" className="icon-md" />
                          </a>
                        )}
                    </div>
                  </div>
                </Row>
                <Row className="mt-3">
                  <h5 className="text-muted">{projeto?.subtitulo}</h5>
                </Row>
                <Row className="mt-3 border-top pt-3">
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        {projeto?.imagens.length > 0 && (
                          <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            pagination={{ clickable: true }}
                            navigation={true}
                            loop={true}
                            autoplay={{
                              delay: 2500,
                              disableOnInteraction: true,
                            }}
                            className="mySwiper swiper navigation-swiper rounded"
                          >
                            <div className="swiper-wrapper">
                              {projeto?.imagens.map((imagem, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <img
                                      src={imagem.url}
                                      alt={`img-${index}`}
                                      className="img-fluid"
                                    />
                                  </SwiperSlide>
                                );
                              })}
                            </div>
                          </Swiper>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3 border-top pt-3">
                  <Col lg="12">
                    <Card>
                      <CardHeader>
                        <h4>Descrição do projeto</h4>
                      </CardHeader>
                      <CardBody>
                        <div
                          dangerouslySetInnerHTML={{ __html: projeto?.texto }}
                        ></div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              {projeto?.status !== "ativo" ? (
                <Col lg="4">
                  <Card>
                    <CardBody>
                      <h5>
                        Status:
                        <span className={`badge bg-${corStatus} ms-2`}>
                          {projeto?.status.at(0).toUpperCase() +
                            projeto?.status.slice(1)}
                        </span>
                      </h5>
                      <p>O projeto não está habilitado para alocações.</p>
                    </CardBody>
                  </Card>
                </Col>
              ) : userProjetoDados ? (
                <Col lg="4">
                  <Card>
                    <CardBody>
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <div>
                            <h5 className="card-title mb-0">
                              Status:
                              <span className={`badge bg-${corStatus} ms-2`}>
                                {projeto?.status.at(0).toUpperCase() +
                                  projeto?.status.slice(1)}
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div>
                            <BotaoConectar />
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5>Alocar</h5>
                      <small className="text-muted">
                        Moeda para alocação: {projeto?.moeda?.nome} (
                        {projeto?.moeda?.sigla})
                      </small>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="8">
                          <NumericFormat
                            id="amount"
                            placeholder={
                              `0.00 ${projeto?.moeda?.sigla}` || "0.00"
                            }
                            className="form-control"
                            value={amount || ""}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                            thousandSeparator=","
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            suffix={` ${projeto?.moeda?.sigla}` || ""}
                          />
                          <small className="text-muted">
                            Mínimo:{" "}
                            {parseFloat(
                              userProjetoDados?.grupo === 1
                                ? projeto?.investimento_minimo_1
                                : userProjetoDados?.grupo === 2
                                ? projeto?.investimento_minimo_2
                                : projeto?.investimento_minimo_3
                            ).toFixed(2)}{" "}
                            {projeto?.moeda?.sigla} <br /> Máximo:{" "}
                            {parseFloat(
                              userProjetoDados?.grupo === 1
                                ? projeto?.investimento_maximo_1
                                : userProjetoDados?.grupo === 2
                                ? projeto?.investimento_maximo_2
                                : projeto?.investimento_maximo_3
                            ).toFixed(2)}{" "}
                            {projeto?.moeda?.sigla}
                          </small>
                        </Col>
                        <Col lg="4">
                          <Button
                            color="primary"
                            onClick={() =>
                              alocar(
                                userProjetoDados,
                                projeto,
                                contratoPagamento,
                                amount.replace(` ${projeto?.moeda?.sigla}`, "")
                              )
                            }
                            disabled={
                              parseFloat(amount) <= 0 ||
                              !contratoPagamento ||
                              !carteira ||
                              loading
                            }
                          >
                            {loading ? (
                              <Spinner size="sm" />
                            ) : (
                              <FeatherIcon size="14" icon="send" />
                            )}{" "}
                            Alocar
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5>Alocação Total</h5>
                      <small className="text-muted">
                        Sua alocação total no projeto:{" "}
                        {parseFloat(totalDepositado).toFixed(2)}{" "}
                        {projeto?.moeda?.sigla}
                      </small>
                    </CardHeader>
                    <CardBody>
                      <p>
                        {parseFloat(projeto?.alocacao_atual || 0).toFixed(2)}{" "}
                        {projeto?.moeda?.sigla} alocados no projeto.
                      </p>

                      <div className="live-preview">
                        <div>
                          <Progress
                            value={porcentagemTotal}
                            striped
                            color="success"
                            animated
                          />
                        </div>
                      </div>
                      <small className="text-secondary">
                        Porcentagem total da alocação: {porcentagemTotal}%
                      </small>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5>Dados de Distribuição</h5>
                    </CardHeader>
                    <CardBody>
                      <p>
                        <strong className="text-warning">Periodicidade:</strong>{" "}
                        {projeto?.periodo_distribuicao.charAt(0).toUpperCase() +
                          projeto?.periodo_distribuicao.slice(1)}
                      </p>
                      <p>
                        <strong className="text-warning">Períodos:</strong>{" "}
                        {projeto?.envios.length}
                      </p>
                      <p>
                        <strong className="text-warning">
                          Dia de distribuição:
                        </strong>{" "}
                        {projeto?.distribuicao_dia}
                      </p>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5>Envios</h5>
                    </CardHeader>
                    <CardBody>
                      <table
                        className="table table-bordered table-nowrap table-responsive"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>Enviado ?</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projeto?.envios.map((envio, index) => {
                            return (
                              <tr key={index}>
                                <td>{converterData(envio.data_envio)}</td>
                                <td>
                                  {envio.enviado ? "Enviado" : "Não enviado"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <Col lg="4">
                  <Card>
                    <CardBody>
                      <h5>
                        Você não tem permissão para fazer alocações neste
                        projeto.
                      </h5>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default PaginaAlocacao;
