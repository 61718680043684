import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

import ClientesIn from "./ClientesIn";
import ClientesOut from "./ClientesOut";

const apiClient = new APIClient();

const ModalEditar = ({ modalEditar, toggle, setLoading, projeto }) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [nome, setNome] = useState("");
  const [status, setStatus] = useState("");
  const [funcao, setFuncao] = useState("adicionar");
  const [contratos, setContratos] = useState([]);
  const [contrato, setContrato] = useState({});

  const toggleModal = () => {
    setNome("");
    setStatus("");
    setContrato("");
    setContratos([]);
    setLoading(true);
    toggle();
  };

  const atualizar = async (projeto) => {
    setLoadingModal(true);

    const data = {
      nome,
      status,
      contrato_id: contrato,
    };

    const response = await apiClient.put(`/projetos/${projeto.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  const getClientes = useCallback(
    async (incluso, termo, pagina, projetoId) => {
      const data = {
        incluso,
        projeto_id: projetoId,
        itens_pagina: 10,
        pagina,
        termo,
      };

      const response = await apiClient.post("/projetos/buscar-clientes", data);
      if (response.sucesso) {
        return response.dados;
      } else {
        console.error(response.mensagem);
        toast.error(response.mensagem);
        return [];
      }
    },
    [projeto]
  );

  const fetchContratos = useCallback(async (rede, contrato_id) => {
    if (rede === 0) {
      return;
    }
    const response = await apiClient.get(`/contratos/rede/${rede}`);
    if (response.sucesso) {
      setContratos(response.dados);
      setContrato(contrato_id);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetch = async (projeto) => {
      await fetchContratos(projeto?.token?.rede_id, projeto.contrato_id);
    };
    if (projeto) {
      setNome(projeto.nome || "");
      setStatus(projeto.status || "");
      fetch(projeto);
    }
  }, [projeto]);
  return (
    <Modal
      isOpen={modalEditar}
      toggle={() => {
        toggleModal();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggleModal();
        }}
      >
        Editar Projeto
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={80}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="status-projeto" className="form-label">
                  Status <span className="text-danger">*</span>
                </Label>
                <Input
                  id="status-projeto"
                  className="form-control"
                  type="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                  <option value="finalizado">Finalizado</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="contratos" className="form-label">
                  Contrato de Envio <span className="text-danger">*</span>
                </Label>
                <Input
                  id="contratos"
                  className="form-control"
                  type="select"
                  value={contrato}
                  onChange={(e) => setContrato(e.target.value)}
                >
                  {contratos.map((contrato) => {
                      if(contrato.ativo) return(
                      <option key={contrato.id} value={contrato.id}>
                        {contrato.nome}
                      </option>
                    )})}
                </Input>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-info"
              onClick={() => atualizar(projeto)}
              disabled={loadingModal}
            >
              Editar
            </Link>
          </div>
          <Row className="mt-3">
            <Nav
              pills
              className="nav nav-pills arrow-navtabs nav-justified nav-info bg-light mb-3 mt-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: funcao === "adicionar",
                  })}
                  onClick={() => {
                    setFuncao("adicionar");
                  }}
                >
                  Adicionar Cliente
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: funcao === "remover",
                  })}
                  onClick={() => {
                    setFuncao("remover");
                  }}
                >
                  Editar Cliente
                </NavLink>
              </NavItem>
            </Nav>
          </Row>
          {funcao === "adicionar" ? (
            <ClientesOut projeto={projeto} getClientes={getClientes} />
          ) : (
            <ClientesIn projeto={projeto} getClientes={getClientes} />
          )}

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => toggleModal()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
