import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import BotaoConectar from "./BotaoConectarLogin";

import logoLight from "../../assets/images/logo-light.png";
import ethGuy from "../../assets/images/eth-guy.png";

import AOS from "aos";
import "aos/dist/aos.css";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [lembrar, setLembrar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tipo, setTipo] = useState("cliente");

  const esquecerLembrarSenha = useCallback((lembrarSenha, user, pass) => {
    if (!lembrarSenha) {
      localStorage.removeItem("dadosLogin");
    } else {
      const passBase64 = btoa(pass);
      localStorage.setItem(
        "dadosLogin",
        JSON.stringify({ email: user, password: passBase64 })
      );
    }

    setLembrar(lembrarSenha);
    console.log(lembrarSenha);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/login`,
          {
            email,
            password,
          }
        );
        localStorage.setItem(
          "authUser",
          JSON.stringify(response.dados || response.data?.dados)
        );
        localStorage.setItem("lastLogin", Date.now());
        setTimeout(() => {
          window.location.href = "/principal";
        }, 200);
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.mensagem || "Ocorreu um erro ao fazer login."
        );
      } finally {
        setLoading(false);
      }
    },
    [email, password]
  );

  useEffect(() => {
    const dadosLogin = JSON.parse(localStorage.getItem("dadosLogin"));
    if (
      dadosLogin &&
      !lembrar &&
      dadosLogin.email !== "" &&
      dadosLogin.passBase64 !== ""
    ) {
      setEmail(dadosLogin.email);
      setPassword(atob(dadosLogin.password));
      setLembrar(true);
    }

    AOS.init({
      easing: "ease-out-back",
      duration: 3000,
      anchorPlacement: "top-bottom",
    });
  }, []);

  document.title = "Logar | Claim My Tokens";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-between mt-5">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-5 card-bg-fill" data-aos="fade-right">
                  <CardBody className="p-4">
                    <Row>
                      <Col lg={12}>
                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                          <div>
                            <Link to="/" className="d-inline-block auth-logo">
                              <img src={logoLight} alt="" height="60" />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <div className="dflex justify-content-center mt-4 mb-4">
                        <p>É Cliente?</p>
                        <BotaoConectar />
                      </div>
                      <div className="signin-other-title">
                        <h5 className="fs-13 mb-4 title text-muted">Ou</h5>
                        {tipo === "admin" ? <div className="float-center">
                          <Link
                            to="#"
                            className="text-muted"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {setTipo("cliente")}}
                          >
                            Ocultar
                          </Link>
                        </div>:<div className="float-center">
                          <Link
                            to="#"
                            className="text-muted"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {setTipo("admin")}}
                          >
                            É usuário / Administrador?
                          </Link>
                        </div>}
                      </div>
                    </Row>
                    {tipo === "admin" &&
                      <div className="p-2 mt-2">
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          E-mail
                        </Label>
                        <Input
                          className="form-control"
                          id="email"
                          placeholder="Digite um email válido"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password">
                          Senha
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Digite a senha"
                            id="password"
                          />

                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            checked={lembrar}
                            onChange={(e) =>
                              esquecerLembrarSenha(
                                e.target.checked,
                                email,
                                password
                              )
                            }
                            disabled={email === "" || password === ""}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Lembre-se de mim
                          </Label>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button
                          color="secondary"
                          disabled={loading}
                          className="btn btn-primary w-100"
                          onClick={(e) => handleSubmit(e)}
                        >
                          {loading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : null}
                          Entrar
                        </Button>
                      </div>
                    </div>}
                  </CardBody>
                </Card>
              </Col>
              <Col md={4} lg={6} xl={5}>
                <img src={ethGuy} alt="" height="600" data-aos="fade-up" />
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Login;
