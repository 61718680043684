import React, { useState, useCallback, useEffect } from "react";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import FeatherIcon from "feather-icons-react";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";

const apiClient = new APIClient();

const ClienteInItem = ({ projeto, cliente, setLoadTable }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const editAmount = async (cliente, projeto) => {
    setLoading(true);

    const data = {
      cliente_id: cliente?.cliente?.id,
      projeto_id: projeto?.id,
      amount:
        amount == ""
          ? 0
          : parseInt(
              parseFloat(amount.replace(",", "")) *
                10 ** projeto?.token?.decimais
            ),
    };

    const response = await apiClient.put("/projetos/edit-amount-cliente", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setLoadTable(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const removeClienteProjeto = async (clienteId, projetoId) => {
    setLoading(true);
    const response = await apiClient.delete(
      `/projetos/remove-cliente-projeto/${clienteId}/${projetoId}`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      setLoadTable(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }

  
  //Route::get('projetos/show-amount/{clienteId}/{projetoId}', [ProjetoController::class, 'showAmount']);
  const showAmount = useCallback(async (clienteId, projeto) => {
    const response = await apiClient.get(`/projetos/show-amount/${clienteId}/${projeto?.id}`);
    if (response.sucesso) {
      setAmount(response.dados.amount / (10 ** projeto?.token?.decimais));
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const executar = async () => {
      await showAmount(cliente?.cliente?.id, projeto);
    };
    if (cliente?.cliente?.id && projeto?.id) {
      executar();
    }
  }, [cliente, projeto]);

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0">
        <FeatherIcon className="avatar-xs rounded-circle" icon="user" />
      </div>
      <div className="flex-grow-1 ms-2">{`${cliente?.cliente?.nome} ${cliente?.cliente?.sobrenome}`}</div>
      <div className="ml-auto me-2">
        <NumericFormat
          className="form-control"
          placeholder="0.00"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          thousandSeparator=","
          decimalSeparator="."
          decimalScale={projeto?.token?.decimais}
          fixedDecimalScale={true}
          allowNegative={false}
        />
      </div>
      <div>
        {loading ? (
          <Spinner size={"sm"} />
        ) : (
          <><Link
          title="Editar valor do cliente"
          onClick={() => editAmount(cliente, projeto)}
        >
          <FeatherIcon
            className="text-success me-2"
            icon="check-circle"
            width="20"
          />
        </Link><Link
            title="Remover cliente do projeto"
            onClick={() => removeClienteProjeto(cliente?.cliente?.id, projeto?.id)}
          >
            <FeatherIcon
              className="text-danger"
              icon="minus-circle"
              width="20"
            />
          </Link></>
        )}
      </div>
    </div>
  );
};

export default ClienteInItem;
