import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Label,
  Input,
  Button,
} from "reactstrap";

import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
import { NumericFormat } from "react-number-format";
import { ethers } from "ethers";
import { useSwitchActiveWalletChain, useActiveWallet } from "thirdweb/react";
import { defineChain } from "thirdweb/chains";
import BotaoConectar from "../Authentication/BotaoConectar";
import { erc20 } from "../../assets/abi/Erc20";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const EditarAlocacao = () => {
  const { projeto_id } = useParams();

  const carteira = useActiveWallet();
  const switchChain = useSwitchActiveWalletChain();

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [userDados, setUserDados] = useState(null);
  const { quillRef } = useQuill();

  const [titulo, setTitulo] = useState("");
  const [subtitulo, setSubtitulo] = useState("");
  const [site, setSite] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [discordUrl, setDiscordUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [alocacaoTotal, setAlocacaoTotal] = useState("");
  const [carteiraRecebimento, setCarteiraRecebimento] = useState("");
  const [periodoDistribuicao, setPeriodoDistribuicao] = useState("mensal");
  const [periodos, setPeriodos] = useState("12");
  const [distribuicaoDia, setDistribuicaoDia] = useState("5");
  const [alocacao1, setAlocacao1] = useState("");
  const [porcentagem1, setPorcentagem1] = useState("");
  const [investimentoMinimo1, setInvestimentoMinimo1] = useState("");
  const [investimentoMaximo1, setInvestimentoMaximo1] = useState("");
  const [convite, setConvite] = useState(true);

  const [alocacao2, setAlocacao2] = useState("");
  const [porcentagem2, setPorcentagem2] = useState("");
  const [investimentoMinimo2, setInvestimentoMinimo2] = useState("");
  const [investimentoMaximo2, setInvestimentoMaximo2] = useState("");

  const [alocacao3, setAlocacao3] = useState("");
  const [porcentagem3, setPorcentagem3] = useState("");
  const [investimentoMinimo3, setInvestimentoMinimo3] = useState("");
  const [investimentoMaximo3, setInvestimentoMaximo3] = useState("");

  const [redes, setRedes] = useState([]);
  const [moedas, setMoedas] = useState([
    {
      label: `Outras`,
      value: "0",
    },
  ]);
  const [redeSelecionada, setRedeSelecionada] = useState(null);
  const [moedaSelecionada, setMoedaSelecionada] = useState(null);

  const [nomeMoeda, setNomeMoeda] = useState("");
  const [siglaMoeda, setSiglaMoeda] = useState("");
  const [decimaisMoeda, setDecimaisMoeda] = useState("");
  const [enderecoMoeda, setEnderecoMoeda] = useState("");

  const [imagens, setImagens] = useState([]);
  const [tipoImagem, setTipoImagem] = useState("local");
  const [imagemUrl, setImagemUrl] = useState("");
  const [imagemLocal, setImagemLocal] = useState(null);
  const [nomeImagem, setNomeImagem] = useState("");

  const activateChain = async (rede) => {
    setRedeSelecionada(rede);
    switchChain(
      defineChain({
        id: rede.network_id,
        nativeCurrency: {
          name: rede.nome,
          symbol: rede.sigla,
          decimals: 18,
        },
      })
    );
  };

  const calcularTotal = (total1, total2, total3) => {
    const totalFinal =
      parseFloat(total1 === "" ? "0" : total1.replace(",", "")) +
      parseFloat(total2 === "" ? "0" : total2.replace(",", "")) +
      parseFloat(total3 === "" ? "0" : total3.replace(",", ""));
    setAlocacaoTotal(totalFinal.toFixed(2));
  };

  const fetchProjeto = useCallback(async (id) => {
    setLoadingPage(true);
    const response = await apiClient.get(`/alocacoes/${id}`);
    if (response.sucesso) {
      const projeto = response.dados;
      quillRef.current.firstChild.innerHTML = projeto.texto;
      setTitulo(projeto.titulo);
      setSubtitulo(projeto.subtitulo);
      setSite(projeto.site);
      setTwitterUrl(projeto.twitter_url);
      setDiscordUrl(projeto.discord_url);
      setInstagramUrl(projeto.instagram_url);
      setAlocacaoTotal(projeto.alocacao_total);
      setCarteiraRecebimento(projeto.carteira_recebimento);
      setPeriodoDistribuicao(projeto.periodo_distribuicao);
      setPeriodos(projeto?.envios?.length);
      setDistribuicaoDia(projeto.distribuicao_dia);
      setAlocacao1(projeto.alocacao_1);
      setPorcentagem1(projeto.porcentagem_1);
      setInvestimentoMinimo1(projeto.investimento_minimo_1);
      setInvestimentoMaximo1(projeto.investimento_maximo_1);
      setAlocacao2(projeto.alocacao_2);
      setPorcentagem2(projeto.porcentagem_2);
      setInvestimentoMinimo2(projeto.investimento_minimo_2);
      setInvestimentoMaximo2(projeto.investimento_maximo_2);
      setAlocacao3(projeto.alocacao_3);
      setPorcentagem3(projeto.porcentagem_3);
      setInvestimentoMinimo3(projeto.investimento_minimo_3);
      setInvestimentoMaximo3(projeto.investimento_maximo_3);
      setConvite(projeto.convite);
      setMoedaSelecionada({
        label: `${projeto?.moeda?.nome} (${projeto?.moeda?.sigla})`,
        sigla: `${projeto?.moeda?.sigla}`,
        value: `${projeto?.moeda?.id}`,
      });
      setRedeSelecionada({
        label: `${projeto?.moeda?.rede?.nome} (${projeto?.moeda?.rede?.token_rede})`,
        value: `${projeto?.moeda?.rede?.id}`,
        sigla: `${projeto?.moeda?.rede?.token_rede}`,
        nome: `${projeto?.moeda?.rede?.nome}`,
        network_id: `${projeto?.moeda?.rede?.network_id}`,
      });
      setImagens(projeto.imagens);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadingPage(false);
  }, []);

  const handleImageSubmit = async () => {
    let img = {
      principal: temDestaque(imagens) ? false : true,
    };

    if (tipoImagem === "local") {
      const data = new FormData();
      data.append("arquivo", imagemLocal);
      data.append("nome", nomeImagem);

      try {
        const response = await apiClient.postFormData("/imagens", data);
        if (response.sucesso) {
          img.url = response.dados.path;
        } else {
          console.error(response.mensagem);
          toast.error(response.mensagem);
        }

        if (
          !response.sucesso &&
          (!response.responseCode || response.responseCode === 401)
        ) {
          await checkLastLogin();
        }
      } catch (error) {
        console.error("Erro ao fazer upload da imagem:", error);
        toast.error("Erro ao fazer upload da imagem");
        return;
      }
    } else {
      img.url = imagemUrl;
    }
    if (img.url) {
      // Adicionar a nova imagem ao array de imagens
      setImagens([...imagens, img]);

      // Resetar os campos do formulário
      setTipoImagem("local");
      setImagemUrl("");
      setImagemLocal(null);
      setNomeImagem("");

      toast.success("Imagem adicionada com sucesso!");
    }
  };

  const removerImagem = (indexParaRemover) => {
    const novasImagens = [...imagens];
    novasImagens.splice(indexParaRemover, 1);
    setImagens(novasImagens);
  };

  // função para verificar quantas imagens de destaque existem
  const temDestaque = (imagens) => {
    return imagens.some((imagem) => imagem.principal);
  };

  //funcao para mudar o campo isDestaque de uma imagem da lista. Desmarcar todos os restantes que possam estar marcados
  const mudarDestaque = (indexParaMudar) => {
    const novasImagens = [...imagens];
    novasImagens.forEach((imagem, index) => {
      if (index === indexParaMudar) {
        imagem.principal = true;
      } else {
        imagem.principal = false;
      }
    });
    setImagens(novasImagens);
  };

  const fetchMoedas = useCallback(async (rede_id) => {
    const response = await apiClient.get(`/moedas/rede/${rede_id}`);
    if (response.sucesso) {
      let optionsMoedas = response.dados.map((moeda) => ({
        label: `${moeda?.nome} (${moeda?.sigla})`,
        sigla: `${moeda?.sigla}`,
        value: `${moeda?.id}`,
      }));
      optionsMoedas.push({
        label: `Outras`,
        value: "0",
      });
      setMoedas(optionsMoedas);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchRedes = useCallback(async () => {
    const response = await apiClient.get("/redes");
    if (response.sucesso) {
      const optionsRedes = response.dados.map((rede) => ({
        label: `${rede?.nome} (${rede?.token_rede})`,
        value: `${rede?.id}`,
        sigla: `${rede?.token_rede}`,
        nome: `${rede?.nome}`,
        network_id: `${rede?.network_id}`,
      }));
      setRedes(optionsRedes);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const cadastrarMoeda = async () => {
    const data = {
      nome: nomeMoeda,
      sigla: siglaMoeda,
      decimais: decimaisMoeda,
      endereco: enderecoMoeda,
      rede_id: redeSelecionada.value,
    };
    const response = await apiClient.post("/moedas", data);
    if (response.sucesso) {
      toast.success("Moeda cadastrada com sucesso!");
      await fetchMoedas(redeSelecionada.value);
      setMoedaSelecionada({
        label: `${nomeMoeda} (${siglaMoeda})`,
        sigla: siglaMoeda,
        value: response.dados.id,
      });
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }

    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const editar = async (id_projeto) => {
    setLoading(true);
    const data = {
      titulo,
      subtitulo,
      texto: quillRef.current.firstChild.innerHTML,
      site,
      twitter_url: twitterUrl,
      discord_url: discordUrl,
      instagram_url: instagramUrl,
      alocacao_1: parseFloat(alocacao1.replace(",", "")),
      porcentagem_1: parseFloat(porcentagem1.replace(",", "")),
      investimento_minimo_1: parseFloat(investimentoMinimo1.replace(",", "")),
      investimento_maximo_1: parseFloat(investimentoMaximo1.replace(",", "")),
      alocacao_2: parseFloat(
        alocacao2 === "" ? "0" : alocacao2.replace(",", "")
      ),
      porcentagem_2: parseFloat(
        porcentagem2 === "" ? "0" : porcentagem2.replace(",", "")
      ),
      investimento_minimo_2: parseFloat(
        investimentoMinimo2 === "" ? "0" : investimentoMinimo2.replace(",", "")
      ),
      investimento_maximo_2: parseFloat(
        investimentoMaximo2 === "" ? "0" : investimentoMaximo2.replace(",", "")
      ),
      alocacao_3: parseFloat(
        alocacao3 === "" ? "0" : alocacao3.replace(",", "")
      ),
      porcentagem_3: parseFloat(
        porcentagem3 === "" ? "0" : porcentagem3.replace(",", "")
      ),
      investimento_minimo_3: parseFloat(
        investimentoMinimo3 === "" ? "0" : investimentoMinimo3.replace(",", "")
      ),
      investimento_maximo_3: parseFloat(
        investimentoMaximo3 === "" ? "0" : investimentoMaximo3.replace(",", "")
      ),
      alocacao_total: parseFloat(alocacaoTotal.replace(",", "")),
      carteira_recebimento: carteiraRecebimento,
      moeda_id: moedaSelecionada?.value,
      periodo_distribuicao: periodoDistribuicao,
      distribuicao_dia: distribuicaoDia,
      periodos,
      moeda_id: moedaSelecionada?.value,
      imagens,
      convite,
    };

    const response = await apiClient.put(`/alocacoes/${id_projeto}`, data);
    if (response.sucesso) {
      toast.success("Alocação editada com sucesso! Redirecionando...");
      setTimeout(() => {
        window.location.href = "/alocacao";
      }, 2000);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const waitSeconds = async (seconds) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
  };

  const fetchMoedaDados = useCallback(async (contractAddress, rede) => {
    setEnderecoMoeda(contractAddress);
    if (contractAddress === "") {
      return;
    }
    if (rede === 0) {
      return;
    }
    setLoading(true);

    await waitSeconds(1);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, erc20, provider);
      const retorno = await contract.decimals();
      const symbol = await contract.symbol();
      setDecimaisMoeda(retorno.toString());
      setSiglaMoeda(symbol);
    } catch (error) {
      console.error(error);
      toast.error(
        "Houve um erro ao obter as casas decimais. O contrato e a rede estão corretos?"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async (redeSelecionada) => {
      const dados = await getLoggedinUser();
      setUserDados(dados);

      if (titulo === "") {
        await fetchProjeto(projeto_id);
      }

      if (redes.length === 0) {
        await fetchRedes();
      }

      if (redeSelecionada?.value && redeSelecionada?.value !== "0") {
        await fetchMoedas(redeSelecionada.value);
      }

      setLoading(false);
    };
    fetchData(redeSelecionada);
  }, [redeSelecionada, carteira, projeto_id]);

  document.title = "Nova Alocação | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Novo Projeto de Alocação" pageTitle="Projetos" />
          <Row>
            <Col lg="8">
              <Row className="mt-3">
                <Col lg="12">
                  <Label for="titulo">
                    Título
                    <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    type="text"
                    name="titulo"
                    id="titulo"
                    placeholder="Digite aqui o título do projeto de alocação"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="12">
                  <Label for="subtitulo">
                    Subtítulo
                    <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    type="text"
                    name="subtitulo"
                    id="subtitulo"
                    placeholder="Digite aqui o subtítulo do projeto de alocação"
                    value={subtitulo}
                    onChange={(e) => setSubtitulo(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                {/* checkbox convite */}
                <Col lg="12">
                  <Input
                    type="checkbox"
                    name="convite"
                    id="convite"
                    checked={convite}
                    onChange={(e) => setConvite(e.target.checked)}
                  />
                  <Label for="convite" className="ms-2">
                    Alocação disponível apenas por convite
                  </Label>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="12">
                  <Label for="texto">
                    Descrição
                    <span className="text-danger"> *</span>
                  </Label>
                  <div className="snow-editor" style={{ height: 300 }}>
                    <div ref={quillRef} />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4 border-top pt-3">
                <Col lg="4">
                  <Label for="periodoDistribuicao">
                    Período de Distribuição
                    <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    type="select"
                    name="periodoDistribuicao"
                    id="periodoDistribuicao"
                    value={periodoDistribuicao}
                    onChange={(e) => setPeriodoDistribuicao(e.target.value)}
                  >
                    <option value="mensal">Mensal</option>
                    <option value="bimestral">Bimestral</option>
                    <option value="trimestral">Trimestral</option>
                    <option value="quadrimestral">Quadrimestral</option>
                    <option value="semestral">Semestral</option>
                    <option value="anual">Anual</option>
                  </Input>
                </Col>
                <Col lg="4">
                  <Label for="distribuicaoDia">
                    Dia do Envio
                    <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    type="select"
                    name="distribuicaoDia"
                    id="distribuicaoDia"
                    value={distribuicaoDia}
                    onChange={(e) => setDistribuicaoDia(e.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                  </Input>
                </Col>
                <Col lg="4">
                  <Label for="periodos">
                    Quantos Envios Previstos?
                    <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    type="text"
                    name="periodos"
                    id="periodos"
                    placeholder="Digite aqui o número de períodos de distribuição"
                    value={periodos}
                    onChange={(e) =>
                      setPeriodos(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </Col>
              </Row>
              {!carteira ? (
                <Row className="justify-content-center mt-4 border-top pt-3">
                  <BotaoConectar />
                </Row>
              ) : (
                <Row className="mt-4 border-top pt-3">
                  <Col lg="6">
                    <Label for="rede">
                      Rede
                      <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      value={redeSelecionada}
                      onChange={(sortBy) => {
                        activateChain(sortBy);
                        setMoedaSelecionada(null);
                      }}
                      options={redes}
                      id="redes"
                      placeholder={
                        loading ? "Carregando..." : "Selecione a Rede"
                      }
                      disabled={loading}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#1f2429",
                          border: "1px solid #2a4661",
                          color: "#aaa",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#aaa",
                        }),
                      }}
                    />
                  </Col>
                  {redeSelecionada?.value && (
                    <Col lg="6">
                      <Label for="moeda">
                        Moeda
                        <span className="text-danger"> *</span>
                      </Label>
                      <Select
                        value={moedaSelecionada}
                        onChange={(sortBy) => {
                          setMoedaSelecionada(sortBy);
                          setSiglaMoeda(sortBy.sigla);
                        }}
                        options={moedas}
                        id="moedas"
                        placeholder={
                          loading ? "Carregando..." : "Selecione a Moeda"
                        }
                        disabled={loading}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#1f2429",
                            border: "1px solid #2a4661",
                            color: "#aaa",
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: "#aaa",
                          }),
                        }}
                      />
                    </Col>
                  )}
                  {moedaSelecionada?.value === "0" && (
                    <>
                      <Col lg="6 mt-3">
                        <Label for="enderecoMoeda">
                          Endereço da Moeda
                          <span className="text-danger"> *</span>
                        </Label>
                        <Input
                          type="text"
                          name="enderecoMoeda"
                          id="enderecoMoeda"
                          placeholder="Digite aqui o endereço da moeda"
                          value={enderecoMoeda}
                          onChange={(e) =>
                            fetchMoedaDados(
                              e.target.value,
                              redeSelecionada.value
                            )
                          }
                        />
                      </Col>
                      <Col lg="6 mt-3">
                        <Label for="nomeMoeda">
                          Nome
                          <span className="text-danger"> *</span>
                        </Label>
                        <Input
                          type="text"
                          name="nomeMoeda"
                          id="nomeMoeda"
                          placeholder="Nome da moeda"
                          value={nomeMoeda}
                          onChange={(e) => setNomeMoeda(e.target.value)}
                          maxLength={50}
                        />
                      </Col>
                      <Col lg="6 mt-3">
                        <Label for="siglaMoeda">
                          Sigla {loading && <Spinner size="sm" />}
                        </Label>
                        <Input
                          type="text"
                          name="siglaMoeda"
                          id="siglaMoeda"
                          placeholder="SIGLA"
                          value={siglaMoeda}
                          onChange={() => {}}
                          readOnly
                        />
                      </Col>
                      <Col lg="6 mt-3">
                        <Label for="decimaisMoeda">
                          Decimais {loading && <Spinner size="sm" />}
                        </Label>
                        <Input
                          type="text"
                          name="decimaisMoeda"
                          id="decimaisMoeda"
                          placeholder="Decimais"
                          value={decimaisMoeda}
                          onChange={() => {}}
                          readOnly
                        />
                      </Col>
                      <Col lg="12">
                        <Button
                          color="primary"
                          className="mt-3"
                          size="sm"
                          onClick={() => cadastrarMoeda()}
                        >
                          Cadastrar Moeda
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              {moedaSelecionada?.value && moedaSelecionada?.value !== "0" && (
                <>
                  <Row className="mt-4 border-top pt-3">
                    <h4 className="card-title">Grupo 1</h4>
                    <Col lg="6">
                      <Label for="alocacao1">
                        Alocação
                        <span className="text-danger"> *</span>
                      </Label>
                      <NumericFormat
                        id="alocacao1"
                        placeholder="0,00"
                        className="form-control"
                        value={alocacao1 || ""}
                        onChange={(e) => {
                          setAlocacao1(e.target.value);
                          calcularTotal(e.target.value, alocacao2, alocacao3);
                        }}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="porcentagem1">
                        Taxa do Organizador (%)
                        <span className="text-danger"> *</span>
                      </Label>
                      <NumericFormat
                        id="porcentagem1"
                        placeholder="0,00"
                        className="form-control"
                        value={porcentagem1 || ""}
                        onChange={(e) => setPorcentagem1(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix=" %"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="6">
                      <Label for="investimentoMinimo1">
                        Investimento Mínimo / carteira
                        <span className="text-danger"> *</span>
                      </Label>
                      <NumericFormat
                        id="investimentoMinimo1"
                        placeholder="0,00"
                        className="form-control"
                        value={investimentoMinimo1 || ""}
                        onChange={(e) => setInvestimentoMinimo1(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="investimentoMaximo1">
                        Investimento Máximo / carteira
                        <span className="text-danger"> *</span>
                      </Label>
                      <NumericFormat
                        id="investimentoMaximo1"
                        placeholder="0,00"
                        className="form-control"
                        value={investimentoMaximo1 || ""}
                        onChange={(e) => setInvestimentoMaximo1(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4 border-top pt-3">
                    <h4 className="card-title">Grupo 2</h4>
                    <Col lg="6">
                      <Label for="alocacao2">Alocação</Label>
                      <NumericFormat
                        id="alocacao2"
                        placeholder="0,00"
                        className="form-control"
                        value={alocacao2 || ""}
                        onChange={(e) => {
                          setAlocacao2(e.target.value);
                          calcularTotal(alocacao1, e.target.value, alocacao3);
                        }}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="porcentagem2">Taxa do Organizador (%)</Label>
                      <NumericFormat
                        id="porcentagem2"
                        placeholder="0,00"
                        className="form-control"
                        value={porcentagem2 || ""}
                        onChange={(e) => setPorcentagem2(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix=" %"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="6">
                      <Label for="investimentoMinimo2">
                        Investimento Mínimo / carteira
                      </Label>
                      <NumericFormat
                        id="investimentoMinimo2"
                        placeholder="0,00"
                        className="form-control"
                        value={investimentoMinimo2 || ""}
                        onChange={(e) => setInvestimentoMinimo2(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="investimentoMaximo2">
                        Investimento Máximo / carteira
                      </Label>
                      <NumericFormat
                        id="investimentoMaximo2"
                        placeholder="0,00"
                        className="form-control"
                        value={investimentoMaximo2 || ""}
                        onChange={(e) => setInvestimentoMaximo2(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4 border-top pt-3">
                    <h4 className="card-title">Grupo 3</h4>
                    <Col lg="6">
                      <Label for="alocacao3">Alocação</Label>
                      <NumericFormat
                        id="alocacao3"
                        placeholder="0,00"
                        className="form-control"
                        value={alocacao3 || ""}
                        onChange={(e) => {
                          setAlocacao3(e.target.value);
                          calcularTotal(alocacao1, alocacao2, e.target.value);
                        }}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="porcentagem3">Taxa do Organizador (%)</Label>
                      <NumericFormat
                        id="porcentagem3"
                        placeholder="0,00"
                        className="form-control"
                        value={porcentagem3 || ""}
                        onChange={(e) => setPorcentagem3(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix=" %"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="6">
                      <Label for="investimentoMinimo3">
                        Investimento Mínimo / carteira
                      </Label>
                      <NumericFormat
                        id="investimentoMinimo3"
                        placeholder="0,00"
                        className="form-control"
                        value={investimentoMinimo3 || ""}
                        onChange={(e) => setInvestimentoMinimo3(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="investimentoMaximo3">
                        Investimento Máximo / carteira
                      </Label>
                      <NumericFormat
                        id="investimentoMaximo3"
                        placeholder="0,00"
                        className="form-control"
                        value={investimentoMaximo3 || ""}
                        onChange={(e) => setInvestimentoMaximo3(e.target.value)}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4 border-top pt-3">
                    <Col lg="6">
                      <Label for="alocacaoTotal">Alocação Total</Label>
                      <NumericFormat
                        id="alocacaoTotal"
                        placeholder="0,00"
                        className="form-control"
                        value={alocacaoTotal || "0"}
                        onChange={() => {}}
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        suffix={` ${siglaMoeda}` || ""}
                        readOnly
                      />
                    </Col>
                    <Col lg="6">
                      <Label for="carteiraRecebimento">
                        Carteira de Recebimento{" "}
                        <span className="text-danger"> *</span>
                      </Label>
                      <Input
                        type="text"
                        name="carteiraRecebimento"
                        id="carteiraRecebimento"
                        placeholder="0x ..."
                        value={carteiraRecebimento}
                        onChange={(e) => setCarteiraRecebimento(e.target.value)}
                        maxLength={42}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mt-3">
                <Col lg="12" className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="mt-3 mb-5"
                    onClick={() => editar(projeto_id)}
                    disabled={loading}
                  >
                    Editar
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title">Carrossel de Imagens</h4>
                </CardHeader>
                <CardBody>
                  <Row className="mt-3">
                    <Label for="tipoImagem">Tipo de Imagem</Label>
                    <Col lg="6">
                      <Input
                        type="radio"
                        name="tipoImagem"
                        id="tipoImagem"
                        value="local"
                        onChange={(e) => setTipoImagem(e.target.value)}
                        checked={tipoImagem === "local"}
                      />
                      <Label for="local" className="ms-2">
                        Local
                      </Label>
                    </Col>
                    <Col lg="6">
                      <Input
                        type="radio"
                        name="tipoImagem"
                        id="tipoImagem"
                        value="remoto"
                        onChange={(e) => setTipoImagem(e.target.value)}
                        checked={tipoImagem === "remoto"}
                      />
                      <Label for="remoto" className="ms-2">
                        Remoto
                      </Label>
                    </Col>
                  </Row>
                  {tipoImagem === "local" ? (
                    <Row className="mt-3">
                      <Col lg="12">
                        <Label for="imagem">
                          Imagem
                          <span className="text-danger"> *</span>
                        </Label>
                        <Input
                          type="file"
                          name="imagem"
                          id="imagem"
                          ref={imagemLocal}
                          placeholder="Selecione a imagem"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setImagemLocal(file);
                            }
                          }}
                        />
                      </Col>
                      <Col lg="12" className="mt-3">
                        <Label for="nomeImagem">
                          Nome da Imagem
                          <span className="text-danger"> *</span>
                        </Label>
                        <Input
                          type="text"
                          name="nomeImagem"
                          id="nomeImagem"
                          placeholder="Digite aqui o nome da imagem"
                          value={nomeImagem}
                          onChange={(e) => setNomeImagem(e.target.value)}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row className="mt-3">
                      <Col lg="12">
                        <Label for="imagemUrl">
                          URL da Imagem
                          <span className="text-danger"> *</span>
                        </Label>
                        <Input
                          type="text"
                          name="imagemUrl"
                          id="imagemUrl"
                          placeholder="Digite aqui a URL da imagem"
                          value={imagemUrl}
                          onChange={(e) => setImagemUrl(e.target.value)}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-1">
                    <Col lg="12" className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        className="mt-3"
                        size="sm"
                        onClick={() => handleImageSubmit()}
                        disabled={
                          (tipoImagem === "local" &&
                            (!imagemLocal || !nomeImagem)) ||
                          (tipoImagem === "remoto" && !imagemUrl)
                        }
                      >
                        Adicionar Imagem
                      </Button>
                    </Col>
                  </Row>
                  {imagens.length > 0 && (
                    <Row className="mt-3">
                      <table className="table table-bordered table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th>Imagem</th>
                            <th>Destaque ? </th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        {/* acoes vai ter lixeirinha de excluir FeatherIcon */}
                        <tbody>
                          {imagens.map((imagem, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={imagem.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {imagem.url.split("/").pop().substring(0, 15)}
                                  ...
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  onClick={() => mudarDestaque(index)}
                                >
                                  {imagem.principal ? (
                                    <span className="badge bg-success">
                                      Sim
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger">Não</span>
                                  )}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  onClick={() => removerImagem(index)}
                                >
                                  <FeatherIcon
                                    icon="trash-2"
                                    className="text-danger"
                                    size={16}
                                  />
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Row>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Site / Redes Sociais</h4>
                </CardHeader>
                <CardBody>
                  <Row className="mt-3">
                    <Col lg="12">
                      <Label for="site">Site</Label>
                      <Input
                        type="text"
                        name="site"
                        id="site"
                        placeholder="Digite aqui o site do projeto"
                        value={site}
                        onChange={(e) => setSite(e.target.value)}
                      />
                    </Col>
                    <Col lg="12 mt-3">
                      <Label for="twitterUrl">X</Label>
                      <Input
                        type="text"
                        name="twitterUrl"
                        id="twitterUrl"
                        placeholder="Digite aqui o Twitter do projeto"
                        value={twitterUrl}
                        onChange={(e) => setTwitterUrl(e.target.value)}
                      />
                    </Col>
                    <Col lg="12 mt-3">
                      <Label for="discordUrl">Discord</Label>
                      <Input
                        type="text"
                        name="discordUrl"
                        id="discordUrl"
                        placeholder="Digite aqui o Discord do projeto"
                        value={discordUrl}
                        onChange={(e) => setDiscordUrl(e.target.value)}
                      />
                    </Col>
                    <Col lg="12 mt-3">
                      <Label for="instagramUrl">Instagram</Label>
                      <Input
                        type="text"
                        name="instagramUrl"
                        id="instagramUrl"
                        placeholder="Digite aqui o Instagram do projeto"
                        value={instagramUrl}
                        onChange={(e) => setInstagramUrl(e.target.value)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditarAlocacao;
