import React, { useState, useEffect, useCallback } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";

import {
  useActiveWalletChain,
  useActiveWallet,
  useSwitchActiveWalletChain,
} from "thirdweb/react";
import { defineChain } from "thirdweb/chains";
import { ethers } from "ethers";
import { claimKeeper } from "../../assets/abi/TokenClaimKeeper";
import BotaoConectar from "../Authentication/BotaoConectar";

import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { Link } from "react-router-dom";

const apiClient = new APIClient();

const GerenciaCarteiras = () => {
  const wallet = useActiveWallet();
  const chain = useActiveWalletChain();
  const switchChain = useSwitchActiveWalletChain();

  const [userDados, setUserDados] = useState({});
  const [carteira, setCarteira] = useState("");
  const [carteiraAdmin, setCarteiraAdmin] = useState("");
  const [contrato, setContrato] = useState("");
  const [contratos, setContratos] = useState([]);
  const [rede, setRede] = useState(null);
  const [carteiras, setCarteiras] = useState([]);
  const [carteirasAdmin, setCarteirasAdmin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);

  const changeContrato = async (id) => {
    setContrato(id);
    contratos.forEach((contrato) => {
      if (contrato.id === parseInt(id)) {
        try {
          switchChain(
            defineChain({
              id: contrato.rede.network_id,
              nativeCurrency: {
                name: contrato.rede.nome,
                symbol: contrato.rede.sigla,
                decimals: 18,
              },
            })
          );
        } catch (error) {
          console.error(error);
          toast.error("Erro ao mudar de rede");
        }
      }
    });
  };

  const registerWallet = async (carteira) => {
    setEditLoading(true);
    const response = await apiClient.post(`/carteiras`, {
      carteira,
    });
    if (response.sucesso) {
      toast.success(response.mensagem);
      setCarteira("");
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(true);
    setEditLoading(false);
  };

  const removeWallet = async (id) => {
    setEditLoading(true);
    const response = await apiClient.delete(`/carteiras/${id}/delete`);
    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(true);
    setEditLoading(false);
  };

  const registerAdminWallet = async (carteira) => {
    setEditLoading(true);
    if (contrato == "") {
      toast.error("Selecione um contrato");
      setEditLoading(false);
      return;
    }
    if (!rede || !chain || !chain?.id) {
      toast.error("Não há uma rede ativa");
      setEditLoading(false);
      return;
    }
    let contratoAddress = "";

    for (let i = 0; i < contratos.length; i++) {
      if (contratos[i].id === parseInt(contrato)) {
        contratoAddress = contratos[i].contrato;
        setRede(contratos[i].rede);
        try {
          switchChain(
            defineChain({
              id: contratos[i].rede.network_id,
              nativeCurrency: {
                name: contratos[i].rede.nome,
                symbol: contratos[i].rede.sigla,
                decimals: 18,
              },
            })
          );
        } catch (error) {
          console.error(error);
          toast.error("Erro ao mudar de rede");
          setEditLoading(false);
          return;
        }
      }
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const claimContrato = new ethers.Contract(
      contratoAddress,
      claimKeeper,
      signer
    );

    const USER_ROLE = ethers.utils.keccak256(ethers.utils.toUtf8Bytes("USER"));
    const hasRole = await claimContrato.hasRole(USER_ROLE, carteira);
    const masterWallet = await claimContrato.masterWallet();
    if (wallet.getAccount().address !== masterWallet) {
      toast.error("Apenas o dono do contrato pode remover carteiras");
      setEditLoading(false);
      return;
    }
    let estimateGas;

    if (!hasRole) {
      try {
        estimateGas = await claimContrato.estimateGas.assignRole(
          USER_ROLE,
          carteira
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setEditLoading(false);
        return;
      }

      try {
        const response = await claimContrato.assignRole(USER_ROLE, carteira, {
          gasLimit: estimateGas,
        });
        await response.wait();
      } catch (error) {
        console.error(error);
        toast.error("Erro ao cadastrar carteira");
        setEditLoading(false);
        return;
      }
    }

    const response = await apiClient.post(`/carteiras/admin`, {
      carteira,
      contrato: contrato,
    });
    if (response.sucesso) {
      toast.success(response.mensagem);
      setCarteiraAdmin("");
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(true);
    setEditLoading(false);
  };

  const removeAdminWallet = async (id) => {
    setEditLoading(true);
    const responseCarteiraExiste = await apiClient.get(
      `/carteiras/admin/${id}`
    );
    let carteiraCadastro;
    if (responseCarteiraExiste.sucesso) {
      carteiraCadastro = responseCarteiraExiste.dados.carteira;
    } else {
      console.error(responseCarteiraExiste.mensagem);
      toast.error(responseCarteiraExiste.mensagem);
      setEditLoading(false);
      return;
    }

    if (!chain || !chain?.id) {
      toast.error("Não há uma rede ativa");
      setEditLoading(false);
      return;
    }
    const redeContrato = carteiraCadastro.contrato.rede;
    if (redeContrato.network_id !== chain.id) {
      setRede(redeContrato);
      try {
        switchChain(
          defineChain({
            id: redeContrato.network_id,
            nativeCurrency: {
              name: redeContrato.nome,
              symbol: redeContrato.sigla,
              decimals: 18,
            },
          })
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao mudar de rede");
        setEditLoading(false);
        return;
      }
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const claimContrato = new ethers.Contract(
      carteiraCadastro.contrato.contrato,
      claimKeeper,
      signer
    );

    const USER_ROLE = ethers.utils.keccak256(ethers.utils.toUtf8Bytes("USER"));
    const hasRole = await claimContrato.hasRole(
      USER_ROLE,
      carteiraCadastro.carteira
    );
    const masterWallet = await claimContrato.masterWallet();
    if (wallet.getAccount().address !== masterWallet) {
      toast.error("Apenas o dono do contrato pode remover carteiras");
      setEditLoading(false);
      return;
    }
    let estimateGas;

    if (hasRole) {
      try {
        estimateGas = await claimContrato.estimateGas.removeRole(
          USER_ROLE,
          carteiraCadastro.carteira
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setEditLoading(false);
        return;
      }

      try {
        const response = await claimContrato.removeRole(
          USER_ROLE,
          carteiraCadastro.carteira,
          {
            gasLimit: estimateGas,
          }
        );

        await response.wait();
      } catch (error) {
        console.error(error);
        toast.error("Erro ao remover carteira");
        setEditLoading(false);
        return;
      }
    }

    const response = await apiClient.delete(`/carteiras/admin/${id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(true);
    setEditLoading(false);
  };

  const getData = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados);
  };

  const getContratos = useCallback(async () => {
    const response = await apiClient.get(`/contratos/TokenClaimKeeper`);
    if (response.sucesso) {
      setContratos(response.dados);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  const getRede = useCallback(async () => {
    setRede(null);
    if (!chain?.id) return;
    const response = await apiClient.get(`/redes/${chain.id}/network`);
    if (response.sucesso) {
      setRede(response.dados);
    } else {
      console.error(response.mensagem);
    }
  }, [chain]);

  const getCarteiras = useCallback(async () => {
    const response = await apiClient.get(`/carteiras`);
    if (response.sucesso) {
      if (response.dados.carteirasAdmin) {
        setCarteiras(response.dados.carteiras);
        setCarteirasAdmin(response.dados.carteirasAdmin);
      } else {
        setCarteiras(response.dados);
      }
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  const preencherCarteira = async (endereco) => {
    setCarteira(endereco);
  };

  const preencherCarteiraAdmin = async (endereco) => {
    setCarteiraAdmin(endereco);
  };

  useEffect(() => {
    const getAll = async () => {
      await getData();
      await getContratos();
      await getRede();
      await getCarteiras();
      setLoading(false);
    };
    getAll();
  }, [wallet, loading, chain]);

  document.title = "Perfil de Usuário | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h5 className="card-title mb-2">Carteiras cadastradas</h5>
          <small className="text-muted">
            Carteiras autorizadas a logar no sistema e fazer claim de tokens
          </small>

          <Card className="mt-2">
            <CardBody>
              {!wallet ? (
                <Row className="justify-content-center text-center mt-3">
                  <Col
                    lg="4"
                    sm="12"
                    className="justify-content-center text-center"
                  >
                    <BotaoConectar />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Carteira</Label>
                      <Input
                        id="carteira"
                        placeholder="0x..."
                        type="text"
                        value={carteira}
                        onChange={(e) => setCarteira(e.target.value)}
                        maxLength={42}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-4 w-100">
                      {carteira === "" && (
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                          onClick={() =>
                            preencherCarteira(wallet?.getAccount()?.address)
                          }
                          className="me-2"
                        >
                          Preencher com carteira conectada
                        </Button>
                      )}
                      <Button
                        type="button"
                        color="info"
                        size="sm"
                        onClick={() => registerWallet(carteira)}
                        disabled={loading || editLoading}
                      >
                        Cadastrar
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <h3>Carteiras cadastradas</h3>
                    {loading ? (
                      <Row className="justify-content-center text-center mt-3">
                        <Spinner />
                      </Row>
                    ) : carteiras.length === 0 ? (
                      <small className="text-muted">
                        Nenhuma carteira cadastrada
                      </small>
                    ) : (
                      carteiras.map((carteira) => (
                        <Row
                          className="d-flex justify-content-between mt-3 w-100"
                          key={carteira.id}
                        >
                          <Col sm={11}>{carteira.carteira}</Col>
                          <Col sm={1}>
                            {carteiras.length > 1 && (
                              <Link
                                to="#"
                                className="btn btn-link link-danger fw-medium p-0"
                                onClick={() => removeWallet(carteira.id)}
                                title="Remover Carteira"
                              >
                                <FeatherIcon
                                  icon="trash"
                                  width="13"
                                  className="text-danger"
                                />
                              </Link>
                            )}
                          </Col>
                        </Row>
                      ))
                    )}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>

          {userDados?.role === "admin" && (
            <>
              <h4 className="card-title mb-2">Carteiras Administradoras</h4>
              <small className="text-muted">
                Carteiras autorizadas enviar tokens sem pagar a taxa de 1%
              </small>

              <Card className="mt-2">
                <CardBody>
                  {!wallet ? (
                    <Row className="justify-content-center text-center mt-3">
                      <Col
                        lg="4"
                        sm="12"
                        className="justify-content-center text-center"
                      >
                        <BotaoConectar />
                      </Col>
                    </Row>
                  ) : loading ? (
                    <Row className="justify-content-center text-center mt-3">
                      <Spinner />
                    </Row>
                  ) : (
                    <Row>
                      <Col lg="6" className="mt-3">
                        <div className="form-group">
                          <Label className="form-label">Carteira Admin</Label>
                          <Input
                            id="carteiraAdmin"
                            placeholder="0x..."
                            type="text"
                            value={carteiraAdmin}
                            onChange={(e) => setCarteiraAdmin(e.target.value)}
                            maxLength={42}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <Label className="form-label">Contratos</Label>
                          <Input
                            type="select"
                            id="contrato"
                            onChange={(e) => changeContrato(e.target.value)}
                            value={contrato}
                          >
                            <option value="">Selecione um contrato</option>
                            {contratos.map((contrato) => (
                              <option key={contrato.id} value={contrato.id}>
                                {contrato.nome} ({contrato.rede.nome})
                              </option>
                            ))}
                          </Input>
                          <small className="text-muted">
                            Rede Conectada:{" "}
                            {rede ? `${rede.nome}` : "Rede não cadastrada"}
                          </small>
                        </div>
                        <div className="d-flex justify-content-end mt-4 w-100">
                          {carteiraAdmin === "" && (
                            <Button
                              type="button"
                              color="success"
                              size="sm"
                              onClick={() =>
                                preencherCarteiraAdmin(
                                  wallet?.getAccount()?.address
                                )
                              }
                              className="me-2"
                            >
                              Preencher com carteira conectada
                            </Button>
                          )}
                          <Button
                            type="button"
                            color="info"
                            size="sm"
                            onClick={() => registerAdminWallet(carteiraAdmin)}
                            disabled={
                              loading || editLoading || !rede || !contrato
                            }
                          >
                            Cadastrar
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6" className="mt-3">
                        <h3>Carteiras cadastradas</h3>
                        {loading ? (
                          <Row className="justify-content-center text-center mt-3">
                            <Spinner />
                          </Row>
                        ) : carteirasAdmin.length === 0 ? (
                          <small className="text-muted">
                            Nenhuma carteira cadastrada
                          </small>
                        ) : (
                          carteirasAdmin.map((carteira) => (
                            <Row
                              className="d-flex justify-content-between mt-3 w-100"
                              key={carteira.id}
                            >
                              <Col sm={11}>
                                {carteira.carteira}
                                <br />
                                <small className="text-muted">
                                  {carteira.contrato.nome} (
                                  {carteira.contrato.rede.nome})
                                </small>
                              </Col>
                              <Col sm={1}>
                                {carteirasAdmin.length > 1 && (
                                  <Link
                                    to="#"
                                    className="btn btn-link link-danger fw-medium p-0"
                                    onClick={() =>
                                      removeAdminWallet(carteira.id)
                                    }
                                    title="Remover Carteira"
                                  >
                                    <FeatherIcon
                                      icon="trash"
                                      width="13"
                                      className="text-danger"
                                    />
                                  </Link>
                                )}
                              </Col>
                            </Row>
                          ))
                        )}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GerenciaCarteiras;
