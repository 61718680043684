import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Input, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalFixHash = ({ modal, toggle, setLoading, claim }) => {
  const [loadingModal, setLoadingModal] = useState(false);
    const [hashClaim, setHashClaim] = useState(claim?.hash || "");

  const updateHashClaim = async (hash, claim_id) => {
    setLoadingModal(true);

    const response = await apiClient.put(`/claim/update-hash-claim/${claim_id}`, {
        hash: hashClaim
      });
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Atualizar Claim ID #{claim?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <h4 className="fw-bold">Atualizar Hash do Claim</h4>
            <p className="mt-5" style={{ fontSize: "1rem" }}>
              <b className="text-danger">Atenção!</b> Use apenas em casos
              onde a hash do claim  do cliente não foi gerada corretamente.
            </p>
          </Row>
          {/* input da hash */}
          <Row className="mt-3">
            <Col>
              <Input
                type="text"
                name="hash"
                id="hash"
                placeholder="Hash Corrigida"
                value={hashClaim}
                onChange={(e) => setHashClaim(e.target.value)}
              />
            </Col>
            </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-warning fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-warning"
              onClick={() => updateHashClaim(hashClaim, claim?.id)}
              disabled={loadingModal}
            >
              Update
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalFixHash;
