import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import FeatherIcon from "feather-icons-react";

const UltimosClientes = ({ envios }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState([]);

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < Math.ceil(envios.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = envios.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(currentItems);
  }, [currentPage, envios]);
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Útimos Envios</h4>
            <div className="flex-shrink-0">
              <UncontrolledDropdown className="card-header-dropdown">
                <DropdownToggle
                  tag="a"
                  className="text-reset dropdown-btn"
                  role="button"
                >
                  <span className="text-muted">
                    Ver<i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-end" end>
                  <DropdownItem>
                    <Link to="/envios">Ver Todos os Envios</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-centered table-hover align-middle table-nowrap mb-0">
                <tbody>
                  {currentItems.map((item, key) => {
                    let totalAmount = 0;
                    const dadosEnvio = JSON.parse(item.data);
                    const totalClientes = dadosEnvio.carteiras.length;
                    for (let i = 0; i < totalClientes; i++) {
                      totalAmount += parseFloat(dadosEnvio.amounts[i]);
                    }
                    totalAmount =
                      totalAmount / 10 ** item.projeto?.token?.decimais;
                    totalAmount = totalAmount.toFixed(4);

                    return (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-2">
                              <FeatherIcon
                                icon="send"
                                className="text-warning"
                                width="30"
                              />
                            </div>
                            <div>
                              <h5 className="fs-14 my-1 fw-medium">
                                <Link
                                  to="/apps-ecommerce-seller-details"
                                  className="text-reset"
                                >
                                  {item.projeto.nome}
                                </Link>
                              </h5>
                              <span className="text-muted">
                                {item.projeto.token.nome}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-muted">
                            {totalAmount} {item.projeto.token?.sigla}
                          </span>
                        </td>
                        <td>
                          {item.hash === "" ? (
                            <span className="badge bg-success-subtle text-waiting">
                              Em Espera
                            </span>
                          ) : (
                            <span className="badge bg-success-subtle text-success">
                              Enviado
                            </span>
                          )}
                        </td>
                        <td>
                          <h5 className="fs-14 mb-0">
                            {converterData(item.created_at)}
                          </h5>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
              <div className="col-sm">
                <div className="text-muted">
                  Mostrando <span className="fw-semibold">5</span>
                  {" de "}
                  <span className="fw-semibold">15</span> envios
                </div>
              </div>
              <div className="col-sm-auto mt-3 mt-sm-0">
                <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <Link to="#" className="page-link" onClick={prevPage}>
                      ←
                    </Link>
                  </li>
                  {Array.from(
                    { length: Math.ceil(envios.length / itemsPerPage) },
                    (_, i) => (
                      <li
                        key={i + 1}
                        className={`page-item ${
                          currentPage === i + 1 ? "active" : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(envios.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <Link to="#" className="page-link" onClick={nextPage}>
                      →
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default UltimosClientes;
