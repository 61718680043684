import React, { useState, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import InputMask from "react-input-mask";
import { checkLastLogin, formatEmail } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalNovo = ({ modalNovo, toggle, setLoading }) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState("nacional");
  const [carteira, setCarteira] = useState("");

  const toggleTipoTelefone = useCallback((tipoTelefone) => {
    setTelefone("");
    setTipo(tipoTelefone);
  }, []);

  const criar = async () => {
    setLoadingModal(true);

    const data = {
      nome,
      sobrenome,
      email,
      telefone,
      tipo_telefone: tipo,
      carteira: ethers.utils.getAddress(carteira.toLowerCase()),
    };

    const response = await apiClient.post("/cliente/novo", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      setNome("");
      setSobrenome("");
      setEmail("");
      setTelefone("");
      setCarteira("");

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  return (
    <Modal
      isOpen={modalNovo}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Novo Cliente
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={80}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="sobrenome" className="form-label">
                  Sobrenome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="sobrenome"
                  className="form-control"
                  type="text"
                  placeholder="Sobrenome"
                  value={sobrenome}
                  onChange={(e) => setSobrenome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail <span className="text-danger">*</span>
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(formatEmail(e.target.value))}
                  maxLength={100}
                />
              </div>
            </Col>
            {tipo === "nacional" ? (
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone Nacional |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("usa")}
                    >
                      USA
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("internacional")}
                    >
                      Internacional
                    </a>
                  </Label>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="(11) 99999-9999"
                    defaultValue={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            ) : tipo === "internacional" ? (
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone Internacional |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("nacional")}
                    >
                      Nacional
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("usa")}
                    >
                      USA
                    </a>
                  </Label>
                  <InputMask
                    mask="+999 999 999 9999"
                    placeholder="+999 999 999 9999"
                    defaultValue={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            ) : (
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone USA |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("nacional")}
                    >
                      Nacional
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleTipoTelefone("internacional")}
                    >
                      Internacional
                    </a>
                  </Label>
                  <InputMask
                    mask="+1 (999) 999-9999"
                    placeholder="+1 (999) 999-9999"
                    defaultValue={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            )}
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="carteira" className="form-label">
                  Carteira <span className="text-danger">*</span>
                </Label>
                <Input
                  id="carteira"
                  className="form-control"
                  type="text"
                  placeholder="0x ..."
                  value={carteira}
                  onChange={(e) => setCarteira(e.target.value)}
                  maxLength={42}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-success"
              onClick={() => criar()}
              disabled={loadingModal}
            >
              Criar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
