import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>&copy; {new Date().getFullYear()} ClaimMyTokens.</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Desenvolvido por <b className="text-warning">Valfenda Tech</b>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
