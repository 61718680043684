import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Progress,
  Spinner,
} from "reactstrap";
import CryptoJS from "crypto-js";

import {
  converterData,
  checkLastLogin,
} from "../../Components/FunctionsRepository";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const PaginaAlocacao = () => {
  const { projeto_id } = useParams();

  const alocacaoBaseUrl = "https://claim.meuplanocrypto.com/alocacao-publica/";

  const stringGrupo1 = CryptoJS.MD5(`ALOCACAO:GRUPO1:${projeto_id}`);
  const stringGrupo2 = CryptoJS.MD5(`ALOCACAO:GRUPO2:${projeto_id}`);
  const stringGrupo3 = CryptoJS.MD5(`ALOCACAO:GRUPO3:${projeto_id}`);

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [userDados, setUserDados] = useState(null);

  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [subtitulo, setSubtitulo] = useState("");
  const [site, setSite] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [discordUrl, setDiscordUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [alocacaoTotal, setAlocacaoTotal] = useState("");
  const [carteiraRecebimento, setCarteiraRecebimento] = useState("");
  const [periodoDistribuicao, setPeriodoDistribuicao] = useState("mensal");
  const [periodos, setPeriodos] = useState("12");
  const [distribuicaoDia, setDistribuicaoDia] = useState("5");

  const [envios, setEnvios] = useState([]);
  const [statusProjeto, setStatusProjeto] = useState("ativo");
  const [corStatus, setCorStatus] = useState("success");

  const [alocacao1, setAlocacao1] = useState("");
  const [porcentagem1, setPorcentagem1] = useState("");
  const [investimentoMinimo1, setInvestimentoMinimo1] = useState("");
  const [investimentoMaximo1, setInvestimentoMaximo1] = useState("");
  const [convite, setConvite] = useState(true);

  const [alocacao2, setAlocacao2] = useState("");
  const [porcentagem2, setPorcentagem2] = useState("");
  const [investimentoMinimo2, setInvestimentoMinimo2] = useState("");
  const [investimentoMaximo2, setInvestimentoMaximo2] = useState("");

  const [alocacao3, setAlocacao3] = useState("");
  const [porcentagem3, setPorcentagem3] = useState("");
  const [investimentoMinimo3, setInvestimentoMinimo3] = useState("");
  const [investimentoMaximo3, setInvestimentoMaximo3] = useState("");

  const [redes, setRedes] = useState([]);
  const [moedas, setMoedas] = useState([
    {
      label: `Outras`,
      value: "0",
    },
  ]);
  const [redeSelecionada, setRedeSelecionada] = useState(null);
  const [moedaSelecionada, setMoedaSelecionada] = useState(null);

  const [nomeMoeda, setNomeMoeda] = useState("");
  const [siglaMoeda, setSiglaMoeda] = useState("");
  const [decimaisMoeda, setDecimaisMoeda] = useState("");
  const [enderecoMoeda, setEnderecoMoeda] = useState("");

  const [imagens, setImagens] = useState([]);
  const [tipoImagem, setTipoImagem] = useState("local");
  const [imagemUrl, setImagemUrl] = useState("");
  const [imagemLocal, setImagemLocal] = useState(null);
  const [nomeImagem, setNomeImagem] = useState("");

  const fetchProjeto = useCallback(async (id) => {
    setLoadingPage(true);
    const response = await apiClient.get(`/alocacoes/${id}`);
    if (response.sucesso) {
      const projeto = response.dados;

      document.title = `Ver Alocação | ${projeto.titulo} | Claim My Tokens`;

      setTitulo(projeto.titulo);
      setSubtitulo(projeto.subtitulo);
      setEnvios(projeto.envios);
      setTexto(projeto.texto);
      setSite(projeto.site);
      setTwitterUrl(projeto.twitter_url);
      setDiscordUrl(projeto.discord_url);
      setInstagramUrl(projeto.instagram_url);
      setAlocacaoTotal(projeto.alocacao_total);
      setCarteiraRecebimento(projeto.carteira_recebimento);
      setPeriodoDistribuicao(projeto.periodo_distribuicao);
      setDistribuicaoDia(projeto.distribuicao_dia);
      setAlocacao1(projeto.alocacao_1);
      setPorcentagem1(projeto.porcentagem_1);
      setInvestimentoMinimo1(projeto.investimento_minimo_1);
      setInvestimentoMaximo1(projeto.investimento_maximo_1);
      setAlocacao2(projeto.alocacao_2);
      setPorcentagem2(projeto.porcentagem_2);
      setInvestimentoMinimo2(projeto.investimento_minimo_2);
      setInvestimentoMaximo2(projeto.investimento_maximo_2);
      setAlocacao3(projeto.alocacao_3);
      setPorcentagem3(projeto.porcentagem_3);
      setInvestimentoMinimo3(projeto.investimento_minimo_3);
      setInvestimentoMaximo3(projeto.investimento_maximo_3);
      setConvite(projeto.convite);
      setStatusProjeto(projeto.status);
      setMoedaSelecionada({
        label: `${projeto?.moeda?.nome} (${projeto?.moeda?.sigla})`,
        sigla: `${projeto?.moeda?.sigla}`,
        value: `${projeto?.moeda?.id}`,
      });
      setRedeSelecionada({
        label: `${projeto?.moeda?.rede?.nome} (${projeto?.moeda?.rede?.token_rede})`,
        value: `${projeto?.moeda?.rede?.id}`,
        sigla: `${projeto?.moeda?.rede?.token_rede}`,
        nome: `${projeto?.moeda?.rede?.nome}`,
        network_id: `${projeto?.moeda?.rede?.network_id}`,
      });
      setImagens(projeto.imagens);
      setCorStatus(
        projeto.status === "ativo"
          ? "success"
          : projeto.status === "inativo"
          ? "danger"
          : projeto.status === "iniciado"
          ? "info"
          : projeto.status === "finalizado"
          ? "dark"
          : "secondary"
      );
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    const fetchData = async (projeto_id) => {
      const dados = await getLoggedinUser();
      setUserDados(dados);

      if (titulo === "") {
        await fetchProjeto(projeto_id);
      }

      setLoading(false);
    };
    fetchData(projeto_id);
  }, [projeto_id]);

  document.title = `${titulo} | Claim My Tokens`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Alocação" pageTitle="Projetos" />
          <Link to="/alocacao" className="btn btn-sm btn-secondary">
            <FeatherIcon icon="arrow-left" className="icon-md" />
            Voltar
          </Link>
          {loadingPage ? (
            <Row className="mt-3 d-flex justify-content-center">
              <Spinner color="warning" />
            </Row>
          ) : (
            <Row>
              <Col lg="8">
                <Row className="mt-3">
                  <h1 className="text-warning">{titulo}</h1>
                  <h5 className="text-muted">{subtitulo}</h5>
                </Row>
                <Row className="mt-3 border-top pt-3">
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        {imagens.length > 0 && (
                          <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            pagination={{ clickable: true }}
                            navigation={true}
                            loop={true}
                            autoplay={{
                              delay: 2500,
                              disableOnInteraction: true,
                            }}
                            className="mySwiper swiper navigation-swiper rounded"
                          >
                            <div className="swiper-wrapper">
                              {imagens.map((imagem, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <img
                                      src={imagem.url}
                                      alt={`img-${index}`}
                                      className="img-fluid"
                                    />
                                  </SwiperSlide>
                                );
                              })}
                            </div>
                          </Swiper>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3 border-top pt-3">
                  <Col lg="12">
                    <Card>
                      <CardHeader>
                        <h4>Descrição do projeto</h4>
                      </CardHeader>
                      <CardBody>
                        <div dangerouslySetInnerHTML={{ __html: texto }}></div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg="4">
                <Card>
                  <CardBody>
                    <h5>
                      Status:
                      <span className={`badge bg-${corStatus} ms-2`}>
                        {statusProjeto.at(0).toUpperCase() +
                          statusProjeto.slice(1)}
                      </span>
                    </h5>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5>Redes Sociais / Sites</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      {site && site !== "" && (
                        <a
                          href={site}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon icon="globe" className="icon-md" />
                        </a>
                      )}
                      {twitterUrl && twitterUrl !== "" && (
                        <a
                          href={twitterUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon icon="twitter" className="icon-md" />
                        </a>
                      )}
                      {discordUrl && discordUrl !== "" && (
                        <a
                          href={discordUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon
                            icon="message-circle"
                            className="icon-md"
                          />
                        </a>
                      )}
                      {instagramUrl && instagramUrl !== "" && (
                        <a
                          href={instagramUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <FeatherIcon icon="instagram" className="icon-md" />
                        </a>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5>Carteira de Recebimento</h5>
                  </CardHeader>
                  <CardBody>
                    <p className="text-warning">{carteiraRecebimento}</p>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5>Alocação Total</h5>
                  </CardHeader>
                  <CardBody>
                    <p>
                      0 de {parseFloat(alocacaoTotal).toFixed(2)}{" "}
                      {moedaSelecionada?.sigla}
                    </p>

                    <div className="live-preview">
                      <div>
                        <Progress value={0} striped color="success" animated />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <h5>Alocação Grupo 1</h5>
                      </div>
                      <div className="col-sm-auto">
                        <Link
                          to="#"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${alocacaoBaseUrl}${projeto_id}/${stringGrupo1}`
                            );
                            toast.success(
                              "Link copiado para a área de transferência"
                            );
                          }}
                        >
                          <i className="ri-file-copy-2-line align-bottom me-1"></i>
                          Copiar Link Grupo 1
                        </Link>{" "}
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <p>
                      0 de {parseFloat(alocacao1).toFixed(2)}{" "}
                      {moedaSelecionada?.sigla}
                    </p>

                    <div className="live-preview">
                      <div>
                        <Progress value={0} striped color="success" animated />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {parseFloat(alocacao2) > 0 && (
                  <Card>
                    <CardHeader>
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <h5>Alocação Grupo 2</h5>
                        </div>
                        <div className="col-sm-auto">
                          <Link
                            to="#"
                            className="btn btn-success btn-sm"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${alocacaoBaseUrl}${projeto_id}/${stringGrupo2}`
                              );
                              toast.success(
                                "Link copiado para a área de transferência"
                              );
                            }}
                          >
                            <i className="ri-file-copy-2-line align-bottom me-1"></i>
                            Copiar Link Grupo 2
                          </Link>{" "}
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <p>
                        0 de {parseFloat(alocacao2).toFixed(2)}{" "}
                        {moedaSelecionada?.sigla}
                      </p>

                      <div className="live-preview">
                        <div>
                          <Progress
                            value={0}
                            striped
                            color="success"
                            animated
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )}
                {parseFloat(alocacao3) > 0 && (
                  <Card>
                    <CardHeader>
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <h5>Alocação Grupo 3</h5>
                        </div>
                        <div className="col-sm-auto">
                          <Link
                            to="#"
                            className="btn btn-success btn-sm"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${alocacaoBaseUrl}${projeto_id}/${stringGrupo3}`
                              );
                              toast.success(
                                "Link copiado para a área de transferência"
                              );
                            }}
                          >
                            <i className="ri-file-copy-2-line align-bottom me-1"></i>
                            Copiar Link Grupo 3
                          </Link>{" "}
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <p>
                        0 de {parseFloat(alocacao3).toFixed(2)}{" "}
                        {moedaSelecionada?.sigla}
                      </p>

                      <div className="live-preview">
                        <div>
                          <Progress
                            value={0}
                            striped
                            color="success"
                            animated
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )}
                <Card>
                  <CardHeader>
                    <h5>Dados de Distribuição</h5>
                  </CardHeader>
                  <CardBody>
                    <p>
                      <strong className="text-warning">Periodicidade:</strong>{" "}
                      {periodoDistribuicao.charAt(0).toUpperCase() +
                        periodoDistribuicao.slice(1)}
                    </p>
                    <p>
                      <strong className="text-warning">Períodos:</strong>{" "}
                      {periodos}
                    </p>
                    <p>
                      <strong className="text-warning">
                        Dia de distribuição:
                      </strong>{" "}
                      {distribuicaoDia}
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5>Dias de Envio</h5>
                  </CardHeader>
                  <CardBody>
                    <table
                      className="table table-bordered table-nowrap table-responsive"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Enviado ?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {envios.map((envio, index) => {
                          return (
                            <tr key={index}>
                              <td>{converterData(envio.data_envio)}</td>
                              <td>
                                {envio.enviado ? "Enviado" : "Não enviado"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaginaAlocacao;
