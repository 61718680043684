import React, { Fragment } from "react";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import Regras from "./Regras";

const Informacoes = () => {
  document.title = "Informações | Claim My Tokens";

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Informações" pageTitle="Painel" />
          <Row>
            <Col xl={12} md={12}>
              <Regras />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Informacoes;
