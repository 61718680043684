import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useActiveWalletChain } from "thirdweb/react";
import { ethers } from "ethers";
import { claimKeeper } from "../../assets/abi/TokenClaimKeeper";
import { erc20 } from "../../assets/abi/Erc20";
import { APIClient } from "../../helpers/api_helper";
import BotaoConectar from "../Authentication/BotaoConectar";

const apiClient = new APIClient();

const ModalEnviar = ({
  modalEnviar,
  toggle,
  setLoading,
  envio,
  converterData,
  wallet,
}) => {
  const chain = useActiveWalletChain();
  const [loadingModal, setLoadingModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [carteirasContrato, setCarteirasContrato] = useState([]);

  const updateHash = async (hash, envio, avisar = true) => {
    const response = await apiClient.put(
      `/registro-envios/update-hash/${envio.id}`,
      {
        hash: hash ? hash : "0x0",
        carteira: wallet?.getAccount()?.address,
      }
    );
    if (response.sucesso) {
      if (avisar) toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  const getCarteiras = useCallback(async (contrato_id) => {
    const response = await apiClient.get(`/carteiras/contrato/${contrato_id}`);
    if (response.sucesso) {
      setCarteirasContrato(response.dados);
      setIsAdmin(checkCarteiras(wallet?.getAccount()?.address, response.dados));
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  const checkCarteiras = (carteira, carteiras) => {
    let returnValue = false;
    carteiras.forEach((carteiraContrato) => {
      if (carteira.toLowerCase() === carteiraContrato.carteira.toLowerCase()) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const envioUnico = async (envio, projeto) => {
    setLoadingModal(true);
    const dadosEnvio = JSON.parse(envio.data);
    if (chain.id !== projeto?.token?.rede?.network_id) {
      toast.error("Mude para a rede correta para enviar os tokens");
      setLoadingModal(false);
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenAddress = projeto.token.contrato;
    const tokenContrato = new ethers.Contract(tokenAddress, erc20, signer);

    const contrato = projeto.contrato?.contrato;
    if (!contrato) return;

    let receipt;
    try {
      const contract = new ethers.Contract(contrato, claimKeeper, signer);
      const carteiras = dadosEnvio.carteiras;
      let estimateGas;

      const totalAmount = ethers.BigNumber.from(
        envio.total_token.toString().split(".")[0]
      );

      try {
        estimateGas = await tokenContrato.estimateGas.approve(
          contrato,
          totalAmount
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoadingModal(false);
        return;
      }
      const approveResponse = await tokenContrato.approve(
        contrato,
        totalAmount,
        {
          gasLimit: estimateGas,
        }
      );
      await approveResponse.wait();

      const quantidadesBigNumber = dadosEnvio.amounts.map((qtd) =>
        ethers.BigNumber.from(qtd.toString().split(".")[0])
      );

      try {
        estimateGas = await contract.estimateGas.depositTokens(
          tokenAddress,
          quantidadesBigNumber,
          carteiras
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoadingModal(false);
        return;
      }

      const transactionResponse = await contract.depositTokens(
        tokenAddress,
        quantidadesBigNumber,
        carteiras,
        {
          gasLimit: estimateGas,
        }
      );
      // get the transaction hash before waiting
      let txHash = await transactionResponse.hash;
      await updateHash(txHash, envio, false);
      receipt = await transactionResponse.wait();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar transação");
      setLoadingModal(false);
      return;
    }
    await updateHash(receipt.transactionHash, envio);
    setLoadingModal(false);
    setLoading(true);
    toggle();
  };

  useEffect(() => {
    const fetchCarteiras = async () => {
      if (envio?.projeto?.contrato) {
        await getCarteiras(envio.projeto.contrato.id);
      }
    };
    if (modalEnviar && wallet) fetchCarteiras();
  }, [envio, wallet]);

  return (
    <Modal
      isOpen={modalEnviar}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          !loadingModal && toggle();
        }}
      >
        Realizar Envio
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <h4 className="fw-bold">Confirmação de Envio</h4>
            <small className="text-muted">
              <b>{envio?.projeto?.nome}</b> Em{" "}
              {converterData(envio?.created_at || "")}
            </small>
            <small className="text-muted">
              <b>Carteira conectada:</b> {wallet?.getAccount()?.address}
            </small>
            <p className="mt-5" style={{ fontSize: "1rem" }}>
              A ação de envio é irreversível. Deseja realmente enviar os tokens?
            </p>
            {!isAdmin && (
              <>
                <br />
                <small className="text-warning">
                  Será cobrado 1% do valor total do envio para esta transação.
                </small>
              </>
            )}
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            {!loadingModal && (
              <Link
                to="#"
                className="btn btn-link link-info fw-medium"
                onClick={() => toggle()}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
            )}
            {chain ? (
              <Button
                className="btn btn-info"
                onClick={() => {
                  envioUnico(envio, envio?.projeto);
                }}
                disabled={loadingModal}
              >
                {loadingModal ? (
                  <>
                    <Spinner size="sm" /> Enviando
                  </>
                ) : (
                  "Enviar"
                )}
              </Button>
            ) : (
              <BotaoConectar />
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEnviar;
