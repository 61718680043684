import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalRelatorio = ({ modal, toggle, projeto }) => {
  const [loadingModal, setLoadingModal] = useState(false);

  const baixarRelatorio = async (projeto) => {
    setLoadingModal(true);

    const response = await apiClient.get(`/projetos/${projeto.id}/relatorio`);
    if (response.sucesso) {
      const csvContent = response.dados;

      // Criar um objeto Blob e uma URL para ele
      const blob = new Blob([csvContent], { type: "text/csv" });
      const blobURL = window.URL.createObjectURL(blob);

      // Criar e configurar um elemento de link para iniciar o download
      const fileName = `relatorio_${projeto.nome}.csv`;
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = fileName;
      link.click();

      // Limpar a URL do blob
      window.URL.revokeObjectURL(blobURL);

      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Relatório do Projeto {projeto?.nome || ""}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <h4 className="fw-bold">Confirmação de Download de Relatório</h4>
            <small className="text-muted">
              {projeto?.nome ? `${projeto.nome} (${projeto?.token.nome})` : ""}
            </small>
            <p className="mt-5" style={{ fontSize: "1rem" }}>
              Ao clicar no botão{" "}
              <b className="text-warning">Baixar Relatório</b>, o sistema fará o
              download de um arquivo CSV contendo um relatório de listage, todos os
              clientes atuais cadastrados no projeto e suas carteiras.
            </p>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Link
              to="#"
              className="btn btn-warning"
              onClick={() => baixarRelatorio(projeto)}
              disabled={loadingModal}
            >
              Baixar Relatório
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalRelatorio;
