import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import { Link } from "react-router-dom";
import Paginacao from "../../Components/Common/Paginacao";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchOption from "./SearchOption";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";
import ModalEnviar from "./ModalEnviar";
import ModalRelatorio from "./ModalRelatorio";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const Projetos = () => {
  const [userDados, setUserDados] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [modalNovo, setModalNovo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalEnviar, setModalEnviar] = useState(false);
  const [modalRelatorio, setModalRelatorio] = useState(false);

  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [base, setBase] = useState("meus");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const arrowNavToggle = (tab) => {
    if (base !== tab) {
      setBase(tab);
      setCurrentPage(1);
      setLoading(true);
    }
  };

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalEditar = useCallback(
    (proj) => {
      setProjeto(proj);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalApagar = useCallback(
    (proj) => {
      setProjeto(proj);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const toggleModalEnviar = useCallback(
    (proj) => {
      setProjeto(proj);
      setModalEnviar(!modalEnviar);
    },
    [modalEnviar]
  );

  const toggleModalRelatorio = useCallback(
    (proj) => {
      setProjeto(proj);
      setModalRelatorio(!modalRelatorio);
    },
    [modalRelatorio]
  );

  const fetchProjetos = useCallback(async (params = {}) => {
    const response = await apiClient.post("/projetos/buscar", {
      ...params,
    });

    if (response.sucesso) {
      setProjetos(response.dados.data);
      setTotalItems(response.dados.total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const getProjetos = async () => {
      setUserDados(getLoggedinUser());
      await fetchProjetos({
        base,
        termo: termoBusca,
        itens_pagina: itemsPerPage,
        pagina: currentPage,
      });
      setLoading(false);
      setLoadTable(false);
    };
    getProjetos();
  }, [fetchProjetos, loading, itemsPerPage, currentPage, termoBusca, base]);

  document.title = "Projetos de Envio | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Projetos de Envio" pageTitle="Projetos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2 mt-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                    setLoading(true);
                  }}
                  value={itemsPerPage}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Col>
              <Col xl={10}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setCurrentPage}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          {userDados.role === "admin" && (
            <Nav
              pills
              className="nav nav-pills arrow-navtabs nav-info bg-light mb-3 mt-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "meus",
                  })}
                  onClick={() => {
                    arrowNavToggle("meus");
                  }}
                >
                  Meus Projetos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "todos",
                  })}
                  onClick={() => {
                    arrowNavToggle("todos");
                  }}
                >
                  Todos
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Lista de Projetos</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => toggleModalNovo()}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Novo
                          Projeto
                        </button>{" "}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {!loading && !loadTable ? (
                      <table className="table table-hover table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                id="checkBoxAll"
                                className="form-check-input"
                                onClick={() => {}}
                              />
                            </th>
                            <th scope="col">Nome</th>
                            <th scope="col">Token</th>
                            <th scope="col">Criador</th>
                            <th scope="col">Clientes</th>
                            <th scope="col">Status</th>
                            <th scope="col">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(projetos || []).map((projeto, index) => {
                            const statusColor =
                              projeto.status === "ativo"
                                ? "success"
                                : projeto.status === "inativo"
                                ? "danger"
                                : "warning";

                            return (
                              <tr key={index}>
                                <th scope="row">
                                  <input
                                    type="checkbox"
                                    className="customerCheckBox form-check-input"
                                    value={projeto.id}
                                    onChange={() => {}}
                                  />
                                </th>
                                <td>
                                  {projeto?.nome}
                                  <br />
                                  <small className="text-muted">
                                    <b>Rede: </b>
                                    {projeto?.token?.rede?.nome}
                                  </small>
                                </td>
                                <td>{projeto?.token?.nome}</td>
                                <td>{projeto?.criador?.name}</td>
                                <td className="text-success">
                                  {projeto.clientes_count}
                                </td>
                                <td>
                                  <span
                                    className={`badge bg-${statusColor}-subtle text-${statusColor}`}
                                  >
                                    {projeto.status.charAt(0).toUpperCase() +
                                      projeto.status.slice(1)}
                                  </span>
                                </td>
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    {/* ver claims em  /claim-projeto/:projeto_id */}
                                    <li
                                      className="list-inline-item edit"
                                      title="Ver Claims dos clientes"
                                    >
                                      <Link
                                        to={`/claim-projeto/${projeto.id}`}
                                        className="text-muted d-inline-block edit-item-btn"
                                      >
                                        <i className="ri-eye-fill fs-16"></i>
                                      </Link>
                                    </li>
                                    <li
                                      className="list-inline-item edit"
                                      title="Relatório"
                                    >
                                      <Link
                                        to="#"
                                        className="text-warning d-inline-block edit-item-btn"
                                        onClick={() => {
                                          toggleModalRelatorio(projeto);
                                        }}
                                      >
                                        <i className="ri-file-download-fill fs-16"></i>
                                      </Link>
                                    </li>
                                    <li
                                      className="list-inline-item edit"
                                      title="Enviar"
                                    >
                                      <Link
                                        to="#"
                                        className="text-success d-inline-block edit-item-btn"
                                        onClick={() => {
                                          toggleModalEnviar(projeto);
                                        }}
                                      >
                                        <i className="ri-send-plane-fill fs-16"></i>
                                      </Link>
                                    </li>
                                    <li
                                      className="list-inline-item edit"
                                      title="Editar"
                                    >
                                      <Link
                                        to="#"
                                        className="text-info d-inline-block edit-item-btn"
                                        onClick={() => {
                                          toggleModalEditar(projeto);
                                        }}
                                      >
                                        <i className="ri-pencil-fill fs-16"></i>
                                      </Link>
                                    </li>
                                    <li
                                      className="list-inline-item"
                                      title="Remover"
                                      onClick={() => {
                                        toggleModalApagar(projeto);
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        className="text-danger d-inline-block remove-item-btn"
                                        onClick={() => {}}
                                      >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <Row className="justify-content-center mt-3 mb-3">
                        <Spinner color="warning" />
                      </Row>
                    )}
                  </div>
                  {totalItems > itemsPerPage && (
                    <Paginacao
                      total={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      perPageData={itemsPerPage}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>

          <ModalNovo
            modalNovo={modalNovo}
            toggle={toggleModalNovo}
            setLoading={setLoading}
          />
          <ModalEditar
            modalEditar={modalEditar}
            toggle={toggleModalEditar}
            projeto={projeto}
            setLoading={setLoading}
          />
          <ModalEnviar
            modalEnviar={modalEnviar}
            toggle={toggleModalEnviar}
            projeto={projeto}
            setLoading={setLoading}
          />
          <ModalApagar
            modalApagar={modalApagar}
            toggle={toggleModalApagar}
            projeto={projeto}
            setLoading={setLoading}
          />
          <ModalRelatorio
            modal={modalRelatorio}
            toggle={toggleModalRelatorio}
            projeto={projeto}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Projetos;
