import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const apiClient = new APIClient();

//import images
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentNotifications, setCurrentNotifications] = useState([]);
  const [notificationsPerPage] = useState(5);

  const changePage = (direction) => {
    if (
      direction === "next" &&
      currentPage < Math.ceil(notificacoes.length / notificationsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification =
      indexOfLastNotification - notificationsPerPage;
    setCurrentNotifications(
      notificacoes.slice(indexOfFirstNotification, indexOfLastNotification)
    );
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/notificacoes");
    if (response.sucesso) {
      setNotificacoes(response.dados);

      const indexOfLastNotification = currentPage * notificationsPerPage;
      const indexOfFirstNotification =
        indexOfLastNotification - notificationsPerPage;
      setCurrentNotifications(
        response.dados.slice(indexOfFirstNotification, indexOfLastNotification)
      );
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }

    setLoading(false);
  }, []);

  const marcarLida = async (id) => {
    setLoading(true);
    const response = await apiClient.put(`/notificacoes/marcar-lida/${id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      fetchData();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [fetchData]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {notificacoes.length}
            <span className="visually-hidden">não lidas</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notificações{" "}
                  </h6>
                </Col>
              </Row>
            </div>
          </div>

          <TabContent activeTab="1">
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {notificacoes.length === 0 ? (
                  <TabPane tabId="1" className="p-4">
                    <div className="w-25 w-sm-50 pt-3 mx-auto">
                      <img src={bell} className="img-fluid" alt="user-pic" />
                    </div>
                    <div className="text-center pb-5 mt-2">
                      <h6 className="fs-18 fw-semibold lh-base">
                        Você não tem notificações{" "}
                      </h6>
                    </div>
                  </TabPane>
                ) : (
                  currentNotifications.map((notificacao, key) => (
                    <div
                      className="text-reset notification-item d-block dropdown-item"
                      onClick={() => marcarLida(notificacao.id)}
                      key={key}
                    >
                      <div className="d-flex">
                        <div className="me-3 rounded-circle avatar-xs text-warning bg-soft-primary">
                          <FeatherIcon icon={notificacao.icon} />
                        </div>
                        <div className="flex-grow-1">
                          <Link to="#" className="stretched-link">
                            <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                              {notificacao.titulo}
                            </h6>
                          </Link>
                          <div className="fs-13 text-muted">
                            <p className="mb-1">{notificacao.texto}</p>
                          </div>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {converterData(notificacao.created_at)}
                            </span>
                          </p>
                        </div>
                        <div className="px-2 fs-15">
                          <button
                            type="button"
                            className="btn btn-sm btn-soft-success"
                            title="Marcar como lida"
                            onClick={() => marcarLida(notificacao.id)}
                            disabled={loading}
                          >
                            <i className="ri-check-line"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}

                {notificacoes.length > 0 && (
                  <div className="d-flex justify-content-between my-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-secondary waves-effect waves-light"
                      onClick={() => changePage("prev")}
                      disabled={currentPage === 1}
                    >
                      <i className="ri-arrow-left-line align-middle"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-secondary waves-effect waves-light"
                      onClick={() => changePage("next")}
                      disabled={
                        currentPage ===
                        Math.ceil(notificacoes.length / notificationsPerPage)
                      }
                    >
                      <i className="ri-arrow-right-line align-middle"></i>
                    </button>
                  </div>
                )}
              </SimpleBar>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
