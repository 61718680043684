import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ProfileDropdown = ({userData}) => {

  const logout = async () => {
    await apiClient.post('/usuario/logout', {});
  
    localStorage.removeItem("authUser");
    localStorage.removeItem("lastUpdated");
    localStorage.removeItem("totais");
    localStorage.removeItem("arvore");
  
    window.location.href = "/login";
  };  

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <FeatherIcon
              className="rounded-circle header-profile-user"
              icon="user"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userData?.name.split(' ')[0]}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {userData?.role}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Bem-vindo(a) {userData?.name.split(' ')[0]} :
          </h6>
          <DropdownItem className="p-0">
            <Link to="/profile" className="dropdown-item">
              <i className="mdi mdi-account-edit text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Perfil</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/gerencia-carteiras" className="dropdown-item">
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Gerenciar Carteiras</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/informacoes" className="dropdown-item">
              <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Ajuda</span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => logout(userData)}
            >
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
