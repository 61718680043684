import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import Paginacao from "../../Components/Common/Paginacao";

import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchOption from "./SearchOption";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";
import ModalCarteiras from "./ModalCarteiras";
import ModalProjetos from "./ModalProjetos";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const Clientes = () => {
  const [userDados, setUserDados] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [modalNovo, setModalNovo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalCarteiras, setModalCarteiras] = useState(false);
  const [modalProjetos, setModalProjetos] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState({});
  const [clientesProjetos, setClientesProjetos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [base, setBase] = useState("meus");
  const [status, setStatus] = useState("todos");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const arrowNavToggle = (tab) => {
    if (base !== tab) {
      setBase(tab);
      setCurrentPage(1);
      setLoading(true);
    }
  };

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalEditar = useCallback(
    (cli) => {
      setCliente(cli);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalCarteiras = useCallback(
    (cli) => {
      setCliente(cli);
      setModalCarteiras(!modalCarteiras);
    },
    [modalCarteiras]
  );

  const toggleModalApagar = useCallback(
    (cli) => {
      setCliente(cli);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const toggleModalProjetos = useCallback(
    (cli, proj) => {
      setCliente(cli);
      setClientesProjetos(proj);
      setModalProjetos(!modalProjetos);
    },
    [modalProjetos]
  );

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const fetchClientes = useCallback(async (params = {}) => {
    const response = await apiClient.post("/cliente/buscar", {
      ...params,
    });

    if (response.sucesso) {
      setClientes(response.dados.data);
      setTotalItems(response.dados.total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const getClientes = async () => {
      setUserDados(getLoggedinUser());
      await fetchClientes({
        base,
        termo: termoBusca,
        status,
        itens_pagina: itemsPerPage,
        pagina: currentPage,
      });
      setLoading(false);
      setLoadTable(false);
    };
    getClientes();
  }, [fetchClientes, loading, itemsPerPage, currentPage, termoBusca, status]);

  document.title = "Clientes | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Clientes" pageTitle="Painel" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2 mt-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                    setLoading(true);
                  }}
                  value={itemsPerPage}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Col>
              <Col xl={8}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setCurrentPage}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCurrentPage(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={status}
                >
                  <option value="todos">Todos</option>
                  <option value="ativo">Ativos</option>
                  <option value="inativo">Inativos</option>
                  <option value="bloqueado">Bloqueados</option>
                </select>
              </Col>
            </Row>
          </Card>

          {userDados.role === "admin" && (
            <Nav
              pills
              className="nav nav-pills arrow-navtabs nav-info bg-light mb-3 mt-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "meus",
                  })}
                  onClick={() => {
                    arrowNavToggle("meus");
                  }}
                >
                  Meus Clientes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "todos",
                  })}
                  onClick={() => {
                    arrowNavToggle("todos");
                  }}
                >
                  Todos
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Lista de Clientes</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => toggleModalNovo()}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Novo
                          Cliente
                        </button>{" "}
                        <Link
                          to="/importacao"
                          className="btn btn-secondary"
                          onClick={() => {}}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Importar
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {!loading && !loadTable ? (
                      <table className="table table-hover table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                id="checkBoxAll"
                                className="form-check-input"
                                onClick={() => {}}
                              />
                            </th>
                            <th scope="col">Nome</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Telefone</th>
                            <th scope="col">Status</th>
                            <th scope="col">Carteiras</th>
                            <th scope="col">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(clientes || []).map((customer, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="customerCheckBox form-check-input"
                                  value={customer.id}
                                  onChange={() => {}}
                                />
                              </th>
                              <td>
                                {customer.cliente.nome}{" "}
                                {customer.cliente.sobrenome}
                                <br />
                                <small className="text-muted">
                                  {converterData(
                                    userDados.role === "admin"
                                      ? customer.cliente?.created_at
                                      : customer.created_at
                                  )}
                                </small>
                              </td>
                              <td>{customer.cliente.email}</td>
                              <td>{customer.cliente.telefone}</td>
                              <td>
                                {(() => {
                                  switch (customer.cliente?.user?.status) {
                                    case "bloqueado":
                                      return (
                                        <span className="badge text-uppercase bg-info-subtle text-info">
                                          Bloqueado
                                        </span>
                                      );
                                    case "inativo":
                                      return (
                                        <span className="badge text-uppercase bg-danger-subtle text-danger">
                                          Inativo
                                        </span>
                                      );
                                    case "ativo":
                                      return (
                                        <span className="badge text-uppercase bg-success-subtle text-success">
                                          Ativo
                                        </span>
                                      );
                                    default:
                                      return (
                                        <span className="badge bg-dark-subtle text-white">
                                          -
                                        </span>
                                      );
                                  }
                                })()}
                              </td>
                              <td className="text-success">
                                {customer.cliente?.user?.carteiras?.length || 0}
                              </td>
                              <td>
                                <ul className="list-inline hstack gap-2 mb-0">
                                  {/* ver projetos */}
                                  <li
                                    className="list-inline-item"
                                    title="Projetos"
                                  >
                                    <Link
                                      to="#"
                                      className="text-warning d-inline-block edit-item-btn"
                                      onClick={() => {
                                        toggleModalProjetos(customer.cliente, customer.projetos);
                                      }}
                                    >
                                      <i className="ri-folder-info-fill fs-16"></i>
                                    </Link>
                                  </li>

                                  <li
                                    className="list-inline-item edit"
                                    title="Editar"
                                  >
                                    <Link
                                      to="#"
                                      className="text-info d-inline-block edit-item-btn"
                                      onClick={() => {
                                        toggleModalEditar(customer.cliente);
                                      }}
                                    >
                                      <i className="ri-pencil-fill fs-16"></i>
                                    </Link>
                                  </li>
                                  {/* levantar modal de editar carteiras */}
                                  <li
                                    className="list-inline-item"
                                    title="Carteiras"
                                    onClick={() => {
                                      toggleModalCarteiras(customer.cliente);
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      className="text-success d-inline-block edit-item-btn"
                                      onClick={() => {}}
                                    >
                                      <i className="ri-wallet-3-fill fs-16"></i>
                                    </Link>
                                  </li>
                                  <li
                                    className="list-inline-item"
                                    title="Remover"
                                    onClick={() => {
                                      toggleModalApagar(customer.cliente);
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      className="text-danger d-inline-block remove-item-btn"
                                      onClick={() => {}}
                                    >
                                      <i className="ri-delete-bin-5-fill fs-16"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <Row className="justify-content-center mt-3 mb-3">
                        <Spinner color="warning" />
                      </Row>
                    )}
                  </div>
                  {totalItems > itemsPerPage && (
                    <Paginacao
                      total={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      perPageData={itemsPerPage}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>

          <ModalNovo
            modalNovo={modalNovo}
            toggle={toggleModalNovo}
            setLoading={setLoading}
          />
          <ModalEditar
            modalEditar={modalEditar}
            toggle={toggleModalEditar}
            cliente={cliente}
            setLoading={setLoading}
          />
          <ModalCarteiras
            modalCarteiras={modalCarteiras}
            toggle={toggleModalCarteiras}
            cliente={cliente}
            setLoading={setLoading}
          />
          <ModalApagar
            modalApagar={modalApagar}
            toggle={toggleModalApagar}
            cliente={cliente}
            setLoading={setLoading}
          />
          <ModalProjetos
            modalProjetos={modalProjetos}
            toggle={toggleModalProjetos}
            cliente={cliente}
            projetos={clientesProjetos}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Clientes;
