import React from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

function App() {
  return (
    <React.Fragment>
        <Route />
    </React.Fragment>
  );
}

export default App;
