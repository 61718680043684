import React, { useState, useCallback, useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatEmail,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";
import SearchModal from "./SearchModal";
import { ethers } from "ethers";

const apiClient = new APIClient();

const ModalEnviar = ({ modal, setLoading, toggle, alocacao }) => {
  const [loadTable, setLoadTable] = useState(true);
  const [emails, setEmails] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [termo, setTermo] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [adicionadosPaginaAtual, setAdicionadosPaginaAtual] = useState([]);
  const [total, setTotal] = useState(0);
  const [novo, setNovo] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [carteira, setCarteira] = useState("");
  const [grupo, setGrupo] = useState(0);

  const prevPage = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
      setLoadTable(true);
    }
  };

  const nextPage = () => {
    if (paginaAtual < Math.ceil(total / 5)) {
      setPaginaAtual(paginaAtual + 1);
      setLoadTable(true);
    }
  };

  const adicionadoPrevPage = () => {
    if (adicionadosPaginaAtual > 1) {
      setAdicionadosPaginaAtual(adicionadosPaginaAtual - 1);
      setLoadTable(true);
    }
  };

  const adicionadoNextPage = () => {
    if (adicionadosPaginaAtual < Math.ceil(emails.length / 5)) {
      setAdicionadosPaginaAtual(adicionadosPaginaAtual + 1);
      setLoadTable(true);
    }
  };

  const toggleModal = () => {
    setEmails([]);
    setClientes([]);
    setTermo("");
    setPaginaAtual(1);
    setAdicionadosPaginaAtual(1);
    setTotal(0);
    setNovo(false);
    setNome("");
    setSobrenome("");
    setEmail("");
    setCarteira("");
    setGrupo(0);
    
    setLoading(true);
    setLoadTable(true);
    toggle();
  };

  const estaNaLista = (email) => {
    return emails.find((e) => e.email === email);
  };

  const addCliente = (cliente) => {
    if (estaNaLista(cliente.email)) {
      return;
    }
    setEmails([
      ...emails,
      {
        email: cliente.email,
        nome: cliente.nome,
        sobrenome: cliente.sobrenome,
      },
    ]);
  };

  const removeCliente = (email) => {
    setEmails(emails.filter((e) => e.email !== email));
  };

  const criar = async () => {
    setNovo(false);

    const data = {
      nome,
      sobrenome,
      email,
      carteira: ethers.utils.getAddress(carteira.toLowerCase()),
    };

    const response = await apiClient.post("/cliente/novo", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      const clienteDados = response.dados;

      addCliente({
        email: clienteDados?.email,
        nome: clienteDados?.nome,
        sobrenome: clienteDados?.sobrenome,
      });

      setNome("");
      setSobrenome("");
      setEmail("");
      setCarteira("");
      setLoadTable(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const getClientes = useCallback(async (termo, pagina) => {
    const data = {
      itens_pagina: 5,
      pagina,
      termo,
      status: "ativo",
    };

    const response = await apiClient.post("/cliente/buscar", data);
    if (response.sucesso) {
      const dados = response.dados;
      setClientes(dados.data);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const enviarConvites = async (alocacao) => {
    setLoadTable(true);

    const emailsLista = emails.map((email) => email.email);

    const data = {
      alocacao_id: alocacao.id,
      emails: emailsLista,
      grupo
    };

    const response = await apiClient.post(`/alocacoes/enviar-convites`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);

      toggleModal();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadTable(false);
  };

  useEffect(() => {
    const execute = async () => {
      await getClientes(termo, paginaAtual);
      setLoading(false);
      setLoadTable(false);
    };

    if (modal && alocacao && loadTable) {
      execute();
    }
  }, [modal, alocacao, loadTable]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggleModal();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggleModal();
        }}
      >
        Enviar Convites
      </ModalHeader>
      {grupo === 0 ? (
        <ModalBody className="text-left p-5">
          <Row className="g-4 align-items-center pb-3">
            <Col md="12">
              <Label htmlFor="grupo" className="form-label">
                Grupo
              </Label>
              <Input
                type="select"
                id="grupo"
                value={grupo}
                onChange={(e) => setGrupo(parseInt(e.target.value))}
              >
                <option value="0">Selecione um grupo</option>
                <option value="1">Grupo 1</option>
                {alocacao?.alocacao_2 > 0 && <option value="2">Grupo 2</option>}
                {alocacao?.alocacao_3 > 0 && <option value="3">Grupo 3</option>}
              </Input>
            </Col>
          </Row>
        </ModalBody>
      ) : (
        <ModalBody className="text-left p-5">
          <Card>
            <CardHeader>
              <SearchModal
                setTermoBusca={setTermo}
                termoBusca={termo}
                setLoading={setLoading}
                setLoadTable={setLoadTable}
                setPaginaAtual={setPaginaAtual}
              />
            </CardHeader>
            <CardBody>
              {novo ? (
                <div className="mt-4">
                  <Row className="mt-3">
                    <Col md="6">
                      <div>
                        <Label htmlFor="nome" className="form-label">
                          Nome <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="nome"
                          className="form-control"
                          type="text"
                          placeholder="Nome"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                          maxLength={80}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <Label htmlFor="sobrenome" className="form-label">
                          Sobrenome <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="sobrenome"
                          className="form-control"
                          type="text"
                          placeholder="Sobrenome"
                          value={sobrenome}
                          onChange={(e) => setSobrenome(e.target.value)}
                          maxLength={255}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="6">
                      <div>
                        <Label htmlFor="email" className="form-label">
                          E-mail <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="email"
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) =>
                            setEmail(formatEmail(e.target.value))
                          }
                          maxLength={100}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <Label htmlFor="carteira" className="form-label">
                          Carteira <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="carteira"
                          className="form-control"
                          type="text"
                          placeholder="0x ..."
                          value={carteira}
                          onChange={(e) => setCarteira(e.target.value)}
                          maxLength={42}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="hstack gap-2 justify-content-end mt-5">
                    <Link
                      to="#"
                      className="btn btn-sm btn-link link-success fw-medium"
                      onClick={() => setNovo(false)}
                      disabled={loadTable}
                    >
                      <i className="ri-search-line me-1 align-middle"></i>
                      Voltar para busca
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-sm btn-success"
                      onClick={() => criar()}
                      disabled={loadTable}
                    >
                      Criar
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  <Row className="g-4 align-items-center pb-3">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Clientes</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <Link
                          to="#"
                          className="btn btn-sm btn-secondary add-btn"
                          onClick={() => setNovo(true)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Novo
                          Cliente
                        </Link>{" "}
                      </div>
                    </div>
                  </Row>
                  <div className="live-preview">
                    {loadTable ? (
                      <Row className="justify-content-center">
                        <Spinner />
                      </Row>
                    ) : (
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th className="border-0">Nome</th>
                            <th className="border-0">Email</th>
                            <th className="border-0">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientes?.map((cliente, index) => {
                            return (
                              <tr key={index}>
                                <td>{`${cliente?.cliente?.nome} ${cliente?.cliente?.sobrenome}`}</td>
                                <td>{cliente?.cliente?.email}</td>
                                <td>
                                  {!estaNaLista(cliente?.cliente?.email) ? (
                                    <Link
                                      title="Adicionar cliente ao envio de convites"
                                      onClick={() =>
                                        addCliente(cliente?.cliente)
                                      }
                                    >
                                      <FeatherIcon
                                        className="text-success"
                                        icon="plus-circle"
                                        width="20"
                                      />
                                    </Link>
                                  ) : (
                                    <Badge color="primary">Adicionado</Badge>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                    <div className="col-sm-auto mt-3 mt-sm-0">
                      <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                        <li
                          className={`page-item ${
                            paginaAtual === 1 ? "disabled" : ""
                          }`}
                        >
                          <Link to="#" className="page-link" onClick={prevPage}>
                            ←
                          </Link>
                        </li>
                        <li
                          className={`page-item ${
                            paginaAtual === Math.ceil(total / 5)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <Link to="#" className="page-link" onClick={nextPage}>
                            →
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}

              <h5 className="mt-5">A Enviar Convite</h5>
              <div className="live-preview mt-2">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Nome</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emails?.map((email, index) => {
                      return (
                        <tr key={index}>
                          <td>{`${email?.nome} ${email?.sobrenome}`}</td>
                          <td>{email?.email}</td>
                          <td>
                            <Link
                              title="Remover cliente do envio de convites"
                              onClick={() => removeCliente(email?.email)}
                            >
                              <FeatherIcon
                                className="text-danger"
                                icon="x-circle"
                                width="20"
                              />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                    <li
                      className={`page-item ${
                        adicionadosPaginaAtual === 1 ? "disabled" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={adicionadoPrevPage}
                      >
                        ←
                      </Link>
                    </li>
                    <li
                      className={`page-item ${
                        adicionadosPaginaAtual === Math.ceil(emails.length / 5)
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={adicionadoNextPage}
                      >
                        →
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggleModal()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <Button
              className="btn btn-success"
              onClick={() => enviarConvites(alocacao)}
              disabled={loadTable}
            >
              Enviar
            </Button>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default ModalEnviar;
