import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ModalCarteiras = ({ modalCarteiras, toggle, setLoading, cliente }) => {
  const [loadingModal, setLoadingModal] = useState(true);
  const [carteira, setCarteira] = useState("");
  const [carteiras, setCarteiras] = useState([]);

  const registerWallet = async (carteira, client) => {
    if (!client) return;
    setLoadingModal(true);
    const response = await apiClient.post(`/carteiras/cliente/${client.id}`, {
      carteira,
    });
    if (response.sucesso) {
      toast.success(response.mensagem);
      setCarteira("");
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(true);
    setLoadingModal(false);
  };

  const removeWallet = async (id) => {
    setLoadingModal(true);
    const response = await apiClient.delete(`/carteiras/${id}/delete`);
    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(true);
    setLoadingModal(false);
  };

  const getCarteiras = useCallback(async (client) => {
    if (!client?.id) return;
    const response = await apiClient.get(`/cliente/${client?.id}`);
    if (response.sucesso) {
      setCarteiras(response.dados.user?.carteiras || []);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    const getAll = async () => {
      await getCarteiras(cliente);
      setLoadingModal(false);
    };
    getAll();
  }, [loadingModal, cliente]);

  return (
    <Modal
      isOpen={modalCarteiras}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Carteiras
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div>
          <small className="text-danger">
            <ul>
              <li>
                Uma carteira somente pode ser removida do cliente se houver mais
                de uma cadastrada.
              </li>
              <li>
                Carteiras ligadas a projetos não podem ser removidas, a não ser
                que o cliente desvincule do projeto em seu próprio painel.
              </li>
            </ul>
          </small>
          <Row className="mt-4">
            <h4 className="fw-bold">Carteiras Ativas</h4>
            <small className="text-muted">
              <b>Nome:</b>{" "}
              {cliente?.nome ? `${cliente.nome} ${cliente.sobrenome}` : ""}
            </small>
          </Row>
          <Row className="mt-3">
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Carteira</Label>
                <Input
                  id="carteira"
                  placeholder="0x..."
                  type="text"
                  value={carteira}
                  onChange={(e) => setCarteira(e.target.value)}
                  maxLength={42}
                />
              </div>
              <div className="d-flex justify-content-end mt-4 w-100">
                <Button
                  type="button"
                  color="info"
                  size="sm"
                  onClick={() => registerWallet(carteira, cliente)}
                  disabled={loadingModal || loadingModal}
                >
                  Cadastrar
                </Button>
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <h3>Carteiras cadastradas</h3>
              {loadingModal ? (
                <Row className="justify-content-center text-center mt-3">
                  <Spinner />
                </Row>
              ) : carteiras.length === 0 ? (
                <small className="text-muted">
                  Nenhuma carteira cadastrada
                </small>
              ) : (
                carteiras.map((carteira) => (
                  <Row
                    className="d-flex justify-content-between mt-3 w-100"
                    key={carteira.id}
                  >
                    <Col
                      sm={11}
                      title={carteira.carteira}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {formatAddress(carteira.carteira)}
                    </Col>
                    <Col sm={1}>
                      {carteiras.length > 1 && (
                        <Link
                          to="#"
                          className="btn btn-link link-danger fw-medium p-0"
                          onClick={() => removeWallet(carteira.id)}
                          title="Remover Carteira"
                        >
                          <FeatherIcon
                            icon="trash"
                            width="13"
                            className="text-danger"
                          />
                        </Link>
                      )}
                    </Col>
                  </Row>
                ))
              )}
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loadingModal}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalCarteiras;
