import React, { useState, useEffect, useCallback } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap";

import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const UserProfile = () => {
  document.title = "Perfil de Usuário | Claim My Tokens";
  const [userDados, setUserDados] = useState({});
  const [nome, setNome] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  const getData = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados);
    setNome(dados.name);
    setEmail(dados.email || "");
    setPassword("");
  };

  const editar = useCallback(async () => {
    setLoading(true);

    const data = {
      name: nome,
      email,
      password: password,
      password_confirmation: passwordConfirm,
    };

    const response = await apiClient.put(`/users/${userDados.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      await refreshUser();
      await getData();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, [nome, email, password, userDados]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <FeatherIcon
                        icon="user"
                        width="13"
                        className="avatar-md rounded-circle img-thumbnail text-warning"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userDados?.nome}</h5>
                        <p className="mb-1">Nome: {userDados?.name}</p>
                        <p className="mb-0">E-mail: {userDados?.email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Editar seu Usuário</h4>

          <Card>
            <CardBody>
              <Row>
                <Col lg="6" className="mt-3">
                  <div className="form-group">
                    <Label className="form-label">Nome</Label>
                    <Input
                      id="nome"
                      placeholder="Digite seu nome"
                      type="text"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                      maxLength={255}
                    />
                  </div>
                </Col>
                <Col lg="6" className="mt-3">
                  <div className="form-group">
                    <Label className="form-label">Email</Label>
                    <Input
                      id="email"
                      placeholder="Digite seu email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      maxLength={255}
                    />
                    <small className="text-muted">
                      Para poder solicitar reset de senha, um email válido é
                      necessário.
                    </small>
                  </div>
                </Col>
              </Row>
              {userDados?.role !== "cliente" && (
                <Row>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Senha</Label>
                      <Input
                        id="password"
                        placeholder="Digite apenas se deseja alterar a senha"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        maxLength={30}
                      />
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Confirmação de Senha</Label>
                      <Input
                        id="passwordConfirm"
                        placeholder="Digite a senha novamente"
                        type="password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        maxLength={30}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <div className="d-flex justify-content-end mt-4 w-100">
                  <Button
                    type="button"
                    color="danger"
                    onClick={editar}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
